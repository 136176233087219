import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";

import { useHistory } from "react-router-dom";

export default ({webTitle}) => {

    let {location} = useHistory();

    const data  = {
        'event': 'virtualPageview',
        'pagePath': location.pathname+location.search+location.hash,
        'pageTitle': webTitle 
    }
    window.dataLayer.push(data);


    // let history = useHistory();

    // const a = ""

    // return (<Helmet>
    //     <script>
    //         {`window.dataLayer = window.dataLayer || [];
    //         window.dataLayer.push({
    //             'event': 'virtualPageview',
    //             'pagePath': ${a},
    //             'pageTitle': ${a}' 
    //         });`}
    //     </script>

    // </Helmet>)


    return null;
}
