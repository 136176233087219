import React, { useState, useEffect, useMemo } from 'react';
import './main.css'
import { Link } from "react-router-dom";
import $ from "jquery";

// onclick="document.getElementById('widget').className = 'hide' "

export default ({ match }) => {

    const { lang, destinationWebUrl } = match.params
    const url = `/${lang}/${destinationWebUrl}/take-your-pass`;

    const [hidden, setHidden] = useState(false);

    // const isMobile = useMediaQuery("(max-width: 648px)")
    const sm = useMediaQuery("(max-width: 648px)")
    const md = useMediaQuery("(max-width: 981px)")
    
    

    const onScroll = () => {

        if(hidden) return

        var navbar = $(".widget")
        if ($(window).scrollTop() >= 150) {
            navbar.removeClass("hide");
        } else {
            navbar.addClass("hide");
        }
    }
    const onHide = () => {
        setHidden(true)
        var navbar = $(".widget")
        navbar.addClass("hide");
    }

    useEffect(() => {
        console.log("SCROLL ON !!!")
        $(window).on('scroll', onScroll);

        return () => {
            console.log("SCROLL OFF !!!")
            $(window).off('scroll', onScroll);
        }

    }, [hidden])

    

    // if(destinationWebUrl != "visit-brac") return null

    var widgetClass = sm? "widget--sm": md? "widget--md": "widget--small";
    return (
        // .widget--small
        // .widget--small-180
        <div className={`widget hide ${widgetClass} widget--float widget--bottom-right no-print`} id="widget">
            <div className="widget__btn" onClick={onHide}>
                <svg
                    className="feather feather-x-circle" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                    strokeLinejoin="round">
                    <circle cx="12" cy="12" r="10"></circle>
                    <path d="M15 9l-6 6M9 9l6 6"></path>
                </svg>
            </div>
            <Link className="widget__item" to={url}>
                <div className="widgetCard">
                    <div className="widgetCard__img">
                        <img src="https://assets.iqmpass.com/pdf/widget/card-slavonija.png" alt="" />
                        <div className="widgetCard__row widgetCard__row--green">

                            <h2 className="widgetCard__h1">
                                {lang == 'hr' ? `Preuzmite svoj IQM Pass besplatno ` : `Get your free pass now!`}
                            </h2>

                        </div>
                    </div>
                </div>
                <div className="widget__mask"></div>
            </Link>
        </div>
    )
}




function useMediaQuery(query) {
    const mediaQuery = useMemo(() => window.matchMedia(query), [query]);
    const [match, setMatch] = useState(mediaQuery.matches);

    useEffect(() => {
        const onChange = () => setMatch(mediaQuery.matches);
        mediaQuery.addEventListener("change", onChange);

        return () => mediaQuery.removeEventListener("change", onChange);
    }, [mediaQuery]);

    return match;
}