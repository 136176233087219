import React, { Fragment, useEffect, useState } from 'react';
import { Switch, Route, Redirect, Link, NavLink } from "react-router-dom";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import DOMPurify from 'dompurify'; // To prevent XSS attacks

import SeoHead from '../includes/SeoHead'
import Loader from '../includes/Loader'
import Footer from '../includes/Footer'
import Navbar from '../includes/Navbar'
import NotFound404Page from './NotFound404Page'
import Network from '../network';

import DataLayerHead  from '../includes/DataLayerHead'

import { categoryAboutProviderMapper, passTypeMapper, categorySlugMapper, trimmString } from '../helpers'
const { getItinerary, getPassList, getActiveDeals } = Network


export default (props) => {
    const { destination, destinationID, match, history } = props
    const { lang, destinationWebUrl, itineraryID } = match.params




    const [itinerary, setItinerary] = useState(null)
    const [passList, setPassList] = useState([])
    const [dealList, setDealList] = useState([])

    const LoadData = async () => {
        const itinerary = await getItinerary(destinationID, itineraryID)
        const passList = await getPassList(destinationID)
        const dealList = await getActiveDeals(destinationID)


        setItinerary(itinerary)
        setPassList(passList)
        setDealList(dealList)
    }

    useEffect(() => {
        LoadData().then(() => {
            setFetching(false)
        }).catch(e => console.log(e))


    }, [])


    const [fetching, setFetching] = useState(true)
    if (fetching === true) return <Loader />
    else if (!itinerary || !itinerary._id) return <NotFound404Page {...props} />



    const title = itinerary.metaData[lang].title;
    const heroImg = itinerary.coverImage || destination.webData.hero_section.images[0]


    const url = `/${lang}/${destinationWebUrl}`

    const itineraryLangData = itinerary.metaData[lang]
    const webTitle = `IQM Pass - ${itineraryLangData.title} - IQMpass.com`;

    return (
        <Fragment>
            <DataLayerHead webTitle={webTitle} />
            <SeoHead match={match} history={history} pageType="ItineraryPage" />
            <Navbar lang={lang} {...props} />


            {/* HERO */}
            <div className="section hero--small" id="hero">
                <div className="heroSlider">
                    <div className="heroSlider__item">
                        <img src={`https://assets.iqmpass.com/${heroImg.path}`} alt="" />
                    </div>
                </div>
                <div className="container">
                    <div className="hero">
                        <h1 className="hero__title">{`${title}`}</h1><Link className="hero__box" to={`${url}/take-your-pass`}>{lang === 'hr' ? 'Preuzmite IQM Pass' : 'Take Your IQM Pass'}</Link>
                    </div>
                </div>
                {/* <div className="spacer spacer--greenTop" /> */}
                <div className="spacer spacer--whiteTop" />
            </div>


            <Itinerary itinerary={itinerary} passList={passList} dealList={dealList} match={match} />


            <GoogleMap lang={lang} itinerary={itinerary} dealList={dealList} />

            <Footer {...props} />
        </Fragment>
    );
}



const Itinerary = ({ itinerary, passList, dealList, match }) => {

    const { destinationWebUrl, lang } = match.params
    const url = `/${lang}/${destinationWebUrl}`

    // From pass list find pass used for itinerary
    const pass = passList.find(pass => pass._id === itinerary.passID)
    const duration = pass.duration / 24
    const users = (pass.passType === 'TEAM' || pass.passType === 'FAMILY') ? 4 : (pass.passType === 'COUPLE') ? 2 : 1


    let regularPrice = 0;
    let yourPrice = 0;
    let youSave = 0;

    const SaveItems = itinerary.deals.map(dealID => {
        const deal = dealList.find(deal => deal._id === dealID)
        if(deal) {
            return (<DealsItem deal={deal} lang={lang} match={match}  />)
        } else {
            return null
        }


        if (deal) {

            const dealData = deal.dealData.find(data => data.lang === lang)
            const price = deal.price.find(price => {
                if (price.userType !== 'any') return false
                if ((price.currency === 'HRK' && lang === 'hr') || (price.currency === 'EUR' && lang === 'en')) return price
                else return false
            })
            const discount = deal.discount.find(discount => {
                if (discount.userType !== 'any') return false
                if ((discount.currency === 'HRK' && lang === 'hr') || (discount.currency === 'EUR' && lang === 'en')) return discount
                else return false
            })



            if (discount.discountType !== "free" && discount.discountType !== "gift") {
                const multiplayFactor = (price.priceConditions.find(cond => cond === "PERSON") ? users : 1) * (price.priceConditions.find(cond => cond === "PERIOD") ? duration : 1)
                const save = multiplayFactor * (discount.discountType === "gift" ? price.value : (discount.discountType === "trade" ? price.value * ((100 - discount.value) / 100) : price.value - discount.value))

                regularPrice = regularPrice + multiplayFactor * price.value
                yourPrice = yourPrice + save

                return (
                    <div className="save__item">
                        <CardSave pass={pass} deal={deal} match={match} />
                    </div>)
            }
            else if(discount.discountType !== "free") {
                return(
                    <div className="save__item">
                            <CardGift pass={pass} deal={deal} match={match} />
                        </div>)
            }
            else {
                return(
                <div className="save__item">
                        <CardPromo pass={pass} deal={deal} match={match} />
                    </div>)
            }

        }
        else {
            return null;
        }
    })




    youSave = regularPrice - yourPrice


    const subtitle = itinerary.metaData[lang].subtitle;
    const description = itinerary.metaData[lang].description;
    const shortdesc = itinerary.metaData[lang].shortdesc;

    // const usersTxt = (pass.passType === 'TEAM' || pass.passType === 'FAMILY') ? (lang === 'hr' ? "3-5 korisnika" : "3-5 users") : (pass.passType === 'COUPLE') ? (lang === 'hr' ? "2 korisnika" : "2 users") : (lang === 'hr' ? "1 korisnik" : "1 user")
    // const durationTxt = lang === 'hr' ? (pass.duration > 24 ? `${pass.duration / 24} dana` : `1 dan`) : (pass.duration > 24 ? `${pass.duration / 24} days` : `1 day`)

    return (
        <div className="section" id="save">
            <div className="container">
                <h2 className="section__title section__title--blue">{subtitle}</h2>
                {/* <div className="section__subtitle"> {`IQM Pass ${passTypeMapper(pass.passType, lang)} - ${usersTxt}  ${durationTxt}`}</div> */}
                
                {
                    description ?
                    <div style={{ maxWidth: "100%", marginBottom: "40px" }} className="section__text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}></div>:
                    <div style={{ maxWidth: "100%", marginBottom: "40px" }} className="section__text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(shortdesc) }}></div>
                }
                
                {/* <div className="section__subtitle"> {`IQM Pass ${passTypeMapper(pass.passType, lang)} - ${durationTxt}`}</div> */}
                <div className="save">
                    <div className="save__list">
                        {SaveItems}
                    </div>
                    {
                        youSave === 0 ? null:

                        <div className="saveBar">
                        <div className="saveBar__item saveBar__item--yellow">
                            <div className="saveBar__text">{lang === 'hr' ? "Možeš uštedjeti" : "You can save"}</div>
                        </div>
                        <div className="saveBar__item saveBar__item--white">
                            <div className="saveBar__text saveBar__text--large saveBar__text--striked">{`${regularPrice.toFixed(2)} ${lang === 'hr' ? "HRK" : "EUR"}`}</div>
                            <div className="saveBar__text saveBar__text--small">{lang === 'hr' ? 'Regularna cijena' : 'Regular Price'}</div>
                        </div>
                        <div className="saveBar__item saveBar__item--white">
                            <div className="saveBar__text saveBar__text--large">{`${yourPrice.toFixed(2)} ${lang === 'hr' ? "HRK" : "EUR"}`}</div>
                            <div className="saveBar__text saveBar__text--small">{lang === 'hr' ? 'Tvoja cijena' : ' Your price'}</div>
                        </div>
                        <div className="saveBar__item saveBar__item--blue">
                            <div className="saveBar__text saveBar__text--large">{`${youSave.toFixed(2)} ${lang === 'hr' ? "HRK" : "EUR"}`}</div>
                            <div className="saveBar__text saveBar__text--small">{lang === 'hr' ? 'Štediš' : 'You Save'}</div>
                        </div>
                        <div className="btn btn--green btn--fill btn--arrow">
                            <Link className="btn__text" to={`${url}/take-your-pass`} >{lang === 'hr' ? 'Uštedi sada' : 'Save now'}</Link>
                            <div className="btn__icon">
                                <svg viewBox="0 0 15.698 8.706" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.354 0l-.708.706 3.14 3.147H0v1h13.786L10.646 8l.708.706 4.344-4.353z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>

    );




}




const DealsItem = ({ deal, lang, match, history }) => {


    const { destinationWebUrl } = match.params

    const dealData = deal.dealData.find(data => data.lang === lang)
    const discount = deal.discount.find(discount => {
        if (discount.userType !== 'any') return false
        if ((discount.currency === 'HRK' && lang === 'hr') || (discount.currency === 'EUR' && lang === 'en')) return discount
        else return false
    })

    
    const curencyMap = {
        "HRK": "kn",
        "EUR": "€"
    }
    const discountType = discount.discountType === 'cash' ? (`-${discount.value}${curencyMap[discount.currency]}`) :
        (discount.discountType === 'trade' ? `-${discount.value}%` : (discount.discountType === 'gift' ? "GIFT": "FREE"))

    // Ovo je za slučaj kada nije free i gift već nema popusta
    const price = deal.price.find(price => {
        if (price.userType !== 'any') return false
        if ((price.currency === 'HRK' && lang === 'hr') || (price.currency === 'EUR' && lang === 'en')) return price
        else return false
      })
    const noDisscout = (discount.discountType === "trade" || discount.discountType === "cash") && discount.value === 0 ? true:false;

        // console.log(discount,discountType)
    // const url = match.url.replace('/deals', `/deal/${deal._id}`)
    const url = `/${lang}/${destinationWebUrl}/deal/${deal._id}`
    return (
        <Link className="deals__item" to={`${url}`} name={dealData.title} title={dealData.title}>
            <div className="card card--large">
                <div className="card__img">
                    {deal.coverImage ? <img src={`https://assets.iqmpass.com/${deal.coverImage.path}`} alt="" /> : null}
                    {/* css je napravljen tako da se prilagodava sirini, tako da vrijednosti mogu bit koliko hoces samo textualno ubaci (npr. "-100€" "-100kn" "Gift" "-90%") */}
                    
                    {!noDisscout? <div className="card__discount">{discountType}</div>:null}
                </div>
                <div className="card__row card__row--white">
                    <h2 className="card__h1">{dealData.title}</h2>
                    <div className="card__text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dealData.shortdesc) }}/>
                    <div className="card__btn">
                        <div className="btnTextArrow" >
                            {lang === 'hr' ?
                                <div className="btnTextArrow__text">Ostvarite ovu ponudu</div> :
                                <div className="btnTextArrow__text">Get this deal</div>
                            }
                            <div className="btnTextArrow__icon">
                                <svg viewBox="0 0 15.698 8.706" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.354 0l-.708.706 3.14 3.147H0v1h13.786L10.646 8l.708.706 4.344-4.353z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="deals__mask" />
        </Link>


    );
}

const CardSave = ({ pass, deal, match }) => {
    const { lang, destinationWebUrl } = match.params

    const dealData = deal.dealData.find(data => data.lang === lang)
    const price = deal.price.find(price => {
        if (price.userType !== 'any') return false
        if ((price.currency === 'HRK' && lang === 'hr') || (price.currency === 'EUR' && lang === 'en')) return price
        else return false
    })
    const discount = deal.discount.find(discount => {
        if (discount.userType !== 'any') return false
        if ((discount.currency === 'HRK' && lang === 'hr') || (discount.currency === 'EUR' && lang === 'en')) return discount
        else return false
    })

    const curencyMap = {
        "HRK": "kn",
        "EUR": "€"
    }


    const url = `/${lang}/${destinationWebUrl}/deal/${deal._id}`


    const duration = pass.duration / 24
    const users = (pass.passType === 'TEAM' || pass.passType === 'FAMILY') ? 5 : (pass.passType === 'COUPLE') ? 2 : 1



    let totalPriceText = `${price.priceType === "FULL" ? (lang === 'hr' ? "Puna cijena  " : "Full price ") : (lang === 'hr' ? "Prosječna potrošnja " : "Average spend ")}`

    const getStayCondition = (days, lang) => {
        if (lang === 'hr')
            return days > 1 ? `za ${days} dana boravka ` : `za ${days} dan boravka `
        else
            return days > 1 ? `for ${days}-day stay ` : `for ${days}-day stay `
    }

    const getPeopleCondition = (people, lang) => {

        const personsHR = ["za jednu osobu", "za dvije osobe", "za tri osobe", "za četiri osobe", "za pet osoba"]
        const personsEN = ["for one person", "for two people", "for three people", "for four people", "for five people"]

        if (lang === 'hr')
            return personsHR[people - 1]
        else
            return personsEN[people - 1]
    }

    if (price.priceConditions.find(cond => cond === "PERIOD"))
        totalPriceText = totalPriceText + getStayCondition(duration, lang)

    if (price.priceConditions.find(cond => cond === "PERSON"))
        totalPriceText = totalPriceText + getPeopleCondition(users, lang)

    const multiplayFactor = (price.priceConditions.find(cond => cond === "PERSON") ? users : 1) * (price.priceConditions.find(cond => cond === "PERIOD") ? duration : 1)

    const save = multiplayFactor * (discount.discountType === "gift" ? price.value : (discount.discountType === "trade" ? price.value * ((100 - discount.value) / 100) : price.value - discount.value))
    const totalPrice = multiplayFactor * price.value;


    const discountType = discount.discountType === 'cash' ? `-${multiplayFactor * discount.value}${curencyMap[discount.currency]}` :
        (discount.discountType === 'trade' ? `-${discount.value}%` : "GIFT")


    console.log("teaserDeal", price.priceConditions, price.priceType, totalPriceText)
    console.log("teaserDeal", deal)

    const noDisscout = (discount.discountType === "trade" || discount.discountType === "cash") && discount.value === 0 ? true:false;


    return (
        
        <div className="card">
                <Link to={`${url}`} name={dealData.title} title={dealData.title}>
                    <div className="card__img">
                        <h3 className="card__title">{dealData.title}
                            <div className="card__icon">
                                <svg viewBox="0 0 15.698 8.706" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.354 0l-.708.706 3.14 3.147H0v1h13.786L10.646 8l.708.706 4.344-4.353z" />
                                </svg>
                            </div>
                        </h3>
                        {/* <div className="card__discount">{discountType}</div> */}
                        {!noDisscout? <div className="card__discount">{discountType}</div>:null}
                        <div className="card__img-mask2" />
                        <div className="card__img-mask" />
                        {deal.coverImage ? <img src={`https://assets.iqmpass.com/${deal.coverImage.path}`} alt="" /> : null}
                    </div>
                </Link>
                <div className="card__row card__row--white card__row--col2">
                    {/* <div className="card__text">{` IQM Pass ${pass.passType} ${duration}`}</div> */}
                    {/* <div className="card__text">{price.description ? `${price.description[0].toUpperCase()+price.description.slice(1)}  ${price.value} ${price.currency}`: ""}</div>
                    <div className="card__text card__text--striked"> <b>{lang=== "hr" ? "Ukupno": "Total"}</b> <br /><span>{`${totalPrice} ${price.currency}`}</span> */}


                    {/* <div className="card__text">
                        {price.description ? `${price.description[0].toUpperCase()+price.description.slice(1)}`: ""}
                        <br/> 
                        {totalPriceText}
                    </div> */}
                    <div className="card__text-wrap">
                        <div className="card__text">
                            {price.description ? `${price.description[0].toUpperCase() + price.description.slice(1)}` : ""}
                        </div>
                        <div className="card__text card__text--striked">
                            <b>{`${price.value} ${price.currency}`}</b>
                        </div>
                    </div>


                    <div className="card__text-wrap">
                        <div className="card__text">
                            {totalPriceText}
                        </div>
                        <div className="card__text card__text--striked">
                        {!noDisscout? <span>{`${totalPrice} ${price.currency}`}</span>:<b>{`${totalPrice} ${price.currency}`}</b>}
                        </div>
                    </div>
                </div>
                <div className="card__row card__row--green card__row--col2">
                {!noDisscout? <Fragment>
                    <div className="card__text">{lang === 'hr' ? "Cijena sa IQM pass-om" : "Price with IQM pass"}</div>
                    <div className="card__text"> <span>{`${save.toFixed(2)} ${price.currency}`}</span></div>
                </Fragment>:null}
                </div>
            </div>
        

    );
}

const CardPromo = ({ pass, deal, match }) => {
    const { lang, destinationWebUrl } = match.params

    const dealData = deal.dealData.find(data => data.lang === lang)
    const price = deal.price.find(price => {
        if (price.userType !== 'any') return false
        if ((price.currency === 'HRK' && lang === 'hr') || (price.currency === 'EUR' && lang === 'en')) return price
        else return false
    })
    const discount = deal.discount.find(discount => {
        if (discount.userType !== 'any') return false
        if ((discount.currency === 'HRK' && lang === 'hr') || (discount.currency === 'EUR' && lang === 'en')) return discount
        else return false
    })

    const curencyMap = {
        "HRK": "kn",
        "EUR": "€"
    }


    const url = `/${lang}/${destinationWebUrl}/deal/${deal._id}`


    return (
        
            <div className="card">
                <Link to={`${url}`} name={dealData.title} title={dealData.title}>
                    <div className="card__img">
                        <h3 className="card__title">{dealData.title}
                            <div className="card__icon">
                                <svg viewBox="0 0 15.698 8.706" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.354 0l-.708.706 3.14 3.147H0v1h13.786L10.646 8l.708.706 4.344-4.353z" />
                                </svg>
                            </div>
                        </h3>
                        <div className="card__discount">FREE</div>
                        <div className="card__img-mask2" />
                        <div className="card__img-mask" />
                        {deal.coverImage ? <img src={`https://assets.iqmpass.com/${deal.coverImage.path}`} alt="" /> : null}
                    </div>
                </Link>
                <div className="card__row card__row--white card__row--col2">
                    
                    <div className="card__text-wrap">
                        <div className="card__text">
                            
                        </div>
                        <div className="card__text card__text--striked">
                            
                        </div>
                    </div>


                    <div className="card__text-wrap">
                        <div className="card__text">
                    
                        </div>
                        <div className="card__text card__text--striked">
                    
                        </div>
                    </div>
                </div>
                <div className="card__row card__row--green card__row--col2">
                    <div className="card__text" 
                    style={{width: "100%", textAlign: "center"}}><b>FREE</b></div>
                </div>
            </div>


    );
}

const CardGift = ({ pass, deal, match }) => {
    const { lang, destinationWebUrl } = match.params
    const dealData = deal.dealData.find(data => data.lang === lang)
    const url = `/${lang}/${destinationWebUrl}/deal/${deal._id}`
    return (
            <div className="card">
                <Link to={`${url}`} name={dealData.title} title={dealData.title}>
                    <div className="card__img">
                        <h3 className="card__title">{dealData.title}
                            <div className="card__icon">
                                <svg viewBox="0 0 15.698 8.706" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.354 0l-.708.706 3.14 3.147H0v1h13.786L10.646 8l.708.706 4.344-4.353z" />
                                </svg>
                            </div>
                        </h3>
                        <div className="card__discount">{lang === 'hr'? 'POKLON': 'GIFT'}</div>
                        <div className="card__img-mask2" />
                        <div className="card__img-mask" />
                        {deal.coverImage ? <img src={`https://assets.iqmpass.com/${deal.coverImage.path}`} alt="" /> : null}
                    </div>
                </Link>
                <div className="card__row card__row--white card__row--col2">
                    
                    <div className="card__text-wrap">
                        <div className="card__text">
                            
                        </div>
                        <div className="card__text card__text--striked">
                            
                        </div>
                    </div>


                    <div className="card__text-wrap">
                        <div className="card__text">
                    
                        </div>
                        <div className="card__text card__text--striked">
                    
                        </div>
                    </div>
                </div>
                <div className="card__row card__row--green card__row--col2">
                    <div className="card__text" 
                    style={{width: "100%", textAlign: "center"}}><b>{lang === 'hr'? 'POKLON': 'GIFT'}</b></div>
                </div>
            </div>


    );
}



const MapBox = ({ google, lang, itinerary, dealList }) => {

    const initialCenter = {}
    const Markers = itinerary.deals.map(dealID => {
        const deal = dealList.find(deal => deal._id === dealID)
        const { address } = deal
        const { long, lat } = address.geoLocation;
        const dealData = deal.dealData.find(data => data.lang === lang)

        initialCenter.lat = long;
        initialCenter.lng = lat;
        return <Marker title={dealData.name} name={dealData.name} position={{ lat: long, lng: lat }} />
    })



    return (
        <div className="section" id="googlemap">
            <div className="container">
                <div className="map">
                    {/* <h2 className="section__h3">{i18n[lang].location}</h2> */}
                    <div className="mapBox" style={{ position: "relative" }}>

                        {/* <Map google={google} zoom={14} initialCenter={{ lat: long, lng: lat }}> */}
                        <Map google={google} zoom={8} initialCenter={initialCenter} >
                            {Markers}
                        </Map>
                    </div>
                </div>
            </div>
            {/* <div className="spacer spacer--grayBot" /> */}
        </div>
    );
}

const GoogleMap = GoogleApiWrapper({
    apiKey: ("AIzaSyBw8oSp5nAJC43HjK1GjFRR7hLhkGRezcQ")
})(MapBox)

