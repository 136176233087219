
export const trimmString = (str, maxLen) =>{
    const separator = ' '
        if (str.length <= maxLen) return str;
        return str.substr(0, str.lastIndexOf(separator, maxLen)) + '...';

}


export const getQueryStringParams = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};


export const passTypeMapper = (type, lang) => {

    const mapper = {
        "SINGLE": {
            "hr": "Jedna osoba",
            "en": "Single"

        },
        "COUPLE": {
            "hr": "Dvije osobe",
            "en": "Couple"
            
        },
        "TEAM": {
            "hr": "Grupa",
            "en": "Group"
            
        },
        "FAMILY": {
            "hr": "Obitelj",
            "en": "Family"
            
        },"CHILDREN": {
            "hr": "Djeca",
            "en": "Child"
            
        }
    }


    return mapper[type][lang]
}

// export const categorySlugMapper = (category, lang) =>{
//     console.log("categorySlugMapper", category, lang)
//    const mapper =  {
//         "food-drink": {
//             "hr": "Hrana & Piće",
//             "en": "Food & Drink"
//         },
//         "to-sleep": {
//             "hr": "Noćenje",
//             "en": "To sleep"
//         },
//         "national-parks": {
//             "hr": "Nacionalni parkovi",
//             "en": "National parks"
//         },
//         "unesco": {
//             "hr": "Unesco",
//             "en": "Unesco"
//         },
//         "thematic-tours": {
//             "hr": "Tematske ture",
//             "en": "Thematic tours"
//         },
//         "attractions": {
//             "hr": "Atrakcije",
//             "en": "Attractions"
//         },
//         "local-production": {
//             "hr": "Lokalna proizvodnja",
//             "en": "Local production"
//         },
//         "sport-adventure": {
//             "hr": "Sport & Avantura",
//             "en": "Sport & Adventure"
//         },
//         "entertainment": {
//             "hr": "Zabava",
//             "en": "Entertainment"
//         },
//         "health-spa": {
//             "hr": "Zdravlje & Spa",
//             "en": "Health & Spa"
//         }
//     }

//     return mapper[category][lang]

// }
export const categorySlugMapper = (category, lang) =>{
   const mapper =  {
        "food-drink": {
            "hr": "Hrana & Piće",
            "en": "Food & Drink"
        },
        "to-sleep": {
            "hr": "Noćenje",
            "en": "To sleep"
        },
        "attractions": {
            "hr": "Atrakcije",
            "en": "Attractions"
        },
        "local-production": {
            "hr": "Lokalni proizvodi i suveniri",
            "en": "Local products & souvenirs"
        },
        "entertainment": {
            "hr": "Zabava",
            "en": "Entertainment"
        },
        "excursions": {
            "hr": "Izleti",
            "en": "Excursions"
        },
        "sport-health": {
            "hr": "Sport & Zdravlje",
            "en": "Sport & Health"
        },
        "transport": {
            "hr": "Prijevoz",
            "en": "Transport"
        }
    }

    return mapper[category][lang]

}



export const categoryAboutProviderMapper = (category, lang) =>{
    console.log("categoryProviderMapper", category, lang)
   const mapper =  {
        "food-drink": {
            "hr": "O pružatelju usluge",
            "en": "About this food and drink provider"
        },
        "to-sleep": {
            "hr": "O ovom smještaju",
            "en": "About this accommodation"
        },
        "national-parks": {
            "hr": "O parku prirode",
            "en": "About this nature spot"
        },
        "unesco": {
            "hr": "O ovoj UNESCO atrakciji",
            "en": "About this UNESCO attraction"
        },
        "thematic-tours": {
            "hr": "O pružatelju usluge",
            "en": "About this thematic tours provider"
        },
        "attractions": {
            "hr": "O ovoj atrakciji",
            "en": "About this attraction"
        },
        "local-production": {
            "hr": "O lokalnom proizvođaču",
            "en": "About this local produce/products"
        },
        "sport-adventure": {
            "hr": "O pružatelju usluge",
            "en": "About this sport & adventure experience"
        },
        "entertainment": {
            "hr": "O pružatelju usluge",
            "en": "About this entertainment"
        },
        "health-spa": {
            "hr": "O pružatelju usluge",
            "en": "About this health & spa service"
        }
    }

    if(mapper[category]) {
        return mapper[category][lang]
    } else {
        var defaultMapper = {
            "hr": "O pružatelju usluge",
            "en": "About this service"
        }
    }
    

}

export const categoryProxy = (category) => {
    return {
        "food-drink": "food-drink",
        "to-sleep": "to-sleep",
        "national-parks": "attractions",
        "unesco": "attractions",
        "thematic-tours": "excursions",
        "attractions": "attractions",
        "local-production": "local-production",
        "sport-adventure": "sport-health",
        "entertainment": "entertainment",
        "health-spa": "sport-health",
        "excursions":  "excursions",
        "sport-health": "sport-health",
        "transport": "transport",
    }[category]
}