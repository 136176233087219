import React, { Fragment, useEffect, useState } from 'react';
import { Switch, Route, Redirect, Link, NavLink } from "react-router-dom";

import $ from "jquery";
import 'slick-carousel'

import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";



import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import DOMPurify from 'dompurify'; // To prevent XSS attacks

import SeoHead from '../includes/SeoHead'
import Loader from '../includes/Loader'
import Footer from '../includes/Footer'
import NavbarDeal from '../includes/NavbarDeal'
import Navbar from '../includes/Navbar'

import DataLayerHead from '../includes/DataLayerHead'

import NotFound404Page from './NotFound404Page'
import GetPassWidget from '../includes/GetPassWidget';

import { categoryAboutProviderMapper, passTypeMapper, categorySlugMapper, trimmString } from '../helpers'


import Network from '../network';
const { getDeal, getPartner } = Network


export default (props) => {

  const { destination, destinationID, match, history } = props
  const { lang, dealID, destinationWebUrl } = match.params
  const [deal, setDeal] = useState({})
  const [partner, setPartner] = useState({})
  const [fetching, setFetching] = useState(true)

  useEffect(() => {
    getDeal(destinationID, dealID).then(deal => {

      const { partnerID } = deal
      getPartner(destinationID, partnerID).then(partner => {

        setDeal(deal)
        setPartner(partner)
        setFetching(false)

      }).catch(e => console.log(e))

    }).catch(e => console.log(e))
  }, [])

  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 750px)").matches
  )
  useEffect(() => {
    window
      .matchMedia("(max-width: 750px)")
      .addEventListener('change', e => setMatches(e.matches));
  }, []);


  if (fetching) return <Loader />
  else if (!deal || !deal._id) return <NotFound404Page {...props} />


  const dealLangData = deal.dealData.find(data => data.lang === lang)
  const webTitle = `IQM Pass - ${dealLangData.title} - IQMpass.com`;
  const url = `/${lang}/${destinationWebUrl}`

  return (<Fragment>

    <DataLayerHead webTitle={webTitle} />
    <SeoHead match={match} history={history} pageType="DealPage" />

    <NavbarDeal lang={lang}  {...props} />
    {
      matches ? <MobileGallerySection deal={deal} match={match} /> :
        <SimpleReactLightbox>
          <Section deal={deal} match={match} />
        </SimpleReactLightbox>
    }

   
    <GoogleMap lang={lang} partner={partner} deal={deal} />
    <DealTemplateLayout lang={lang} partner={partner} deal={deal} match={match} />

    <GetPassWidget match={match} />
    <Footer {...props} />

  </Fragment>)
}

const MobileGallerySection = ({ deal, match }) => {
  const { destinationID, partnerID } = deal;
  const { lang } = match.params;

  const dealData = deal.dealData.find(data => data.lang === lang)
  const discount = deal.discount.find(discount => {
    if (discount.userType !== 'any') return false
    if ((discount.currency === 'HRK' && lang === 'hr') || (discount.currency === 'EUR' && lang === 'en')) return discount
    else return false
  })
  const price = deal.price.find(price => {
    if (price.userType !== 'any') return false
    if ((price.currency === 'HRK' && lang === 'hr') || (price.currency === 'EUR' && lang === 'en')) return price
    else return false
  })
  const save = discount.discountType === "trade" ? price.value * ((100 - discount.value) / 100) : price.value - discount.value

  var H2DiscountText = "";
  if (discount.discountType !== "free") {
    if (discount.discountType === 'gift') {
      // H2DiscountText = "<span>Poklon</span> uz predočenje IQM passa"
      // H2DiscountText = "<span>Gift</span> with the presentation of IQM pass"
      H2DiscountText = lang === 'hr' ?
        `Poklon uz predočenje IQM passa.` :
        `Gift with the presentation of IQM pass.`

    } else if (save !== price.value) { // This is when there is no discount -> It is diffrent form free and gift
      // H2DiscountText = "<span>{`-${discount.value} ${discount.discountType === "trade" ? '%' : discount.currency}`}</span> popust sa passom.";
      // H2DiscountText = "<span>{`-${discount.value} ${discount.discountType === "trade" ? '%' : discount.currency}`}</span> discount with pass." 
      H2DiscountText = lang === 'hr' ?
        `-${discount.value} ${discount.discountType === "trade" ? '%' : discount.currency} popust sa passom.` :
        `-${discount.value} ${discount.discountType === "trade" ? '%' : discount.currency} discount with pass.`
    } else {
      H2DiscountText = ""
    }
  } else {
    H2DiscountText = ""
  }


  // const H2DiscountText = `${discount.value} ${discount.discountType === "trade" ? '%' : discount.currency} popust sa passom. `

  const path = `https://assets.iqmpass.com/${destinationID}/partner/${partnerID}`;

  const images = deal.gallery.map(img => {

    // Idioti upisuju "." u ime slike tako da logika splitanja po "." ne vridi
    var lastIndexOfDot = img.name.lastIndexOf(".");
    var newImageName = `${path}/${img.name.slice(0,lastIndexOfDot)}_1000.webp`;
    
    return {
      original: newImageName
    }
  });

  return (
    <div className="section" id="mobile-gallery">
      <ImageGallery items={images}
        showThumbnails={false}
        showNav={false}
        showFullscreenButton={false}
        showPlayButton={false}
        useBrowserFullscreen={false}
        showBullets={true}
      />
      <div className="container">
        <h2 className="section__title section__title--blue">{dealData.name}</h2>
        <div className="section__subtitle">{`${dealData.title}`} <br /><span>{`${H2DiscountText}`}</span></div>
      </div>
    </div>

  )
}

const Section = ({ deal, match }) => {

  const { destinationID, partnerID } = deal;
  const { lang } = match.params;

  const dealData = deal.dealData.find(data => data.lang === lang)
  const discount = deal.discount.find(discount => {
    if (discount.userType !== 'any') return false
    if ((discount.currency === 'HRK' && lang === 'hr') || (discount.currency === 'EUR' && lang === 'en')) return discount
    else return false
  })
  const price = deal.price.find(price => {
    if (price.userType !== 'any') return false
    if ((price.currency === 'HRK' && lang === 'hr') || (price.currency === 'EUR' && lang === 'en')) return price
    else return false
  })
  const save = discount.discountType === "trade" ? price.value * ((100 - discount.value) / 100) : price.value - discount.value

  var H2DiscountText = "";
  if (discount.discountType !== "free") {
    if (discount.discountType === 'gift') {
      // H2DiscountText = "<span>Poklon</span> uz predočenje IQM passa"
      // H2DiscountText = "<span>Gift</span> with the presentation of IQM pass"
      H2DiscountText = lang === 'hr' ?
        `Poklon uz predočenje IQM passa.` :
        `Gift with the presentation of IQM pass.`

    } else if (save !== price.value) { // This is when there is no discount -> It is diffrent form free and gift
      // H2DiscountText = "<span>{`-${discount.value} ${discount.discountType === "trade" ? '%' : discount.currency}`}</span> popust sa passom.";
      // H2DiscountText = "<span>{`-${discount.value} ${discount.discountType === "trade" ? '%' : discount.currency}`}</span> discount with pass." 
      H2DiscountText = lang === 'hr' ?
        `-${discount.value} ${discount.discountType === "trade" ? '%' : discount.currency} popust sa passom.` :
        `-${discount.value} ${discount.discountType === "trade" ? '%' : discount.currency} discount with pass.`
    } else {
      H2DiscountText = ""
    }
  } else {
    H2DiscountText = ""
  }


  const path = `https://assets.iqmpass.com/${destinationID}/partner/${partnerID}`;

  const GalleryItems = deal.gallery.map((img, index) => {

    // Idioti upisuju "." u ime slike tako da logika splitanja po "." ne vridi
    var lastIndexOfDot = img.name.lastIndexOf(".");
    var newImageName = `${path}/${img.name.slice(0,lastIndexOfDot)}_1000.webp`;

    if (index > 4) {
      return (
        <figure className={`gallery_grid__item gallery_grid__item--${index + 1}`} style={{ display: "none" }}>
          <img src={newImageName} className="gallery_grid__img" />
        </figure>
      )
    } else {
      return (
        <figure className={`gallery_grid__item gallery_grid__item--${index + 1}`}>
          <img src={newImageName} className="gallery_grid__img" />
        </figure>
      )
    }
  })
  return (
    <div className="section" id="gallery">
      <div className="container">
        <h2 className="section__title section__title--blue">{dealData.name}</h2>
        <div className="section__subtitle">{`${dealData.title}`} <br /><span>{`${H2DiscountText}`}</span></div>
        <SRLWrapper>
          <div className="gallery_container">
            <div className={`gallery_grid grid-${deal.gallery.length > 5 ? 5 : deal.gallery.length}`}>
              {GalleryItems}
            </div>
            <div class="bg bg-wave"><svg xmlns="http://www.w3.org/2000/svg" width="406.062" height="136.38" viewBox="0 0 406.062 136.38"><path class="cls-1" id="bg_effect" d="M433.388 4494.95c-35.366 26.52-65.541 26.52-100.913 0-23.064-17.3-42.756-17.3-65.815 0-35.041 26.27-63.616 26.27-98.652 0-23.322-17.5-39.076-17.5-62.414 0l-9.622-12.84c28.357-21.25 52.182-22.12 81.663 0 27.792 20.85 48.306 23.32 79.4 0 29.481-22.12 56.892-21.12 85.065 0 28.921 21.7 51.611 22.55 81.663 0 29.5-22.12 48.781-22.12 78.262 0l-9.628 12.84c-23.697-17.79-35.29-17.79-59.009 0zm0-43.54c-35.366 26.53-65.541 26.53-100.913 0-23.064-17.31-42.756-17.31-65.815 0-35.041 26.27-63.616 26.27-98.652 0-23.322-17.5-39.076-17.5-62.414 0l-9.622-12.83c28.357-21.27 52.182-22.12 81.663 0 27.792 20.84 48.306 23.32 79.4 0 29.481-22.12 56.892-21.12 85.065 0 28.921 21.69 51.611 22.54 81.663 0 29.5-22.12 48.781-22.12 78.262 0l-9.628 12.83c-23.697-17.79-35.29-17.79-59.009 0zm-255.753 74.24c27.792 20.85 48.306 23.31 79.4 0 29.481-22.12 56.892-21.13 85.065 0 28.921 21.7 51.611 22.55 81.663 0 29.5-22.12 48.781-22.12 78.262 0l-9.628 12.83c-23.7-17.78-35.293-17.78-59.012 0-35.366 26.53-65.541 26.54-100.913 0-23.064-17.3-42.756-17.3-65.815 0-35.041 26.28-63.616 26.28-98.652 0-23.322-17.5-39.076-17.5-62.414 0l-9.622-12.83c28.36-21.26 52.185-22.12 81.666 0z" transform="translate(-95.969 -4422)"></path></svg></div>
            <div class="bg bg-circle"><svg xmlns="http://www.w3.org/2000/svg" width="424" height="424" viewBox="0 0 424 424"><path class="cls-1" id="bg_effect" d="M1362 4220c0 116.9 95.1 212 212 212s212-95.1 212-212-95.1-212-212-212-212 95.1-212 212zm16.96 0c0-107.55 87.49-195.04 195.04-195.04s195.04 87.49 195.04 195.04-87.49 195.04-195.04 195.04-195.04-87.5-195.04-195.04z" transform="translate(-1362 -4008)"></path></svg></div>
          </div>
        </SRLWrapper>

        <TakeYourPassSectionButton match={match} />
      </div>
    </div>
  )
}






const httpReg = new RegExp("^(http|https)://", "i");
const DealTemplateLayout = ({ match, deal, partner }) => {

  const { lang, destinationWebUrl } = match.params


  const discount = deal.discount.find(discount => {
    if (discount.userType !== 'any') return false
    if ((discount.currency === 'HRK' && lang === 'hr') || (discount.currency === 'EUR' && lang === 'en')) return discount
    else return false
  })

  const dealLangData = deal.dealData.find(data => data.lang === lang)

  const partnerLangData = partner.partnerData.find(data => data.lang === lang)
  const url = `/${lang}/${destinationWebUrl}`

  return (
    <div className="section" id="deal">
      <div className="container">
        {discount.discountType !== "free" ?
          /* DEALS */
          <div className="deal">

            {/* About this deal discount */}
            <div className="deal__col">
              <h2 className="section__h3">{lang === 'hr' ? "Saznajte više o ovom popustu" : "About this deal discount"}</h2>
              <div className="section__text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(discount.description) }} />
            </div>

            {/* About the xxx */}
            <div className="deal__col">

              {/* Partner Data */}
              <div className="dealBox">
                <div className="deal__icon">
                  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 46C11.9 46 2 36.1 2 24S11.9 2 24 2s22 9.9 22 22-9.9 22-22 22zm0-42C13 4 4 13 4 24s9 20 20 20 20-9 20-20S35 4 24 4z" />
                    <path d="M23 16h2v20h-2zM23 12h2v2h-2z" />
                  </svg>
                </div>
                <h2 className="section__h3">{categoryAboutProviderMapper(deal.categories[0], lang)}</h2>
                <div className="section__text section__text--bold">{dealLangData.name}</div>
                <div className="section__text">{`${deal.address.street}, ${deal.address.postal_code}, ${deal.address.city}`}
                  <br />{lang === 'hr' ? "Hrvatska" : "Croatia"}</div>

                {/* {partner.address.country_code */}
                <div className="section__text"><a href={`tel:${partnerLangData.phone}`}>{`Phone: ${partnerLangData.phone}`}</a></div>
                <div className="section__text">{`Email: ${partner.email}`}</div>
                {httpReg.test(partnerLangData.website) ? <div className="section__text">Web: <a href={`${partnerLangData.website}`} rel="noopener noreferrer" target="_blank"> {`${partnerLangData.website}`}</a></div> : null}


                {deal.externalLink && deal.externalLink.facebook && deal.externalLink.facebook.url && httpReg.test(deal.externalLink.facebook.url) ?
                  <div className="section__text">Facebook: <a href={`${deal.externalLink.facebook.url}`} rel="noopener noreferrer" target="_blank"> {`${deal.externalLink.facebook.url}`}</a></div> : null}


                {deal.externalLink && deal.externalLink.instagram && deal.externalLink.instagram.url && httpReg.test(deal.externalLink.instagram.url) ?
                  <div className="section__text">Instagram: <a href={`${deal.externalLink.instagram.url}`} rel="noopener noreferrer" target="_blank"> {`${deal.externalLink.instagram.url}`}</a></div> : null}

                {deal.externalLink && deal.externalLink.tripadvisor && deal.externalLink.tripadvisor.url && httpReg.test(deal.externalLink.tripadvisor.url) ?
                  <div className="section__text">Tripadvisor: <a href={`${deal.externalLink.tripadvisor.url}`} rel="noopener noreferrer" target="_blank"> {`${deal.externalLink.tripadvisor.url}`}</a></div> : null}


                {/* <a className="deal__btn" rel="noopener noreferrer" target='_blank' href={"deal.externalLink.booknowEN.url"}>
                <div className="btn btn--green btn--fill3">Book now </div>
              </a> */}


                {
                  lang === 'hr' ?
                    (deal.externalLink && deal.externalLink.booknowHR && deal.externalLink.booknowHR.url && httpReg.test(deal.externalLink.booknowHR.url) ?
                      <a className="deal__btn" target='_blank' href={deal.externalLink.booknowHR.url}>
                        <div className="btn btn--green btn--fill3">Rezerviraj </div>
                      </a> : null) :
                    (deal.externalLink && deal.externalLink.booknowEN && deal.externalLink.booknowEN.url && httpReg.test(deal.externalLink.booknowEN.url) ?
                      <a className="deal__btn" target='_blank' href={deal.externalLink.booknowEN.url}>
                        <div className="btn btn--green btn--fill3">Book now </div>
                      </a> : null)
                }

              </div>

              {/* About this object */}
              <div className="dealBox">
                <div className="section__text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dealLangData.description) }} />

              </div>
            </div>

            <HowToGet lang={lang} deal={deal} />
            {/* Use the same IQM Pass for more deals*/}
            <div className="deal__col" style={{ marginBottom: "25px" }}>
              <h2 className="section__h3">{lang === 'hr' ? "Iskoristite svoj IQM Pass i za ostale ponude u destinaciji" : "Use the same IQM Pass for more deals"}</h2>
              {lang === 'hr' ? <div className="section__text">
                Iskoristite IQM Pass za mnogobrojne druge popuste u destinaciji. Pokažite svoj IQM Pass i ostalim pružateljima usluga u destinaciji koji odobravaju popuste.
                <Link className="btn btn--blue btn--empty" to={`${url}/deals#${deal.categories[0]}`} style={{ marginTop: "25px" }}> Pregledajte ovdje sve popuste &nbsp; » </Link>
              </div> :
                <div className="section__text">
                  Once you have your IQM Pass, you can use it for many other deals in this destination. Just show your IQM Pass to participating providers.
                  <Link className="btn btn--blue btn--empty" to={`${url}/deals#${deal.categories[0]}`} style={{ marginTop: "25px" }}> Check all other deals here &nbsp; » </Link>
                </div>}
            </div>

          </div> :

          /* FREE DEALS */
          <div className="deal">
            {/* Use the same IQM Pass for more deals*/}
            <div className="deal__col" style={{ marginBottom: "25px" }}>
              <h2 className="section__h3">{lang === 'hr' ? "Iskoristite svoj IQM Pass i za ostale ponude u destinaciji" : "Use the same IQM Pass for more deals"}</h2>
              {lang === 'hr' ? <div className="section__text">
                Iskoristite IQM Pass za mnogobrojne druge popuste u destinaciji. Pokažite svoj IQM Pass i ostalim pružateljima usluga u destinaciji koji odobravaju popuste.
                <Link className="btn btn--blue btn--empty" to={`${url}/deals#${deal.categories[0]}`} style={{ marginTop: "25px" }}> Pregledajte ovdje sve popuste &nbsp; » </Link>
              </div> :
                <div className="section__text">
                  Once you have your IQM Pass, you can use it for many other deals in this destination. Just show your IQM Pass to participating providers.
                  <Link className="btn btn--blue btn--empty" to={`${url}/deals#${deal.categories[0]}`} style={{ marginTop: "25px" }}> Check all other deals here &nbsp; » </Link>
                </div>}
            </div>

            {/* About the xxx */}
            <div className="deal__col">

              {/* Partner Data */}
              <div className="dealBox">
                <div className="deal__icon">
                  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 46C11.9 46 2 36.1 2 24S11.9 2 24 2s22 9.9 22 22-9.9 22-22 22zm0-42C13 4 4 13 4 24s9 20 20 20 20-9 20-20S35 4 24 4z" />
                    <path d="M23 16h2v20h-2zM23 12h2v2h-2z" />
                  </svg>
                </div>
                <h2 className="section__h3">{categoryAboutProviderMapper(deal.categories[0], lang)}</h2>
                <div className="section__text section__text--bold">{dealLangData.name}</div>
                {deal.address && deal.address.street && deal.address.postal_code && deal.address.city ?
                  <div className="section__text">{`${deal.address.street}, ${deal.address.postal_code}, ${deal.address.city}`}
                    <br />{lang === 'hr' ? "Hrvatska" : "Croatia"}</div> : null}



                {/* {partner.address.country_code */}
                <div className="section__text">{`Phone: ${partnerLangData.phone}`}</div>
                <div className="section__text">{`Email: ${partner.email}`}</div>
                {httpReg.test(partnerLangData.website) ? <div className="section__text">Web: <a href={`${partnerLangData.website}`} rel="noopener noreferrer" target="_blank"> {`${partnerLangData.website}`}</a></div> : null}

                {/* <a className="deal__btn" rel="noopener noreferrer" target='_blank' href={"deal.externalLink.booknowEN.url"}>
    <div className="btn btn--green btn--fill3">Book now </div>
  </a> */}


                {
                  lang === 'hr' ?
                    (deal.externalLink && deal.externalLink.booknowHR && deal.externalLink.booknowHR.url && httpReg.test(deal.externalLink.booknowHR.url) ?
                      <a className="deal__btn" target='_blank' href={deal.externalLink.booknowHR.url}>
                        <div className="btn btn--green btn--fill3">Rezerviraj </div>
                      </a> : null) :
                    (deal.externalLink && deal.externalLink.booknowEN && deal.externalLink.booknowEN.url && httpReg.test(deal.externalLink.booknowEN.url) ?
                      <a className="deal__btn" target='_blank' href={deal.externalLink.booknowEN.url}>
                        <div className="btn btn--green btn--fill3">Book now </div>
                      </a> : null)
                }

              </div>

              {/* About this object */}
              <div className="dealBox">
                <div className="section__text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dealLangData.description) }} />

              </div>
            </div>

          </div>}






        {/* Ubacia san gore da bude sve u containeru */}
        {/* <div className="deal__gallery">
          <h2 className="section__h3">{i18n[lang].gallery}</h2>
          <div className="gallery__box">
            <div className="gallery">
              <SRLWrapper>
                {GalleryItems}
              </SRLWrapper>
            </div>
          </div>
        </div> */}



      </div>



      <div className="spacer spacer--grayBot" />
    </div>
  )

}


// How to get this deal
const HowToGet = ({ lang, deal }) => {


  const dealLangData = deal.dealData.find(data => data.lang === lang)
  const discount = deal.discount.find(discount => {
    if (discount.userType !== 'any') return false
    if ((discount.currency === 'HRK' && lang === 'hr') || (discount.currency === 'EUR' && lang === 'en')) return discount
    else return false
  })

  const { discountType } = discount;


  return (
    <div className="deal__col" id="howtoget">
      <h2 className="section__h3" style={{ marginBottom: "25px" }}>{lang === 'hr' ? "Kako ostvariti popust" : "How to get this deal"}</h2>
      <div className="section__text">
        {lang === 'hr' ?
          <ol>
            <li>Ukoliko još uvijek niste preuzeli svoj IQM Pass, preuzmite ga sada pazeći da IQM Pass važi na dan Vaše posjete.</li>
            <li>{`Kontaktirajte ili posjetite ${dealLangData.name} i izvršite rezervaciju ili obavite kupnju.`}</li>
            <li>Pokažite svoj IQM Pass prije plaćanja računa kako bi ostvarili popust</li>
          </ol> :
          <ol>
            <li> If you don’t already have one, request an IQM Pass now making sure it covers the period of your intended visit..</li>
            <li>{`Contact or visit ${dealLangData.name} directly and make your reservation or purchase`}</li>
            <li> Show your IQM Pass before the payment to get your discount</li>
          </ol>
        }
      </div>
    </div>)


}



const MapBox = ({ google, lang, deal, partner }) => {
  const { address } = deal
  const { long, lat } = address.geoLocation;

  const [place, setPlace] = useState(null)

  const fetchPlaces = (mapProps, map) => {
    if (deal.externalLink && deal.externalLink.googlePlaceID) {
      const { google } = mapProps;
      const service = new google.maps.places.PlacesService(map);
      // var infowindow = new google.maps.InfoWindow();
      var request = {
        placeId: deal.externalLink.googlePlaceID.url,
        // fields: ['name', 'rating', 'formatted_phone_number', 'geometry']
        fields: ["address_component", "adr_address", "business_status", "formatted_address", "geometry",
          "icon", "name", "photo", "place_id", "plus_code", "type", "url", "utc_offset_minutes", "vicinity",
          "formatted_phone_number", "international_phone_number", "opening_hours", "website", "price_level",
          "rating", "review", "user_ratings_total"]


      };
      service.getDetails(request, callback);

      function callback(place, status) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          // createMarkers(place, map)
          setPlace(place)
        }
      }

    }
  }

  const PlacePopup = ({ place }) => {
    console.log(place)
    const { name, rating, user_ratings_total, formatted_address, place_id } = place

    const StarsElements = []
    for (var i = 1; i < rating; i++) {
      StarsElements.push(<div className="googlePopupRating__star googlePopupRating__star--full"></div>)
    }
    if (rating % StarsElements.length && StarsElements.length < 5) {
      StarsElements.push(<div className="googlePopupRating__star googlePopupRating__star--left"></div>)
    }
    if (StarsElements.length < 5) {
      var starsLeft = 5 - StarsElements.length
      for (var j = 0; j < starsLeft; j++) {
        StarsElements.push(<div className="googlePopupRating__star googlePopupRating__star--empty"></div>)
      }
    }



    // let adressTxt = `${address_components[1]} ${address_components[0]}, ${address_components[6]}, ${address_components[2]}, ${address_components[5]}`
    return (<div className="googlePopup">
      <div className="googlePopup__title">{name}</div>
      <div className="googlePopup__text"> {formatted_address}</div>
      <div className="googlePopup__box">
        <div className="googlePopupRating">
          <div className="googlePopupRating__num">{rating}</div>
          <div className="googlePopupRating__stars">
            {StarsElements}
          </div>
        </div>
        <a href={`https://search.google.com/local/reviews?placeid=${place_id}`} className="googlePopup__link" rel="noopener noreferrer" target="_blank">{`Users review: ${user_ratings_total}`}</a>
      </div>
      {/* <a href="" className="googlePopup__link" rel="noopener noreferrer" target="_blank">Prikaz veće karte</a> */}
    </div>)
  }

  return (
    <div className="section" id="googlemap">

      <div className="container">
        <div className="map">
          <h2 className="section__h3">{i18n[lang].location}</h2>
          <div className="mapBox" style={{ position: "relative" }}>

            <Map google={google} zoom={14} initialCenter={{ lat: long, lng: lat }} onReady={fetchPlaces}>
              {place ? <PlacePopup place={place} /> : null}
              <Marker name={'Your position'} position={{ lat: long, lng: lat }} />
            </Map>
          </div>
        </div>
      </div>
      <div className="spacer spacer--grayBot"></div>
      <div className="spacer spacer--grayTop"></div>

    </div>
  );
}
const GoogleMap = GoogleApiWrapper({
  apiKey: ("AIzaSyBw8oSp5nAJC43HjK1GjFRR7hLhkGRezcQ")
})(MapBox)


const i18n = {

  'hr': {
    'about': 'O nama',
    'address': 'Adresa & kontakt',
    'gallery': 'Galerija',
    'working': 'Radni sati',
    'info': 'Više informacija',
    'location': 'Lokacija',
    'days': {
      'M': 'Ponedjeljak',
      'T': 'Utorak',
      'W': 'Srijeda',
      'R': 'Četvrtak',
      'F': 'Petak',
      'S': 'Subota',
      'U': 'Nedjelja'
    }
  },
  'en': {
    'about': 'About us',
    'address': 'Address & Contact',
    'gallery': 'Gallery',
    'working': 'Working hours',
    'info': 'More informations',
    'location': 'Location',
    'days': {
      'M': 'Monday',
      'T': 'Tuesday',
      'W': 'Wednesday',
      'R': 'Thursday',
      'F': 'Friday',
      'S': 'Saturday',
      'U': 'Sunday'
    }
  }

}





const TakeYourPassSectionButton = ({match}) => {

  const { lang, destinationWebUrl } = match.params
  const url = `/${lang}/${destinationWebUrl}`

  return <NavLink className="btn btn--green btn--fill" to={`${url}/take-your-pass`}
  style={{
    maxWidth: "400px",
    height: "75px",
    margin: "auto",
    padding: "15px",
    marginTop: "50px"
  }}>
  {
    lang === "hr" ?
      <span>Preuzmite svoj IQM Pass besplatno </span>
      :
      <span>Take Your free IQM Pass</span>
  }
  </NavLink>
}

