import React, { Fragment, useEffect, useState } from 'react';
import { Switch, Route, Redirect, Link, NavLink } from "react-router-dom";

import $ from "jquery";
import 'slick-carousel'
import DOMPurify from 'dompurify'; // To prevent XSS attacks

import {categorySlugMapper, categoryProxy} from '../helpers'

import SeoHead from '../includes/SeoHead'
import Loader from '../includes/Loader'
import Footer from '../includes/Footer'
import Navbar from '../includes/Navbar'

import DataLayerHead  from '../includes/DataLayerHead'
import GetPassWidget from '../includes/GetPassWidget';

import Network from '../network';
const { loadDestination, getActiveDeals, getPassList } = Network



export default (props) => {

    const { destinationID, match, history, destination } = props
    const categoryHash = history.location.hash.slice(1) || 'all'
    const { lang } = match.params

    const [deals, setDeals] = useState([])
    useEffect(() => {
        getActiveDeals(destinationID).then(deals => {
            setDeals(deals)
            setTimeout(()=>{
                setFetching(false)
            },100)
        }).catch(e => console.log(e))
    }, [])

    const [fetching, setFetching] = useState(true)
    if (fetching) return <Loader />


    // Filter deals by category
    const DealsItemList = deals.map(deal => {
        if (categoryHash === 'all' || deal.categories.find(cat => categoryProxy(cat) === categoryHash))
            return (<DealsItem deal={deal} lang={lang} {...props} />)
    })

    const destinationName = destination.name;
    const webTitle = `IQM Pass - ${lang === 'en'? "Deals in": "Ponude u"}${destinationName.replace("Visit", "")} - IQMpass.com`;

    return (<Fragment>
        <DataLayerHead webTitle={webTitle} />
        <SeoHead match={match} history={history} pageType="DealsPage" />
        <Navbar {...props} />
        <HeroSection match={match} destination={destination} />
        <DealsNav deals={deals} {...props} match={match}/>
        <div id="deals">
            <div className="container">
                <div className="deals">
                    {DealsItemList}
                </div>
            </div>
        </div>

        <GetPassWidget match={match} />
        <Footer {...props} />

    </Fragment>)
}

// HANDLE THE LINK FOR GREEN "GET YOUR PASS" TO GO TO HOMEPAGE/#prices
const HeroSection = ({ destination, match }) => {
    const heroImg = destination.webData.hero_section.images[0]

    const { destinationWebUrl, lang } = match.params
    const url = `/${lang}/${destinationWebUrl}`
    return (
        <div className="section" id="hero">
        {/* <div className="section hero--small" id="hero"> */}
            <div className="heroSlider">
                <div className="heroSlider__item">
                    <img src={`https://assets.iqmpass.com/${heroImg.path}`} alt="" />
                </div>
            </div>
            <div className="container">
                {/* <div className="hero">
                    <h1 className="hero__title">{`${destination.name.replace("Visit", "")} pass deals`}</h1>
                    <Link className="hero__box" to={`${url}/billing`}>{lang === 'hr' ? "UZMITE SVOJU KARTICU": "GET YOUR PASS"}</Link>
                </div> */}
                <div className="hero">
                    {
                        lang === 'hr' ?
                            <Fragment>
                                {/* <h1 className="hero__title">{`${destination.name.replace("Visit", "")} pass ponude`}</h1> */}
                                <h1 className="hero__title">{`${destination.name.replace("Visit", "")}`}</h1>
                                <div class="hero__subtitle">Doživite više, uštedite više</div>
                                <a className="hero__box" href="#dealsNav">Sve ponude i popusti</a>
                            </Fragment> :
                            <Fragment>
                                {/* <h1 className="hero__title">{`${destination.name.replace("Visit", "")} pass deals`}</h1> */}
                                <h1 className="hero__title">{`${destination.name.replace("Visit", "")}`}</h1>
                                <div class="hero__subtitle">Experience more, save more</div>
                                <a className="hero__box" href="#dealsNav">See all deals</a>
                            </Fragment>
                    }
                </div>
            </div>
            <div className="spacer spacer--greenTop" />
        </div>

    )
}



const DealsNav = ({ deals, history, match}) => {

    const {lang} = match.params
    const categoryHash = history.location.hash.slice(1) || 'all'
    const getActiveClassName = (hash) => {
        return `dealsNav__item ${categoryHash === hash ? 'dealsNav__item--active' : ''}`
    }

    const filter = (hash) => {
        history.push(hash)
    }
    const Categories = {}

    const categoriesSort = [
        "food-drink",
        "local-production",
        "excursions", 
        "sport-health",
        "entertainment",
        "to-sleep",
        "attractions",
        "transport"
    ]

    // deals.forEach(deal => deal.categories.forEach(category => Categories[category] = Categories[category] ? Categories[category] + 1 : 1));
    deals.forEach(deal => {
        const categoriesProxy = deal.categories.map(category => categoryProxy(category))
        const categoriesUnique = [...new Set(categoriesProxy)]
        categoriesUnique.forEach(category => Categories[category] = Categories[category] ? Categories[category] + 1 : 1)
    });

    const CategoryNavItems = []

    // for (let cat in Categories) {
        for(var i = 0; i<categoriesSort.length; i++) {
            let cat = categoriesSort[i];
            if(Categories[cat])
                CategoryNavItems.push(
                    <div className={getActiveClassName(cat)} onClick={() => filter(`#${cat}`)}>{categorySlugMapper(cat, lang)}</div>
        )
    }

    useEffect(()=>{
        $("#filter").click(function() {
            $(".dealsNav-wrap").slideToggle("show")
        })
    },[])

    return (
        <div id="dealsNav">
            <div className="container">
                <div className="dealsNav__btn">
                    <div className="dealsNav__item" id="filter">Filters</div>
                </div>
                <div className="dealsNav-wrap">
                    <div className="dealsNav">
                        <div className={getActiveClassName('all')} onClick={() => filter('#all')}>{lang === 'hr'? "Sve" :"All"}</div>
                        {CategoryNavItems}
                    </div>
                </div>
            </div>
        </div>
    );
}

// TODO: Handle better
const DealsItem = ({ deal, lang, match, history }) => {

    
    const dealData = deal.dealData.find(data => data.lang === lang)
    const discount = deal.discount.find(discount => {
        if (discount.userType !== 'any') return false
        if ((discount.currency === 'HRK' && lang === 'hr') || (discount.currency === 'EUR' && lang === 'en')) return discount
        else return false
    })

    
    const curencyMap = {
        "HRK": "kn",
        "EUR": "€"
    }
    const discountType = discount.discountType === 'cash' ? (`-${discount.value}${curencyMap[discount.currency]}`) :
        (discount.discountType === 'trade' ? `-${discount.value}%` : (discount.discountType === 'gift' ? "GIFT": "FREE"))

    // Ovo je za slučaj kada nije free i gift već nema popusta
    const price = deal.price.find(price => {
        if (price.userType !== 'any') return false
        if ((price.currency === 'HRK' && lang === 'hr') || (price.currency === 'EUR' && lang === 'en')) return price
        else return false
      })
    const noDisscout = (discount.discountType === "trade" || discount.discountType === "cash") && discount.value === 0 ? true:false;

        // console.log(discount,discountType)
    const url = match.url.replace('/deals', `/deal/${deal._id}`)

    var coverImageEl = null;
    if(deal.coverImage) {
        var lastIndexOfDot = deal.coverImage.path.lastIndexOf(".");
        var newImageName = `${deal.coverImage.path.slice(0,lastIndexOfDot)}_750.webp`;
        coverImageEl = <img src={`https://assets.iqmpass.com/${newImageName}`} alt="" />
        
    }
    
    return (
        <Link className="deals__item" to={`${url}`} name={dealData.title} title={dealData.title}>
            <div className="card card--large">
                <div className="card__img">
                    {coverImageEl}
                    {/* css je napravljen tako da se prilagodava sirini, tako da vrijednosti mogu bit koliko hoces samo textualno ubaci (npr. "-100€" "-100kn" "Gift" "-90%") */}
                    
                    {!noDisscout? <div className="card__discount">{discountType}</div>:null}
                </div>
                <div className="card__row card__row--white">
                    <h2 className="card__h1">{dealData.title}</h2>
                    <div className="card__text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dealData.shortdesc) }}/>
                    <div className="card__btn">
                        <div className="btnTextArrow" >
                            {lang === 'hr' ?
                                <div className="btnTextArrow__text">{(noDisscout || discountType == "FREE" || discountType =="GIFT")?"Saznaj više":"Otkrijte put do doživljaja"}</div> :
                                <div className="btnTextArrow__text">{(noDisscout || discountType == "FREE" || discountType =="GIFT")?"More":"Discover the path to experience"}</div>
                            }
                            <div className="btnTextArrow__icon">
                                <svg viewBox="0 0 15.698 8.706" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.354 0l-.708.706 3.14 3.147H0v1h13.786L10.646 8l.708.706 4.344-4.353z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="deals__mask" />
        </Link>


    );
}