import React, { Fragment, useState, useRef, useEffect } from 'react';
import $ from "jquery";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import moment from 'moment-timezone';
import * as EmailValidator from 'email-validator';
import ReCAPTCHA from "react-google-recaptcha";

import SeoHead from '../includes/SeoHead'
import Loader from '../includes/Loader'
import Footer from '../includes/Footer'
import Navbar from '../includes/Navbar'

import DataLayerHead from '../includes/DataLayerHead'


import Network from '../network'
const { generateOrder, generateFreePassCards, getPassList, getGiftCardByCode, takeFreePass } = Network



export default (props) => {


  const { destinationID, destination, match, history } = props
  const { lang } = match.params

  const passType = "single";

  const [fetching, setFetching] = useState(true)
  const [passList, setPassList] = useState([])
  useEffect(() => {
    getPassList(destinationID).then(passList => {
      setPassList(passList)
      setFetching(false)
    }).catch(e => console.log(e))
  }, [])



  const destinationName = destination.name;
  // const webTitle =  `IQM Pass - ${destinationName.replace("Visit", "")} ${lang === 'en'? " - Preuzmi IQM Pass": " - Take Your IQM Pass"} - IQMpass.com`;
  const webTitle = `IQM Pass - ${destinationName.replace("Visit", "")} ${lang === 'hr' ? " - Preuzmi IQM Pass" : " - Take Your IQM Pass"}`;

  if (fetching) return <Loader />
  return (
    <Fragment>
      <DataLayerHead webTitle={webTitle} />
      <SeoHead match={match} history={history} pageType="GetPassCardPage" />
      {/* Nav bar not scrolling on single page  FIX it latter */}
      <Navbar lang={lang} transparent={false} {...props} />
      <GetPassCardSection lang={lang} passList={passList} {...props} />
      <Footer {...props} />
    </Fragment>


  )

}


const GetPassCardSection = ({ lang, passList, ...props }) => {

  const { destinationID, match, history } = props

  const { destinationWebUrl } = match.params

  const { destination } = props

  return (
    <div className="section" id="take-passcard">
      <div className="container">
        <h1 className="section__title section__title--blue">
          {
            lang === 'hr' ? <span>Preuzmite svoj IQM Pass besplatno</span> : <span>Take Your free IQM Pass</span>
          }
          <br />
          {
            lang === 'hr' ? `IQM Pass ${destination.name.replace("Visit", "")}` :
              `IQM Pass ${destination.name.replace("Visit", "")}` // - Online Pass
          }
        </h1>

        {/* <BillingInfo destinationWebUrl={destinationWebUrl} lang={lang} /> */}
        {/* <BillingNav history={history} match={match} /> */}
        <div className="billing">
          <BillingSummary passList={passList} lang={lang} {...props} />
        </div>
      </div>
    </div>
  )
}


const BillingSummary = ({ passList, destinationID, history, match }) => {


  const { lang, destinationWebUrl } = match.params
  // const baseUrl = destinationWebUrl ? `/${lang}/${destinationWebUrl}` : `/${lang}`
  const baseUrl = `/${lang}`
  const passType = "single"


  /* Functions */

  function formatDate(date) {
    let month = '' + (date.getMonth() + 1),
      day = '' + date.getDate(),
      year = date.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  function formatDateDisplay(date) {
    date = new Date(date)
    let month = '' + (date.getMonth() + 1),
      day = '' + date.getDate(),
      year = date.getFullYear(),
      hour = '' + (date.getHours()),
      min = '' + date.getMinutes();



    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    if (hour.length < 2)
      hour = '0' + hour;
    if (min.length < 2)
      min = '0' + min;



    // console.log("FORMAT DATE", `${day}.${month}.${year}., ${hour}:${min}`)
    return `${day}.${month}.${year}., ${hour}:${min}`;
  }

  function setDate() {
    let date = new Date()
    date.setDate(date.getDate() + 1);

    return formatDate(date)
  }



  /* HAH DONT JUDGE */
  const [agreeTerms, setAgreeTerms] = useState(false)
  const [errorTerms, setErrorTerms] = useState(false)

  const [passDuration, setPassDuration] = useState(336) // Init 24
  const [numberUsers, setNumberUsers] = useState(1);

  const [startDay, setStartDay] = useState(setDate())
  const [startTime, setStartTime] = useState('00:00')

  const [customerEmail, setCustomerEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState(false)

  const passMap = new Map()
  passList.forEach(pass => {
    const { passType, duration } = pass
    passMap.set(passType, { [duration]: pass, ...passMap.get(passType) })
  })

  const activePassType = passMap.get(passType.toUpperCase())
  const activePass = activePassType ? activePassType[passDuration] : null
  if (!activePass)
    return null

  const onChangeAgreeTerms = (e) => {
    const accepted = e.target.checked
    setAgreeTerms(accepted)
    if (accepted)
      setErrorTerms(false)
  }

  const onChangeDuration = e => {
    setPassDuration(parseInt(e.target.value))
    setStartTime('00:00')
  }

  const onChangeNumberUsers = e => {
    setNumberUsers(e.target.value)
  }
  const onChangeStartDate = e => {
    setStartDay(e.target.value)
  }
  const onChangeStartTime = e => {
    setStartTime(e.target.value)
  }
  const onChangeCustomerEmail = e => {
    setCustomerEmail(e.target.value)
    setErrorEmail(false)
  }

  const startDate = moment(`${startDay}T${startTime}`)
  const endDate = moment(startDate, "DD-MM-YYYY").add(passDuration / 24, 'days');


  return (
    <div className="billingSummary">
      <div className="_billingSummary__col">
        <div className="billingSummary--grid">

          <div className="billingSummary__item billingSummary__item--passDurationSelect">
            <div className="input input--padding">
              <label>{lang === 'hr' ? `• Trajanje IQM Pass-a` : `• IQM Pass duration`}</label>
              <select className="input--duration" name="duration" value={passDuration} onChange={onChangeDuration}>
                <option value="24">24h</option>
                <option value="72">{lang === 'hr' ? `3 dana` : `3 day`}</option>
                <option value="168">{lang === 'hr' ? `7 dana` : `7 days`}</option>
                <option value="336">{lang === 'hr' ? `14 dana` : `14 days`}</option>
              </select>
            </div>
          </div>
          <div className="billingSummary__item billingSummary__item--passUsers">
            <div className="input input--padding">
              <label>{lang === 'hr' ? `• Broj korisnika` : `• Number of users`}</label>
              <input className="input--users" type="number" name="passUsers" onChange={onChangeNumberUsers} value={numberUsers} />
            </div>
          </div>
          <div className="billingSummary__item billingSummary__item--passDate">
            <div className="input input--padding">
              <label>{lang === 'hr' ? `• Datum početka` : `• Start date`}</label>
              <input className="input--date" type="date" name="passDate" value={startDay} onChange={onChangeStartDate} />
            </div>
          </div>
          <div className={`billingSummary__item billingSummary__item--passTime ${passDuration !== 24 ? "hide" : ""}`}>
            <div className="input input--padding">
              <label>{lang === 'hr' ? `• Vrijeme početka` : `• Start time`}</label>
              <input className="input--date" type="time" name="passTime" value={startTime} onChange={onChangeStartTime} />
            </div>
          </div>
          <div className="billingSummary__item billingSummary__item--email">
            <div className="input input--padding">
              <label>Email</label>
              <input className="input--email" type="email" name="CustomerEmail" value={customerEmail} onChange={onChangeCustomerEmail} />
              {
                errorEmail ? (lang === 'hr' ?
                  <div className="billingSummary__error"> Morate unjeti vašu email addresu.</div> :
                  <div className="billingSummary__error"> You need to enter your email address</div>) :
                  null
              }
            </div>
          </div>


          {
            lang === 'hr' ?
              <div className="billingSummary__item billingSummary__item--passSummary">
                <div className="billingSummary__subtitle">• Sumirano</div>
                <div className="billingSummary__text">- <span>{passDuration > 24 ? `${passDuration / 24}d` : `${passDuration}h`}</span> trajanje pass-a</div>
                <div className="billingSummary__text">- Datum početka <span>{formatDateDisplay(startDate)}</span></div>
                <div className="billingSummary__text">- Datum završetka <span>{formatDateDisplay(endDate)}</span></div>
                <div className="billingSummary__text">- Broj korisnika <span>{numberUsers}</span></div>
              </div>
              :
              <div className="billingSummary__item billingSummary__item--passSummary">
                <div className="billingSummary__subtitle">• Summary</div>
                <div className="billingSummary__text">- <span>{passDuration > 24 ? `${passDuration / 24}d` : `${passDuration}h`}</span> duration pass</div>
                <div className="billingSummary__text">- Start date <span>{formatDateDisplay(startDate)}</span></div>
                <div className="billingSummary__text">- End date <span>{formatDateDisplay(endDate)}</span></div>
                <div className="billingSummary__text">- Number of users <span>{numberUsers}</span></div>
              </div>
          }

          <div className="billingSummary__submit">
            <SubmitOrder history={history} match={match} destinationID={destinationID} pass={activePass} startDate={startDate} endDate={endDate} agreeTerms={agreeTerms} CustomerEmail={customerEmail} setErrorTerms={setErrorTerms} setErrorEmail={setErrorEmail} numberOfUsers={numberUsers}/>
          </div>

          <div className="billingSummary__checkbox">
            <label className="checkbox-label">
              <input type="checkbox" name="terms" id="terms" checked={agreeTerms} onChange={onChangeAgreeTerms} />
              <span className="checkbox-custom rectangular" />
              {
                lang === 'hr' ?
                  <span className="checkbox-span">Slažem se s  &nbsp; <Link to={`${baseUrl}/terms`} target="_blank">Uvjetima i uslugama</Link></span> :
                  <span className="checkbox-span"> I agree to the  &nbsp; <Link to={`${baseUrl}/terms`} target="_blank">Terms and Services</Link></span>
              }
            </label>
          </div>

          {
            errorTerms ? (lang === 'hr' ?
              <div className="billingSummary__error"> Morate se složiti s <Link to={`${baseUrl}/terms`} target="_blank">Uvjetima i uslugama</Link> za nastaviti!</div> :
              <div className="billingSummary__error"> You have to agree with our  <Link to={`${baseUrl}/terms`} target="_blank">Terms and Services</Link> to continue!</div>) :
              null
          }
        </div>
      </div>
    </div>

  )
}











const BillingInfo = ({ lang, destinationWebUrl }) => {
  return (
    <div className="billingBox">
      <div className="billing__col">
        <div className="billing__row">
          <div className="billing__img">
            <img src={`/img/backgrounds/cards/hand-${destinationWebUrl}.png`} alt="IQM Pass" />
          </div>
          <div className="billing__wrap">
            {/* <div className="billing__box">
                <div className="billing__icon">
                <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M256 41.096c-91.148 0-165.302 74.154-165.302 165.302 0 31.249 8.867 61.733 25.656 88.241.225.51.514 1 .865 1.453l.271.35c4.6 7.058 9.976 14.003 15.986 20.65L250.449 468.19c1.326 1.713 3.37 2.715 5.535 2.715s4.21-1.003 5.535-2.715L378.31 317.328c6.124-6.746 11.613-13.836 16.318-21.078l.122-.158c.303-.393.56-.813.768-1.25 16.871-26.554 25.784-57.116 25.784-88.444 0-91.148-74.155-165.302-165.302-165.302zm127.26 246.93c-.074.113-.142.227-.208.343-.005.01-.011.018-.017.027-4.375 6.762-9.51 13.395-15.266 19.715-.125.138-.245.28-.36.428l-111.425 143.93-111.603-144.16c-.112-.144-.228-.284-.35-.418-5.65-6.231-10.683-12.73-14.957-19.313-.022-.036-.045-.07-.068-.104-.082-.152-.17-.3-.265-.447-15.729-24.467-24.043-52.694-24.043-81.63 0-83.427 67.874-151.3 151.302-151.3s151.302 67.873 151.302 151.3c0 28.936-8.314 57.163-24.042 81.63z" />
                    <path d="M256 132.058c-40.991 0-74.34 33.35-74.34 74.34s33.349 74.34 74.34 74.34 74.34-33.348 74.34-74.34-33.349-74.34-74.34-74.34zm0 134.68c-33.272 0-60.34-27.068-60.34-60.34 0-33.272 27.068-60.34 60.34-60.34 33.272 0 60.34 27.068 60.34 60.34 0 33.272-27.068 60.34-60.34 60.34z" />
                </svg>
                </div>
                <h2 className="section__h3">{`${destination.name.replace("Visit", "")}`}</h2>
                <div className="section__text section__text--large">Fare zone</div>
            </div> */}
            <div className="billing__box">
              <div className="billing__icon">
                <svg width={51} height={50} viewBox="0 0 51 50" xmlns="http://www.w3.org/2000/svg">
                  <g fill="#010101" fillRule="nonzero">
                    <path d="M47 41h-8c-.552 0-1-.448-1-1v-9H12v9c0 .552-.448 1-1 1H3c-1.752-.107-3.091-1.607-3-3.36V16.36c-.091-1.753 1.248-3.253 3-3.36h44c1.752.107 3.091 1.607 3 3.36v21.28c.091 1.753-1.248 3.253-3 3.36zm-7-2h7c.54 0 1-.62 1-1.36V16.36c0-.74-.46-1.36-1-1.36H3c-.54 0-1 .62-1 1.36v21.28c0 .74.46 1.36 1 1.36h7v-9c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v9z" />
                    <path d="M39 50H11c-.552 0-1-.448-1-1V30c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v19c0 .552-.448 1-1 1zm-27-2h26V31H12v17zM39 15H11c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1h21c.266-.002.521.103.71.29l7 7c.187.189.292.444.29.71v6c0 .552-.448 1-1 1zm-27-2h26V8.41L31.59 2H12v11z" />
                    <path d="M39 9h-7c-.552 0-1-.448-1-1V1c.002-.404.247-.767.62-.92.372-.157.802-.074 1.09.21l7 7c.284.288.367.718.21 1.09-.153.373-.516.618-.92.62zm-6-2h3.59L33 3.41V7zM25 44h-9c-.552 0-1-.448-1-1s.448-1 1-1h9c.552 0 1 .448 1 1s-.448 1-1 1zM34 38H16c-.552 0-1-.448-1-1s.448-1 1-1h18c.552 0 1 .448 1 1s-.448 1-1 1zM8 24c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3zm0-4c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z" />
                  </g>
                </svg>
              </div>
              <h2 className="section__h3">Online pass</h2>
              <div className="section__text section__text--large">{lang === 'hr' ? "PDF na vašem mobitelu" : "PDF on your phone"}</div>
            </div>
          </div>
        </div>


        <div className="billing__desc">
          {lang === 'hr' ?
            <Fragment>
              <h2 className="section__h3">Opis</h2>

              <div className="section__text section__text--bold">Što je uključeno u Pass:</div>
              <div className="section__text">
                Prednosti koje Vam donosi IQM Pass su brojne. Prije svega, ušteda, jer karticom dobivate veću vrijednost za povećani doživljaj.
                <br />
                <br />
                Obratite pažnju na popis naših objekta iz ponude, pripremite se za svoje putovanje i krenite u osvajanje novih doživljaja!
              </div>
            </Fragment> :
            <Fragment>
              <h2 className="section__h3">Description</h2>

              <div className="section__text section__text--bold">What is included in your Pass:</div>
              <div className="section__text">
                The benefits of the IQM Pass are numerous. First of all, there are savings, because with the card you get more value for more exciting experiences.
                <br />
                <br />
                Check out the list of the facilities included in the offer, prepare for your trip and start living out some new experiences!
              </div>
            </Fragment>
          }
        </div>
      </div>

      <div className="billing__col">
        <div className="billing__icon">
          <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <path d="M256 44.247c-82.8 0-150.16 67.361-150.16 150.16 0 57.15 32.83 109.528 84.028 134.702v55.448c0 3.866 3.134 7 7 7h118.264c3.866 0 7-3.134 7-7V329.11c51.198-25.173 84.029-77.551 84.029-134.701 0-82.8-67.361-150.161-150.16-150.161zm56.234 274.059c-2.499 1.136-4.102 3.627-4.102 6.372v52.88H203.868v-52.88c0-2.745-1.604-5.236-4.102-6.372-48.554-22.08-79.927-70.713-79.927-123.898 0-75.08 61.082-136.161 136.161-136.161s136.16 61.081 136.16 136.16c0 53.186-31.372 101.82-79.926 123.899zM306.563 416.177H205.437c-3.866 0-7 3.134-7 7s3.134 7 7 7h101.126c3.866 0 7-3.134 7-7s-3.134-7-7-7zM306.563 453.753H205.437c-3.866 0-7 3.134-7 7s3.134 7 7 7h101.126c3.866 0 7-3.133 7-7s-3.134-7-7-7z" />
          </svg>
        </div>
        {lang === 'hr' ?
          <Fragment>
            <h2 className="section__h3">Napomena</h2>
            <div className="section__text">Nakon preuzimanja IQM Pass-a za određenu destinaciju, na Vaš mail stići će digitalni IQM Pass.</div>
            <div className="section__text"> Molimo sačuvajte na mobitelu Vašu digitalnu karticu i pokažite pri narudžbi i obavezno prije početka procesa plaćanja.</div>
            <div className="section__text"> IQM Pass omogućava Vam navedene popuste u svim navedenim objektima. Popusti se odnose na destinaciju za koju ste kupili IQM Pass, u navedeno vrijeme i navedeni broj osoba.</div>
          </Fragment> :
          <Fragment>
            <h2 className="section__h3">Please note</h2>
            <div className="section__text">After requestign an IQM Pass for a specific destination, a digital IQM Pass will be sent to you by e-mail.</div>
            <div className="section__text">Please keep your digital card on your mobile phone and show it when ordering and before starting the payment process.</div>
            <div className="section__text">The IQM Pass offers you the specified discounts in all of the listed facilities. Discounts apply to the destination for which you purchased your IQM Pass, for the specified time and the specified number of persons.</div>
          </Fragment>}
      </div>
    </div>
  )
}


const SubmitOrder = ({ history, match, destinationID, pass, startDate, endDate, agreeTerms, setErrorTerms, CustomerEmail, setErrorEmail, numberOfUsers }) => {



  const { lang } = match.params

  const handleSubmit = async () => {

    // First check validation 
    if (!agreeTerms) {
      setErrorTerms(true)
      return
    } else if (!EmailValidator.validate(CustomerEmail)) {
      setErrorEmail(true)
      return
    }

    recaptchaRef.current.execute();
    return true
  }




  //   The function to be called when the user successfully completes the captcha
  const onChange = async (verificationToken) => {
    const passActivationDate = startDate.format('YYYY-MM-DDTHH:mm')
    const passExpirationDate = endDate.format('YYYY-MM-DDTHH:mm')

    window.dataLayer.push({
      'event': 'formSubmit',
      'type': pass.passType,
      'duration': pass.duration, 
      'numberOfUsers': numberOfUsers
    });

    const order = await takeFreePass(destinationID, pass._id, passActivationDate, passExpirationDate, CustomerEmail, lang, verificationToken, numberOfUsers)
    history.push(`${match.url}/${order._id}`)


  }
  const recaptchaRef = React.createRef();
  return (
    <Fragment >
      <div className="btn btn--yellow btn--fill btn--arrow" onClick={handleSubmit}>
        <div className="btn__text" > {lang === 'hr' ? "PREUZMI" : "REQUEST"}</div>
        <div className="btn__icon">
          <svg viewBox="0 0 15.698 8.706" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.354 0l-.708.706 3.14 3.147H0v1h13.786L10.646 8l.708.706 4.344-4.353z" />
          </svg>
        </div>
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6Le7fY0aAAAAAJaRU9N2HyquTRXekrDfY_F7UG5v"
        onChange={onChange}
      />
    </Fragment>
  )


}


