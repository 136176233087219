import React from 'react';

export default (props) => {
  return (
    <div className="loader">
      <div className="gooey"><span className="dot" />
        <div className="dots"><span /><span /><span />
        </div>
      </div>
    </div>
  );
}
