import React, { Fragment, useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom";

import $ from "jquery";

export default (props) => {

    // Transparent is by default true, so wee need to pass it false for some pages, for example Billing
    // If you pass anything other then null assing it to transparent else transparent = true
    const transparent = props.transparent !== undefined ? props.transparent : true

    const { match,  defaultLanguage } = props

    const lang = match.params.lang || defaultLanguage

    
    const onScroll = ()=> {
        var navbar = $(".navBar")
        if ($(window).scrollTop() >= 80) {
            $(".navBar").addClass("navBar--scroll");
            if (navbar.hasClass("navBar--white")) {
                $(".navBar__logo").removeClass("navBar__logo--white");
            }
        } else {
            $(".navBar").removeClass("navBar--scroll");
            if (navbar.hasClass("navBar--white")) {
                $(".navBar__logo").addClass("navBar__logo--white");
            }
        }
    }

    /* SCROLL */
    useEffect(() => {
        console.log("SCROLL ON !!!")
        $(window).on('scroll', onScroll);

        return ()=>{
            console.log("SCROLL OFF !!!")
            $(window).off('scroll', onScroll);
        }
        
    }, [])

    useEffect(() => {
        $(".nav__link").click(function () {
            $(".nav__link").removeClass("active");
            $(this).addClass("active");
        });

        /* MOBILE NAVIGATION */
        $(".navBar__btn").click(function () {
            $(".navBar__nav").addClass("slide");
            $(".navBar__mask").fadeIn("slow");
        });
        $(".navBar__close").click(function () {
            $(".navBar__nav").removeClass("slide");
            $(".navBar__mask").fadeOut("slow");
        });
        $(".nav__link").click(function () {
            $(".navBar__nav").removeClass("slide");
            $(".navBar__mask").fadeOut("slow");
        });
        $(".btn.blue.fill").click(function () {
            $(".navBar__nav").removeClass("slide");
            $(".navBar__mask").fadeOut("slow");
        });
    }, [])

    useEffect(() => {
        $('a[href*="#"]')
            // Remove links that don't actually link to anything
            .not('[href="#"]')
            .not('[href="#0"]')
            .click(function (event) {
                // On-page links
                if (
                    window.location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '')
                    &&
                    window.location.hostname === this.hostname
                ) {
                    // Figure out element to scroll to
                    var target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                    // Does a scroll target exist?
                    if (target.length) {
                        // Only prevent default if animation is actually gonna happen
                        event.preventDefault();
                        $('html, body').animate({
                            scrollTop: target.offset().top
                        }, 1000, function () {
                            // Callback after animation
                            // Must change focus!
                            var $target = $(target);
                            $target.focus();
                            if ($target.is(":focus")) { // Checking if the target was focused
                                return false;
                            } else {
                                $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                                $target.focus(); // Set focus again
                            };
                        });
                    }
                }
            });
    }, [])


 

    const changeLangLink = (newLang) => {
        return newLang === defaultLanguage?  `/`: `/${newLang}/`
    }

    const navBarClassName = `navBar ${transparent ? 'navBar--white' : 'navBar--color'}`
    const navBarLogoClassName = `navBar__logo ${transparent ? 'navBar__logo--white' : ''}`
    return (
        <div className={navBarClassName}>
            <div className="container container--large">
                <div className={navBarLogoClassName}>
                <Link to ={lang === 'hr' ? '/hr/': '/'} >
                    <svg width="141" height="80" viewBox="0 0 500 283" xmlns="http://www.w3.org/2000/svg">
                        <g fillRule="nonzero" fill="none">
                            <path d="M464.1.868l35.792 203.47h-29.285L452.387 90.13 396.42 213.124 341.866 90.022l8.35-47.723 46.639 105.64L464.1.868zM163.774 11.497c24.078 0 45.554 8.134 64.642 24.295 22.886 19.414 34.274 44.794 34.274 76.139 0 24.512-9.002 46.312-27.115 65.292l-19.414 15.293c-15.727 10.087-33.298 15.185-52.712 15.185-25.054 0-47.397-8.46-66.811-25.488-21.15-18.764-31.779-42.625-31.779-71.692 0-28.525 9.978-52.278 30.043-71.367 19.415-18.438 42.408-27.657 68.872-27.657zM28.85 15.076v189.262H.325V15.076H28.85zm134.599 23.21c-20.282 0-36.985 6.833-50 20.608-13.124 13.666-19.74 30.91-19.74 51.844 0 20.39 6.833 37.093 20.39 50.108 14.1 13.449 30.261 20.065 48.374 20.065 12.364 0 23.536-2.82 33.514-8.46l19.74-15.726c12.039-12.582 18.112-28.2 18.112-46.747 0-20.39-6.724-37.418-20.173-51.084-13.45-13.775-30.26-20.608-50.217-20.608z" fill="#3553A3"></path>
                            <path fill="#28AE70" d="M216.16 192.516L195.88 172.56l-44.469-43.167h36.876l27.44 27.332 19.957 20.498 51.844 50.326L331.887.868l18.33 41.431-8.351 47.723-34.708 192.19z"></path>
                        </g>
                    </svg>
                    </Link>
                </div>
                <div className="navBar__btn">
                    <div>
                        <svg width="24" height="16" viewBox="0 0 24 16" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23 14l.125.006c.53.052.875.438.875.994l-.006.126c-.052.53-.437.874-.994.874H1l-.125-.006C.345 15.942 0 15.558 0 15l.006-.125C.059 14.345.443 14 1 14h22zm0-7l.125.006c.53.052.875.437.875.994l-.006.126c-.052.53-.437.874-.994.874H1l-.125-.006C.345 8.942 0 8.558 0 8l.006-.125C.059 7.345.443 7 1 7h22zm0-7l.125.006c.53.052.875.437.875.994l-.006.125c-.052.53-.437.874-.994.874H1l-.125-.006C.345 1.941 0 1.557 0 1L.007.875C.059.345.443 0 1 0h22z" fill="#3654A3" fillRule="nonzero"></path>
                        </svg>
                    </div>
                </div>
                <div className="navBar__mask"></div>
                <div className="navBar__nav">
                    <div className="navBar__close"></div>
                    <ul>
                        {
                            lang === 'hr' ?
                                <Fragment>
                                    <li><NavLink className="nav__link active" to={`#hero`}>Naslovnica</NavLink></li>
                                    <li><NavLink className="nav__link" to={`#about`}>Što je IQM Pass</NavLink></li>
                                    <li><NavLink className="nav__link" to={`#questions`}>Kako funkcionira</NavLink></li>
                                    <li><NavLink className="nav__link" to={`#destinations`}>Destinacije</NavLink></li>
                                    <li><NavLink className="nav__link" to={"#footer"}>Kontakt</NavLink></li>
                                </Fragment> :
                                <Fragment>
                                    <li><NavLink className="nav__link active" to={`#hero`}>Home</NavLink></li>
                                    <li><NavLink className="nav__link" to={`#about`}>About IQM Pass</NavLink></li>
                                    <li><NavLink className="nav__link" to={`#questions`}>How it works</NavLink></li>
                                    <li><NavLink className="nav__link" to={`#destinations`}>Destinations</NavLink></li>
                                    <li><NavLink className="nav__link" to={"#footer"}>Contact</NavLink></li>
                                </Fragment>
                        }
                        <li className="nav__link--lang">
                            <div className="nav__link">
                                <div className="nav__lang">{lang ? lang.toUpperCase() : 'en'.toUpperCase()}</div>
                                <div className="nav__langList">

                                    
                                    <Link style={{display: "block"}} className="nav__langList-item" to={changeLangLink('hr')}>HR</Link>
                                    <Link style={{display: "block"}} className="nav__langList-item" to={changeLangLink('en')}>EN</Link>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>


    );
}
