import React, { Fragment, useRef, useEffect, useState} from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import SeoHead from '../includes/SeoHead'
import Footer from '../includes/Footer'
import Navbar from '../includes/Navbar'

export default (props) => {
    const { match, history} = props
    const { lang } = match.params
    
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    /* Todo: Replace me */
    return (<Fragment>
        <SeoHead match={match} history={history} pageType="PaymentErrorPage"/>
        <Navbar lang={lang} {...props} />
        <Reject />
        <Footer {...props}/>
    </Fragment>)
    
}



function Reject() {
    return (
        <div className="section" id="success">
            <div className="container">
                <h1 className="section__title section__title--blue">Ups... something went wrong!</h1>
                <div className="reject__icon">
                    <svg className="feather feather-minus-circle" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
                        <circle cx={12} cy={12} r={10} />
                        <path d="M8 12h8" />
                    </svg>
                </div>
                <div className="section__subtitle">Your payment was rejected</div>
                <div className="success__btn"><Link className="btn btn--yellow btn--fill2" to={"/"}>Back to homepage</Link>
                </div>
            </div>
        </div>
    )
}