import React, { Fragment, useState, useEffect} from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect} from "react-router-dom";
// import cookieconsent from 'cookieconsent'
import webconfig from './webconfig'

import LandingPage  from './pages/LandingPage'
import TermsAndServicesPage from './pages/TermsAndServicesPage'
import PrivacyPage from './pages/PrivacyPage'
import NotFound404Page from './pages/NotFound404Page'

import DestinationRouter from './DestinationRouter'



import Loader from './includes/Loader'
import Network from './network'

const  {loadDestinationList} = Network

const defaultLanguage = webconfig.defaultLanguage


function App() {

  const [destinationList, setDestinationList] = useState([])

  useEffect(()=>{
    loadDestinationList().then((destinationList) => {
      setDestinationList(destinationList.filter(destination => destination.webUrl))
    }).catch(e => console.log(e))
  }, [])
  
  

  if(!destinationList.length) 
    return  <Loader />

  return (<Router>
    <Switch>
    

    <Route path = {"/"} exact render = {(props) => <LangRoute component={LandingPage} destinationList = {destinationList} {...props} />}></Route>  
    <Route path = {"/:lang/"} exact render = {(props) => <LangRoute component={LandingPage} destinationList = {destinationList} {...props} />}></Route>  

    <Route exact path={`/:lang/privacy`} render = {(props)=> <LangRoute component={PrivacyPage} {...props} destinationList = {destinationList} /> }></Route>
    <Route exact path={`/:lang/terms`} render = {(props)=> <LangRoute  component={TermsAndServicesPage} {...props} destinationList = {destinationList} /> }></Route>
    <Route path = {"/:lang/:destinationWebUrl"} render = {props => <LangRoute component={DestinationRouter} destinationList = {destinationList} {...props} />}></Route>  

    <Route component = {NotFound404Page} />

    </Switch>
  </Router>)
}

/* Nice Hah that check if lang params is in the list, if not redirect to NotFound404Page */
const LangRoute = ({component: Component, ...props}) => {
  const {match} = props
  const lang = match.params.lang || defaultLanguage
  const langList = ['hr', 'en']
  
  // if(match.params.lang === defaultLanguage && match.path === "/:lang/") {
  //   console.warn("Redirecting ...")
  //   return <Redirect exact path = {`/${lang}`} to={"/"} />
  // }
  // else if  (lang && langList.find(l => l=== lang)) return <Component defaultLanguage = {defaultLanguage} {...props}  />
  if  (lang && langList.find(l => l=== lang)) return <Component defaultLanguage = {defaultLanguage} {...props}  />
  else return <NotFound404Page {...props} />
}






export default App;
