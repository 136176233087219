import React, { Fragment, useRef, useEffect, useState} from 'react'

import {getQueryStringParams} from '../helpers'

import SeoHead from '../includes/SeoHead'
import Footer from '../includes/Footer'
import Navbar from '../includes/Navbar'
import Loader from '../includes/Loader'

import Network from '../network';
const { getOrder, getGiftCard} = Network

export default (props) => {
    const {destinationID, match, history} = props
    const { lang } = match.params


    
    const {_id:orderID} = props.order;
    
    const [pass, setPass ] = useState({})
    const [order, setOrder] = useState({})
    // const [giftcard, setGiftcard] = useState(null)
    
    const LoadData = async ()=>{
        const order = await getOrder(destinationID,orderID);
        const giftcard = await getGiftCard(destinationID, order.giftcardID)
        setPass(order.pass)
        setOrder(order)
        // setGiftcard(giftcard)
        setFetching(false)
    }

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    useEffect(()=>{
        try {
            LoadData()
        } catch (error) {
            console.log(error)
        }
    },[])
    
    const [fetching, setFetching] = useState(true)
    if (fetching) return <Loader />

    return (<Fragment>
        <SeoHead match={match} history={history} pageType="GiftSuccessPage"/>
        <Navbar lang={lang} {...props} />
        {
            lang === 'hr'?
            <SuccessHR lang={lang}  order = {order}  pass={pass} />:
            <SuccessEN lang={lang}  order = {order}  pass={pass} />

        }
        <Footer {...props}/>
    </Fragment>)
    
}



function SuccessHR({lang, order, giftcard, pass}) {

    
    const {customer, _id:orderID} = order
    const priceHRK = pass.price.find(p => p.currency === 'HRK')
    const priceEUR = pass.price.find(p => p.currency === 'EUR')

    // const amount = lang === 'en' ? `${priceHRK.value} ${priceHRK.currency} ~ (${priceEUR.value} ${priceEUR.currency})`: `${priceEUR.value} ${priceEUR.currency}`

    return (
        <div className="section" id="success">
            <div className="container">
                <h1 className="section__title section__title--blue">Čestitamo, uspješno ste zatražili IQM Pass</h1>
                <div className="success__icon">
                    <svg className="feather feather-check-circle" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                        <path d="M22 4L12 14.01l-3-3" />
                    </svg>
                </div>
                <div className="section__subtitle">Vaš IQM Pass će ubrzo bit poslan na email <i>{customer.CustomerEmail}</i></div>

                <div className="section__text"><b>Vrsta IQM Passa:</b> {pass.passType} </div>
                <div className="section__text"><b>Trajanje IQM Passa:</b> {pass.duration > 24 ? `${pass.duration / 24} dana` : `${pass.duration} sata`} </div>
                <br />
                <div className="section__text">
                Možete isprintati IQM Pass ili ga možete koristit kao digitalnu karticu na vašem mobilnom uređaju.<br/>
                Molimo pokažite IQM Pass prije svake kupnje u objektu da Vam djelatnici pravovremeno mogu odobriti navedeni popust.</div>
                <div className="success__btn"><a className="btn btn--yellow btn--fill2" href="/">Nazad na destinacijsku ponudu</a></div>
                <br />
                <br />
                <br />
                <div className="section__text">Vaš Pass će ubrzo bit poslan na email <b>{customer.CustomerEmail}</b>
                    <br />Molimo <a href="mailto:info@iqmpass.com">kontaktirajte nas</a> ukoliko ga ne zaprimite u sljedećih 10 minuta.</div>
            </div>
        </div>
    )
}

function SuccessEN({lang, order, giftcard, pass}) {

    const {customer, _id:orderID} = order
    const priceHRK = pass.price.find(p => p.currency === 'HRK')
    const priceEUR = pass.price.find(p => p.currency === 'EUR')

    const amount = lang === 'en' ? `${priceHRK.value} ${priceHRK.currency} ~ (${priceEUR.value} ${priceEUR.currency})`: `${priceEUR.value} ${priceEUR.currency}`

    return (
        <div className="section" id="success">
            <div className="container">
                <h1 className="section__title section__title--blue">Congratulation, You have successfully requested IQM Pass</h1>
                <div className="success__icon">
                    <svg className="feather feather-check-circle" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                        <path d="M22 4L12 14.01l-3-3" />
                    </svg>
                </div>
                
                <div className="section__subtitle">Your IQM Pass will be sent shortly to your email <b>{customer.CustomerEmail}</b>.</div>

                <div className="section__text"><b>IQM Pass type:</b> {pass.passType} </div>
                <div className="section__text"><b>IQM Pass duration:</b> {pass.duration > 24 ? `${pass.duration / 24} days` : `${pass.duration}h`} </div>
                <br />
                <div className="section__text">
                You can print an IQM Pass or use it as a digital card on your mobile phone. <br/>
                Please show your IQM Pass before each purchase in the facility, so that the employees can grant you the specified discount in a timely manner.</div>
                <div className="success__btn"><a className="btn btn--yellow btn--fill2" href="/">Back to destination offer</a></div>
                <br />
                <br />
                <br />
                <div className="section__text">Your request have been placed under reference: <u>{orderID}</u>.
                    <br />Please <a href="mailto:info@iqmpass.com">contact us</a> if you do not receive it in next 10 minutes.
                </div>
            </div>
        </div>
    )
}