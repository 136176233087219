import React, { Fragment, useState, useRef, useEffect } from 'react';


import SeoHead from '../includes/SeoHead'
import Loader from '../includes/Loader'


import NotFound404Page from './NotFound404Page'
import GiftSuccessPage from './GiftSuccessPage'

import Network from '../network'
const { getOrder,  getGiftCard, generateGiftOrder} = Network


export default (props) => {

    const {match, history, destinationID} = props;
    const {orderID, destinationWebUrl, lang} = match.params
    
    const url = `/${lang}/${destinationWebUrl}`

    const [order, setOrder] = useState(null)

    const [orderCompleted, setOrderCompleted] = useState(null)

    useEffect(()=>{
        getOrder(destinationID, orderID).then(order => {

            setOrder(order)
            setFetching(false)
          
        }).catch(e => console.log(e));
    },[])

    
    const [fetching, setFetching] = useState(true)
    if (fetching) return <Loader />

    else if (!order) return <NotFound404Page {...props} />

    if(order && order.status === "REJECTED") return <NotFound404Page {...props} />

    else if(order && order.status === "ACCEPTED") return <GiftSuccessPage  order={order} {...props}/>

}