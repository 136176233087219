import React, { Fragment, useRef, useEffect, useState } from 'react'

import SeoHead from '../includes/SeoHead'
import FooterRoot from '../includes/FooterRoot'
import NavbarSubRoot from '../includes/NavbarSubRoot'

import DataLayerHead  from '../includes/DataLayerHead'


export default (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    const { match, history, defaultLanguage } = props
    const { lang } = match.params

    const webTitle =  `IQM Pass - ${lang === 'en'? "Privacy": "Privatnost" } - IQMpass.com`;

    /* Todo: Replace me */
    return (<Fragment>
        <DataLayerHead webTitle={webTitle} />
        <SeoHead match={match} history={history} pageType="PrivacyPage"/>
        <NavbarSubRoot lang={lang} {...props} />
        <HeroPrivacy match = {match}/>
        {lang === 'hr' ?
            <PrivacyHR /> :
            <PrivacyEN />
        }
        <FooterRoot defaultLanguage={defaultLanguage} {...props}/>
    </Fragment>)

}


function PrivacyHR() {
    return (
        <div className="section" id="privacy">
            <div className="container">
                <div className="section__text">Sukladno zahtjevima Uredbe (EU) 2016/679 Europskog Parlamenta i Vijeća od 27. travnja 2016. godine o zaštiti pojedinca u vezi s obradom osobnih podataka i o slobodnom kretanju takvih podataka te o stavljanju izvan snage Direktive 95/46/EZ (Opća uredba o zaštiti osobnih podataka – GDPR) i Zakona o provedbi Opće uredbe o zaštiti podataka, FEEL IQM d.o.o., Gravot 7/B, Mali Lošinj, OIB: 45732227989 poštuje i uvažava privatnost svakog posjetitelja naših web stranica te u nastavku ovih Pravila privatnosti pruža sve informacije o prikupljanju i obradi osobnih podataka, koje se odnose na korištenje naših web stranica.</div>
                <h2 className="section__title section__title--blue">Kolačići</h2>
                <div className="section__text">Ova web stranica koristi kolačiće. Kada posjetite našu web stranicu, u početku vam se daje “kolačić”, a vašem računalu dodjeljuje ID broj. Kolačić je uobičajeni dio softvera koji prati pojedinačne preferencije korisnika i koji može poboljšati Vaše iskustvo posjeta web stranici. Kolačići nam omogućuju pružanje prilagođenog iskustva bez da nas morate podsjetiti na svoje postavke svaki put kada posjetite našu web stranicu. Ne dijelimo vašu aktivnost na našoj web stranici s trećim stranama, niti prikupljamo podatke s drugih web stranica koje posjetite. Ovi kolačići ne prikupljaju nikakve informacije o vama koje bi se mogle koristiti za marketing ili praćenje povijesti pregledavanja na drugim web stranicama, uslugama ili aplikacijama.</div>
                <h3 className="section__h3">Podaci o pregledavanju i korištenju web stranice</h3>
                <div className="section__text">Informacije prikupljene automatski sa stranice, uključujući IP adrese ili nazive domena računala koja koriste korisnici koji se povezuju sa stranicom, URI adrese (Uniform Resource Identifier), vrijeme zahtjeva, način korištenja zahtjeva za slanje zahtjeva poslužitelju, veličina zaprimljene datoteke u odgovoru, numeričkom kodu koji označava status odgovora poslužitelja (uspješan ishod, pogreška itd.), zemlju podrijetla, značajke preglednika i operacijskog sustava koji koristi posjetitelj , različite vremenske detalje po posjetu (npr. vrijeme provedeno na svakoj stranici) te pojedinosti o putu koji slijedi unutar stranice s posebnim osvrtom na redoslijed posjećenih stranica i druge parametre o npr. operativnom sustavu i korisničkom informatičkom okruženju.</div>
                <h3 className="section__h3">Google Analytics: usluga za web analitiku koju pruža Google, Inc. (“Google”).</h3>
                <div className="section__text">Ova usluga koristi Kolačiće. Kolačići pomažu web stranici da analizira kako posjetitelji naše web stranice koriste web stranicu. Informacije koje generira Cookie o Vašoj upotrebi web stranice (uključujući vašu IP adresu) bit će prenesene na Google te se u pogledu tih podataka Google ima smatrati voditeljem obrade. Google će koristiti ove informacije u svrhu procjene Vaše upotrebe web stranice, sastavljanje izvješća o aktivnostima na web i pružanje drugih usluga vezanih uz aktivnost na web stranici i korištenje interneta. Google također može prenijeti te podatke trećim stranama, ako to zahtijeva zakon, ili izvršiteljima obrade. Google neće povezati vašu IP adresu s drugim podacima koje Google posjeduje. Možete odbiti upotrebu kolačića odabirom odgovarajućih postavki u pregledniku, no napominjemo da, ako to učinite, možda nećete moći koristiti punu funkcionalnost ove web stranice. Prihvaćanjem svih kolačića, pristajete na obradu Vaših osobnih podataka od strane Googlea na način i za gore navedene svrhe.</div>
                <h3 className="section__h3">Upravljanje postavkama kolačića</h3>
                <div className="section__text">Ako ne želite prihvatiti kolačiće, možete ih blokirati podešavanjem postavki u prozoru za kolačiće, ali i u internetskom pregledniku. Ukoliko promijenite postavku u Vašem pregledniku, ta prilagodba može blokirati kolačiće za bilo koju web stranicu koju posjetite. Možete posjetiti www.aboutcookies.org, koji pruža detaljne informacije o upravljanju kolačićima u popularnim preglednicima.</div>
                <div className="section__text">Blokiranje kolačića može utjecati na vašu sposobnost da iskoriste pogodnosti koje pruža korištenje kolačića, mogu utjecati na vašu mogućnost korištenja određenih značajki prilagodbe i, u slučaju nužnih kolačića za pravilan rad web stranice, može utjecati na kvalitetu Vašeg iskustva na ovoj Internet stranici.</div>
                <h3 className="section__h3">THIRD PARTY COOKIES (Kolačići treće strane)</h3>
                <div className="section__text">Kolačići treće strane mogu biti instalirani na našoj web stranici. Kolačići treće strane su kolačići koje postavlja domena koja nije web stranica koju korisnik posjećuje. Ako korisnik posjeti web stranicu i drugi entitet postavlja kolačić kroz tu web stranicu, to bi bio kolačić treće strane.</div>
                <h3 className="section__h3">PRIMJERI ZA THIRD PARTY COOKIES KOJI MOGU BITI KORIŠTENI NA OVOJ STRANICI</h3>
                <div className="section__text">Google AdWords: prikuplja statističke podatke o korištenju i konverziji.
                    <br />Google Remarketing: omogućuje oglašavanje korisnicima koji su posjetili web mjesto.
                    <br />Facebook: dijeljenje na društvenim medijima.</div>
                <h3 className="section__h3">ADVERTISING COOKIES (Kolačići za oglašavanje)</h3>
                <div className="section__text">Kolačići za oglašavanje su kolačići koji se upotrebljavaju za prikazivanje oglasa koji su relevantniji za interese korisnika. Prepoznaju da je korisnik već pregledao ovo web mjesto. Često su povezani s funkcionalnošću web mjesta. Ovi kolačići upotrebljavaju se za prikupljanje podataka o navikama pregledavanja kako bi oglasi koji se prikazuju bili relevantni za Vas i Vaše interese.</div>
                <div className="section__text">Imajte na umu da to ne utječe na broj oglasa koji će Vam biti prikazani. To utječe samo na njihovu vrstu i sadržaj, pa će oni biti relevantniji Vašim interesima.</div>
                <h3 className="section__h3">TAILORED ADVERTISING (Prilagođeno oglašavanje)</h3>
                <div className="section__text">Ako na vašem računalu imate omogućene kolačiće, možemo vam predstaviti oglase relevantne za našu web stranicu. Google i dobavljači treće strane prikazuju naše oglase na različitim internetskim stranicama. Ako ste uključili kolačiće, možete vidjeti oglase za ovu web stranicu na drugim web stranicama. Ako se želite isključiti iz prilagođenog oglašavanja, posjetite stranicu za isključivanje mrežnih oglašavačkih mreža <a href="http://www.networkadvertising.org/choices/" target="_blank">networkadvertising.org</a>
                </div>
                <h2 className="section__title section__title--blue">Osobni podaci prikupljeni radi izdavanja Destinacijske Kartice (IQM Pass):</h2>
                <div className="section__text">
                    <ul>
                        <li>ime i prezime gosta</li>
                        <li>adresa prebivališta (boravišta)</li>
                        <li>adresa elektroničke pošte, broj telefona</li>
                        {/* <li>podaci o bankovnom računu i financijski detalji o transakciji.</li> */}
                    </ul>
                </div>
                <div className="section__text">Svrha obrade navedenih osobnih podataka je nužnost obrade za izvršavanje ugovora u kojem je ispitanik stranka. Ime i prezime gosta te adresa se unose u Destinacijsku Karticu, temeljem koje je gost ovlašten zatražiti odobrenje popusta od strane pojedinih proizvođača/pružatelja usluga unutar turističke destinacije. Uz zahtjev za odobrenjem popusta te posjedovanje Destinacijske Kartice (IQM Pass), gost je obvezan dati na uvid pojedinom proizvođaču/pružatelju usluga valjan identifikacijski dokument, a za takvu obradu podataka se voditeljem obrade smatra pojedini proizvođač/pružatelj usluga. Radi izvršenja ugovora je nužno da Voditelj obrade može kontaktirati gosta te istome dostaviti Destinacijsku Karticu u elektroničkom obliku, radi čega se prikuplja adresa elektroničke pošte i broj telefona.</div>
                {/* <div className="section__text">Prilikom isplate naknade gosta Voditelju obrade, isti zaprima podatke o bankovnom računu i financijske detalje transakcije kojom je proveden nalog za plaćanje. Ovi podaci mogu biti dostupni i izvršiteljima obrade, primjerice računovodstvenom servisu, pružateljima financijskih usluga kojima se omogućuje plaćanje preko weba (primjerice PayPal), pružateljima softverske podrške i drugih informatičkih usluga, pri čemu će obrada biti usklađena s prvotnom svrhom obrade, odnosno ista će se provoditi isključivo radi izvršenja ugovornog odnosa između gosta i Voditelja obrade.</div> */}
                <h2 className="section__title section__title--blue">Podaci koji se prikupljaju prilikom zaprimanja Vaših upita: </h2>
                <div className="section__text">
                    <ul>
                        <li>ime, prezime, e-mail adresa i drugi osobni podaci koje sam gost ili posjetitelj web stranice upiše u poruku</li>
                    </ul>
                </div>
                <div className="section__text">Svrha prikupljanja navedenih osobnih podataka je omogućavanje gostima, potencijalnim gostima i drugim posjetiteljima web stranice da njenim putem stupe u kontakt s Voditeljem obrade i postave upite vezane uz njegovu djelatnost. Svojevoljnim i dobrovoljnim upisom svojih osobnih podataka i slanjem poruke izražavate suglasnost s obradom osobnih podataka u skladu s odredbama ove točke te dajete jasnu privolu Voditelju obrade. U svakom trenutku imate pravo na povlačenje privole za obradu osobnih podataka.</div>
                <div className="section__text">Povlačenjem ili opozivanjem privole za obradu osobnih podataka radi upućivanja odgovora na upite Vaši osobni podaci se više neće koristiti u tu svrhu, međutim povlačenje ili opozivanje iste ne utječe na zakonitost obrade koja se temeljila na privoli prije njena povlačenja. Isti princip se primjenjuje i na ostale oblike obrade osobnih podataka na temelju privole od strane Voditelja obrade.</div>
                <div className="section__text">Ukoliko prilikom ili po rješavanju Vašeg upita stupite u ugovorni ili predugovorni odnos s Voditeljem obrade, Vaši će se podaci dalje obrađivati kao nužni za izvršenje ugovora ili poduzimanje radnji prije ugovora na zahtjev ispitanika.</div>
                <h2 className="section__title section__title--blue">Primatelji/kategorije primatelja osobnih podataka:</h2>
                <div className="section__text">Pojedini osobni podaci gosta mogu biti ustupljeni javnim tijelima na temelju njihovih zakonskih ovlasti i/ili prilikom provođenja nadzora, kao i izvršiteljima obrade, ugovornim partnerima Voditelja obrade pod istim uvjetima i zaštitnim mjerama obrade. Izvršitelji obrade su primjerice računovodstveni servis, pružatelji informatičkih usluga i slično. Osobni podaci također mogu biti dostavljeni i samostalnim voditeljima obrade kao primjerice odvjetnicima, pri čemu će obrada osobnih podataka biti usklađena s prvotnom svrhom prikupljanja.</div>
                <h2 className="section__title section__title--blue">Način obrade osobnih podataka:</h2>
                <div className="section__text">Podaci se obrađuju uz primjenu svih propisanih i prikladnih pravnih, organizacijskih i tehničkih mjera zaštite osobnih podataka. Pristup je omogućen samo ovlaštenim osobama, uz primjenu mjera informatičke sigurnosti od neželjenog pristupa i zaštite od gubitka podataka.</div>
                <div className="section__text">Pružanje osobnih podataka jest uvjet nužan za izvršenje ugovora, odnosno izdavanje Destinacijske Kartice (IQM Pass). Ako gost ne pruži potrebne osobne podatke, Voditelj obrade neće biti u mogućnosti ispuniti svoje zakonske i/ili ugovorne obveze te osigurati gostu ostvarenje njegovih prava.</div>
                <h2 className="section__title section__title--blue">Razdoblje pohrane osobnih podataka koji se prikupljaju i obrađuju:</h2>
                <div className="section__text">Osobni podaci prikupljeni prilikom izdavanja Destinacijske Kartice se pohranjuju u roku od pet godina od isteka vremena važenja Destinacijske Kartice (IQM Pass), odnosno do isteka zastarnih rokova na potraživanja vezana uz izdavanje i korištenje Destinacijske Kartice (IQM Pass).</div>
                {/* <div className="section__text">Osobni podaci koji se prikupljaju i obrađuju na temelju Zakona o računovodstvu čuvaju se i pohranjuju na rok od 11 godina.</div> */}
                <div className="section__text">Podaci koji se prikupljaju radi komunikacije čuvaju se i pohranjuju najmanje tri godine od isteka godine u kojoj su prikupljeni.</div>
                <div className="section__text">Ako ne postoje posebni stvarni i pravni razlozi čuvanja osobnih podataka na duži rok od predviđenog, podaci se brišu u godini koja slijedi isteku najmanjeg roka čuvanja.</div>
                <h2 className="section__title section__title--blue">Prava ispitanika u pogledu prikupljanja i obrade osobnih podataka:</h2>
                <div className="section__text">U svakom trenutku zajamčena su Vam sljedeća prava:</div>
                <div className="section__text"><strong>Pravo na pristup osobnim podacima</strong> - Vaše je pravo od Voditelja obrade dobiti potvrdu obrađuju li se Vaši osobni podaci, informacije o svrsi obrade, kategorijama podataka koji se obrađuju, primateljima, vremenu pohrane, te postojanju prava da se zatraži ispravak, brisanje, ograničenje obrade ili prigovor na obradu.</div>
                <div className="section__text"><strong>Pravo na ispravak</strong> - imate pravo zatražiti ispravak netočnih podataka ili dopuniti nepotpune podatke koji se na Vas odnose.</div>
                <div className="section__text"><strong>Pravo na brisanje</strong> - imate pravo od Voditelja obrade ishoditi brisanje osobnih podataka koji se na Vas odnose pod određenim uvjetima, osim ukoliko njihovo čuvanje nije propisano zakonom.</div>
                <div className="section__text"><strong>Pravo na ograničenje obrade osobnih podataka</strong> - imate pravo od Voditelja obrade ishoditi ograničenje obrade koje se na Vas odnose pod određenim uvjetima.</div>
                <div className="section__text"><strong>Pravo na ulaganje prigovora na obradu</strong> - imate pravo uložiti prigovor na obradu Vaših osobnih podataka.</div>
                <div className="section__text"><strong>Pravo na prenosivost podataka</strong> - imate pravo prenijeti svoje osobne podatke kod drugog Voditelja obrade, a ukoliko je tehnički izvedivo, prijenos će se izvršiti automatiziranim putem.</div>
                <div className="section__text"><strong>Pravo na podnošenje prigovora nadzornom tijelu:</strong> Agencija za zaštitu osobnih podataka, Selska cesta 136, HR - 10 000 Zagreb, Tel. 00385 (0)1 4609-000, Fax. 00385 (0)1 4609-099, E-mail: <a href="mailto:azop@azop.hr">azop@azop.hr</a>, web: <a href="https://azop.hr/" target="_blank">www.azop.hr</a>.</div>
                <div className="section__text">Sva navedena prava u bilo kojem trenutku možete ostvariti pisanim putem na adresu Voditelja obrade čiji su kontakt podaci navedeni u ovoj obavijesti.</div>
            </div>
        </div>
    )
}


function PrivacyEN() {
    return (
        <div className="section" id="privacy">
            <div className="container">
                <div className="section__text">In accordance with the requirements of Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation - GDPR) and the Act implementing the General Data Protection Regulation, FEEL IQM d.o.o., Gravot 7/B, Mali Lošinj, PIN: 45732227989 respects and the privacy of each visitor to our website and this Privacy Policy provides all information about the collection and processing of personal data, relating to the use of our website.</div>
                <h2 className="section__title section__title--blue">Cookies</h2>
                <div className="section__text">This website uses cookies. When you visit our website, you are initially given a "cookie" and your computer is assigned an ID number. A cookie is a common part of software that tracks individual user preferences and that can enhance your website visiting experience. Cookies allow us to provide a customized experience without you having to remind us of your settings every time you visit our website. We do not share your activity on our website with third parties, nor do we collect information from other websites you visit. These cookies do not collect any information about you that could be used for marketing purposes or tracking your browsing history on other websites, services or applications.</div>
                <h3 className="section__h3">Information on browsing and using the website</h3>
                <div className="section__text">Information collected automatically from the site, including IP addresses or computer domain names used by users connecting to the site, URIs (Uniform Resource Identifier), request time, the way of using the request to send a request to the server, the size of the received response file, numeric code indicating server response status (successful outcome, error, etc.), country of origin, browser and operating system features used by the visitor, various time details per visit (e.g. time spent on each page), and details of the path to follow within the page with special reference on the order of pages visited and other parameters about e.g. operating system and user IT environment.</div>
                <h3 className="section__h3">Google Analytics: a web analytics service provided by Google, Inc. ( "Google").</h3>
                <div className="section__text">This service uses Cookies. Cookies help the website to analyse how visitors to our website use the website. The information about your use of the website (including your IP address) generated by the Cookie will be transferred to Google and Google will be considered the controller for this information. Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties, if required by law, or to processors. Google will not associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website. By accepting all cookies, you agree to the processing of your personal data by Google in the manner and for the above purposes.</div>
                <h3 className="section__h3">Managing cookie settings</h3>
                <div className="section__text">If you do not want to accept cookies, you can block them by adjusting the settings in the cookie window, but also in the Internet browser. If you change the setting in your browser, this customization may block cookies for any web page you visit. You can visit www.aboutcookies.org, which provides detailed information on managing cookies in popular browsers.</div>
                <div className="section__text">Blocking cookies may affect your ability to take advantage of the benefits provided by the use of cookies, may affect your ability to use certain customization features and, in the case of cookies necessary for the proper operation of the website, may affect the quality of your experience on this website.</div>
                <h3 className="section__h3">THIRD PARTY COOKIES</h3>
                <div className="section__text">Third party cookies may be installed on our website. Third-party cookies are cookies set by a domain other than the website the user is visiting. If a user visits a website and another entity sets a cookie through that website, it would be a third party cookie.</div>
                <h3 className="section__h3">EXAMPLES OF THIRD PARTY COOKIES WHICH MAY BE USED ON THIS PAGE</h3>
                <div className="section__text">
                    Google AdWords: collects usage and conversion statistics.
                    <br/>
                    Google Remarketing: allows advertising to users who have visited the site.
                    <br/>
                    Facebook: Sharing on social media.
                </div>
                <h3 className="section__h3">ADVERTISING COOKIES</h3>
                <div className="section__text">Advertising cookies are cookies that are used to display ads that are more relevant to the interests of users. They recognize that the user has already viewed this site. They are often associated with the functionality of the site. These cookies are used to collect data on browsing habits so that the ads displayed are relevant to you and your interests.</div>
                <div className="section__text">Please note that this does not affect the number of ads that will be displayed to you. This only affects their type and content, so they will be more relevant to your interests.</div>
                <h3 className="section__h3">TAILORED ADVERTISING</h3>
                <div className="section__text">
                    If you have cookies enabled on your computer, we may present you with ads relevant to our website. Google and third-party vendors display our ads on various websites. If you have enabled cookies, you may see ads for this website on other websites. If you'd like to opt out of custom advertising, visit the site for opting out network ads <a href="http://www.networkadvertising.org/choices/" target="_blank">networkadvertising.org</a>
                </div>
                <h2 className="section__title section__title--blue">Personal data collected for the purpose of issuing a Destination Card (IQM Pass):</h2>
                <div className="section__text">
                    <ul>
                        <li>name and surname of the guest</li>
                        <li>address of residence (stay)</li>
                        <li>e-mail address, telephone number</li>
                        {/* <li>bank account details and financial details of the transaction.</li> */}
                    </ul>
                </div>
                <div className="section__text">The purpose of the processing of the stated personal data is the necessity of the processing for the execution of the contract to which the respondent is a party. The name and surname of the guest and the address are entered in the Destination Card (IQM Pass), on the basis of which the guest is authorized to request the approval of a discount by individual manufacturers / service providers within the tourist destination. In addition to the request for approval of the discount and the possession of the Destination Card (IQM Pass), the guest is obliged to present a valid identification document to each manufacturer / service provider, and for such data processing the individual manufacturer / service provider is considered the controller. In order to execute the contract, it is necessary that the Controller can contact the guest and deliver the Destination Card (IQM Pass) in electronic form to him/her, for which the e-mail address and telephone number are collected.</div>
                {/* <div className="section__text">When the guest is paying the fee to the Controller, the Controller receives the bank account data and financial details of the transaction by which the payment order was executed. This data may also be available to processors, such as accounting services, financial service providers that enable payment via the web (e.g. PayPal), software support providers and other IT services, where the processing will be in line with the original purpose of the processing. carried out exclusively for the purpose of executing the contractual relationship between the guest and the Controller.</div> */}
                <h2 className="section__title section__title--blue">Information collected when receiving your inquiries:</h2>
                <div className="section__text">
                    <ul>
                        <li>name and surname, email address and other personal data which the guest or visitor to the website visitor enters in the message</li>
                    </ul>
                </div>
                <div className="section__text">The purpose of collecting the above personal data is to enable guests, potential guests and other visitors to the website to get in touch with the Controller and make inquiries related to his business. By voluntarily entering your personal data and sending a message, you express your consent to the processing of personal data in accordance with the provisions of this item and give your clear consent to the Controller. You have the right to withdraw your consent to the processing of personal data at any time.</div>
                <div className="section__text">By withdrawing or revoking consent to the processing of personal data in order to respond to inquiries, your personal data will no longer be used for this purpose, however withdrawing or revoking it does not affect the lawfulness of the processing based on consent prior to its withdrawal. The same principle applies to other forms of personal data processing based on the consent of the Controller.</div>
                <div className="section__text">If during or after resolving your request you enter into a contractual or pre-contractual relationship with the Controller, your data will be further processed as necessary for the execution of the contract or taking action before the contract at the request of the respondent.</div>
                <h2 className="section__title section__title--blue">Recipients / categories of recipients of personal data:</h2>
                <div className="section__text">Individual personal data of the guest may be disclosed to public authorities on the basis of their legal powers and / or during supervision, as well as to processors, contractual partners of the Controller under the same conditions and protective measures of processing. Processors are, for example, accounting services, IT service providers and the like. Personal data may also be provided to independent data controllers, such as lawyers, where the processing of personal data will be in line with the original purpose of the collection.</div>
                <h2 className="section__title section__title--blue">Method of personal data processing:</h2>
                <div className="section__text">Data are processed with the application of all prescribed and appropriate legal, organizational and technical measures for the protection of personal data. Access is provided only to authorized persons, with the application of information security measures against unwanted access and protection against data loss.</div>
                <div className="section__text">The provision of personal data is a condition necessary for the execution of the contract, i.e. the issuance of a Destination Card. If the guest does not provide the necessary personal data, the Controller will not be able to fulfill his/her legal and / or contractual obligations and ensure that the guest exercises his rights.</div>
                <h2 className="section__title section__title--blue">Period of storage of personal data collected and processed:</h2>
                <div className="section__text">Personal data collected during the issuance of the Destination Card is stored for the period of five years from the expiration of the Destination Card (IQM Pass), i.e. until the expiration of the limitation period on claims related to the issuance and use of the Destination Card (IQM Pass).</div>
                {/* <div className="section__text">Personal data collected and processed pursuant to the Accounting Act are kept and stored for a period of 11 years. </div> */}
                <div className="section__text">Data collected for communication purposes shall be kept and stored for at least three years from the end of the year in which they were collected. </div>
                <div className="section__text">If there are no special actual and legal reasons for keeping personal data for a longer period than expected, the data shall be deleted in the year following the expiration of the minimum retention period.</div>
                <h2 className="section__title section__title--blue">Respondents' rights regarding the collection and processing of personal data:</h2>
                <div className="section__text">You are guaranteed the following rights at all times:</div>
                <div className="section__text"><strong>Right to access personal data</strong> - It is your right to obtain information from the Controller on your personal data, information on the purpose of processing, categories of data being processed, recipients, storage time, and the right to request correction, deletion, processing restriction or objection to processing.</div>
                <div className="section__text"><strong>Right to rectification</strong> - you have the right to request the correction of incorrect data or to supplement incomplete data relating to you.</div>
                <div className="section__text"><strong>Right to erasure</strong> - you have the right to obtain from the Controller the erasure of personal data relating to you under certain conditions, unless their storage is prescribed by law. </div>
                <div className="section__text"><strong>Right to restriction of processing</strong>  - you have the right to obtain from the Controller a restriction on the processing that applies to you under certain conditions.</div>
                <div className="section__text"><strong>Right to object to processing</strong> - you have the right to object to the processing of your personal data.</div>
                <div className="section__text"><strong>Right to data portability</strong> - you have the right to transfer your personal data to another Controller, and if technically feasible, the transfer will be done automatically.</div>
                <div className="section__text"><strong>Right to lodge a complaint with a supervisory authority:</strong> Croatian Personal Data Protection Agency, Selska cesta 136, HR - 10 000 Zagreb, Tel. 00385 (0)1 4609-000, Fax. 00385 (0)1 4609-099, E-mail: <a href="mailto:azop@azop.hr">azop@azop.hr</a>, web: <a href="https://azop.hr/" target="_blank">www.azop.hr</a>.</div>
                <div className="section__text">You can exercise all of these rights at any time in writing to the address of the Controller whose contact information is provided in this notice.</div>
            </div>
        </div>
    )
}

function HeroPrivacy({match}) {
    const {lang} = match.params
    return (
        <div className="section hero--small" id="hero">
            <div className="heroSlider__wrap">
                <div className="heroSlider__mask" />
                <div className="heroSlider">
                    <div className="heroSlider__item">
                        <img src="/img/hero/hero-img4.jpg" alt="" />
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="hero">
                    <h1 className="hero__title">{lang === 'hr' ? "Privatnost": "Privacy"}</h1>
                </div>
            </div>
            <div className="spacer spacer--grayTop" />
        </div>
    )
}