import React, { Fragment, useRef, useEffect, useState} from 'react'

import {getQueryStringParams} from '../helpers'

import SeoHead from '../includes/SeoHead'
import Footer from '../includes/Footer'
import Navbar from '../includes/Navbar'
import Loader from '../includes/Loader'

import Network from '../network';
const { getOrder} = Network

export default (props) => {
    console.log(props)
    const {destinationID, match, history} = props
    const { lang } = match.params
    const {search} = history.location

    console.log(getQueryStringParams(search))

    const {CustomerFirstname, CustomerSurname, CustomerEmail, CreditCardNumber, Amount} = getQueryStringParams(search)
    const {ShoppingCartID: orderID} = getQueryStringParams(search)
    
    const [pass, setPass ] = useState({})


    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    useEffect(()=>{
        getOrder(destinationID,orderID).then(order =>{
            setPass(order.pass)
            setFetching(false)
        }).catch(e => console.log(e))
    },[])
    
    const [fetching, setFetching] = useState(true)
    if (fetching) return <Loader />

    return (<Fragment>
        <SeoHead match={match} history={history} pageType="PaymentSuccessPage"/>
        <Navbar lang={lang} {...props} />
        {
            lang === 'hr'?
            <SuccessHR lang={lang}  orderID = {orderID} customer = {{CustomerFirstname, CustomerSurname, CustomerEmail, CreditCardNumber, Amount}} pass={pass}/>:
            <SuccessEN lang={lang}  orderID = {orderID} customer = {{CustomerFirstname, CustomerSurname, CustomerEmail, CreditCardNumber, Amount}} pass={pass}/>

        }
        <Footer {...props}/>
    </Fragment>)
    
}



function SuccessHR({lang, orderID, customer, pass}) {
    const priceHRK = pass.price.find(p => p.currency === 'HRK')
    const priceEUR = pass.price.find(p => p.currency === 'EUR')

    const amount = lang === 'en' ? `${priceHRK.value} ${priceHRK.currency} ~ (${priceEUR.value} ${priceEUR.currency})`: `${priceEUR.value} ${priceEUR.currency}`

    return (
        <div className="section" id="success">
            <div className="container">
                <h1 className="section__title section__title--blue">Hvala Vam na kupnji IQM Pass</h1>
                <div className="success__icon">
                    <svg className="feather feather-check-circle" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                        <path d="M22 4L12 14.01l-3-3" />
                    </svg>
                </div>
                <div className="section__subtitle">Dragi/a <i> {`${customer.CustomerFirstname} ${customer.CustomerSurname}`}</i> uspješno ste kupili IQM Pass!</div>
                <div className="section__text">Zahvaljujemo Vam na kupnji IQM Pass-a. Molimo pokažite IQM Pass prije svake kupnje u objektu da Vam djelatnici pravovremeno mogu odobriti navedeni popust.</div>
                <br/>
                <div className="section__text"><b>Pass type:</b> {pass.passType} </div>
                <div className="section__text"><b>Pass duration:</b> {pass.duration > 24 ? `${pass.duration / 24} days` : `${pass.duration}h`} </div>
                
                <br/>
                <div className="section__text"><b>Vaša narudžba je stavljena pod referencu:<br/></b> {orderID}</div>

                <div className="section__text"><b>Naplačen Vam je iznos od </b>{`${customer.Amount} HRK`} <br/>{customer.CreditCardNumber.replace(/-/g,'*')}</div>
                <div className="section__text">Vaš Pass će ubrzo bit poslan na email <b>{customer.CustomerEmail}</b>
                    <br />Molimo <a href="mailto:support@iqmpass.com">kontaktirajte nas</a> ukoliko ga ne zaprimite u sljedećih 5 minuta.</div>
                    <div className="section__text">Za online rješavanje problema posjetite <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=EN">ec.europa.eu </a></div>
                <div className="success__btn"><a className="btn btn--yellow btn--fill2" href="/">Nazad na početnu stranicu</a>
                {/* TODO ADD LINk */}
                </div>
            </div>
        </div>
    )
}

function SuccessEN({lang, orderID, customer, pass}) {
    const priceHRK = pass.price.find(p => p.currency === 'HRK')
    const priceEUR = pass.price.find(p => p.currency === 'EUR')

    const amount = lang === 'en' ? `${priceHRK.value} ${priceHRK.currency} ~ (${priceEUR.value} ${priceEUR.currency})`: `${priceEUR.value} ${priceEUR.currency}`

    return (
        <div className="section" id="success">
            <div className="container">
                <h1 className="section__title section__title--blue">Thanks for buying IQM Pass</h1>
                <div className="success__icon">
                    <svg className="feather feather-check-circle" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                        <path d="M22 4L12 14.01l-3-3" />
                    </svg>
                </div>
                <div className="section__subtitle">Dear <i> {`${customer.CustomerFirstname} ${customer.CustomerSurname}`}</i> you've successfully bought your IQM Pass!</div>
                <div className="section__text">Thank you for purchasing your IQM Pass. Please show your IQM Pass before each purchase in the facility, so that the employees can grant you the specified discount in a timely manner.</div>
                <div className="section__text"><b>Pass type:</b> {pass.passType} </div>
                <div className="section__text"><b>Pass duration:</b> {pass.duration > 24 ? `${pass.duration / 24} days` : `${pass.duration}h`} </div>
                
                <br/>
                <div className="section__text"><b>Your order have been placed under reference:<br/></b> {orderID}</div>

                <div className="section__text"><b>Your have are charged in the amount of </b>{`${customer.Amount} HRK`} <br/>{customer.CreditCardNumber.replace(/-/g,'*')}</div>
                <div className="section__text">Your Pass will be emailed <b>{customer.CustomerEmail}</b> to you shortly
                    <br />Please <a href="mailto:support@iqmpass.com">contact us</a> if you do not receive it in next 5 minutes.</div>
                    <div className="section__text">For online dispute resolution visit <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=EN">ec.europa.eu </a></div>
                <div className="success__btn"><a className="btn btn--yellow btn--fill2" href="/">Back to homepage</a>
                {/* TODO ADD LINk */}
                </div>
            </div>
        </div>
    )
}