import React, { Fragment, useRef, useEffect, useState} from 'react'

import SeoHead from '../includes/SeoHead'
import FooterRoot from '../includes/FooterRoot'
import NavbarSubRoot from '../includes/NavbarSubRoot'
import DataLayerHead  from '../includes/DataLayerHead'

export default (props) => {
    
    const { match, history, defaultLanguage } = props
    const { lang } = match.params

    // console.log("TERMS", lang)

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    
    const webTitle =  `IQM Pass - ${lang === 'en'? "Terms and Services": "Uvjeti i usluge" } - IQMpass.com`;

    /* Todo: Replace me */
    return (<Fragment>
        <DataLayerHead webTitle={webTitle} />
        <SeoHead match={match} history={history} pageType="TermsAndServicesPage" />
        <NavbarSubRoot defaultLanguage={defaultLanguage} lang={lang} {...props} />
        <HeroTerms match = {match}/>
        { lang === 'hr'?
            <TermsHR />:
            <TermsEN />
        }
        <FooterRoot defaultLanguage={defaultLanguage} {...props}/>
    </Fragment>)
    
}


function TermsHR() {
    return (
        <div className="section" id="terms">
            <div className="container">
                <h2 className="section__title section__title--blue">Opći uvjeti poslovanja i korištenja Destinacijske Kartice vjernosti IQM PASS</h2>
                <div className="section__text">Feel IQM d.o.o., Gravot 7/B, Mali Lošinj, OIB: 45732227989 (u daljnjem tekstu označen kao: IQM) izdaje Destinacijsku Karticu, odnosno legitimacijski papir temeljem kojeg ste ovlašteni ostvariti popuste kod pojedinih pružatelja turističkih usluga (u daljnjem tekstu: Partneri) unutar Destinacije.</div>
                <h3 className="section__h3">Polje primjene</h3>
                <div className="section__text">Ovi Opći uvjeti poslovanja (OUP) se primjenjuju na sve Destinacijske Kartice izdane primateljima turističkih usluga (u daljnjem tekstu: Gosti) putem svih komunikacijskih kanala ili neposredno. Zahtjevi pojedinog Gosta koji bi bili izričito protivni OUP se neće uzeti u obzir. Bilo koje odredbe izvan OUP primjenjive na odnos između IQM i Gosta moraju biti izričito prihvaćene od strane IQM u pisanom obliku.</div>
                <h3 className="section__h3">Kontaktni podaci izdavatelja Destinacijske Kartice</h3>
                <div className="section__text">Ugovor o korištenju Destinacijske Kartice Gost sklapa s IQM-om koji stoji na raspolaganju radi pojašnjenja svih pitanja i detalja vezanih uz ugovor te se istome možete obratiti putem:</div>
                <div className="section__text">
                    <ul>
                        <li>Broj telefona: +385 99 216 3105</li>
                        <li>e-mail adresa: <a href="mailto:info@iqmpass.com">info@iqmpass.com</a></li>
                    </ul>
                </div>
                <h3 className="section__h3">Korištenje Destinacijske Kartice</h3>
                <div className="section__text">Popusti koje nude pojedini Partneri se mogu ostvariti isključivo davanjem Partneru na uvid važeće Destinacijske Kartice, koje se Gostu dostavlja u digitalnom obliku ili putem aplikacije, i to prije korištenja zatražene usluge.</div>
                <div className="section__text">Partneri su obvezani pružiti svoje usluge u skladu s vlastitim uvjetima poslovanja, što se osobito odnosi na radno vrijeme i opće uvjete nužne za korištenje usluga (primjerice vremenske uvjete ukoliko isti utječu na konkretnu uslugu). Partneri pridržavaju pravo mijenjati ponudu odnosno asortiman usluga i radno vrijeme.</div>
                <div className="section__text">Popusti koje pojedini Partner nudi na dio/cijeli asortiman vlastitih usluga su oglašeni na ovoj web stranici te njihov iznos ovisi o konkretnom Partneru, pri čemu je Partnerima zabranjeno umanjiti oglašene popuste ili iz bilo kojeg drugog razloga izdati popust koji bi bio manji od oglašavanog.</div>
                <div className="section__text">Gost nije ovlašten vršiti izmjene ili duplicirati izdane Destinacijske Kartice. Ukoliko bi duplicirana ili izmijenjena Destinacijska Kartica bila korištena od treće strane, Gost će biti odgovoran za svaku štetu koja bi nastala IQM ili Partneru korištenjem takve Destinacijske Kartice te je IQM ovlašten povući izdanu Destinacijsku Karticu.</div>
                
                <h3 className="section__h3">Katalog usluga i izdavanje Destinacijske Kartice</h3>
                <div className="section__text">Predstavljanje usluga Partnera u katalogu na ovoj web stranici nije pravno obvezujuća ponuda za sklapanje ugovora, već se radi o indikativnom prikazu usluga koje Gost može primiti od pojedinog Partnera.</div>
                <div className="section__text">Destinacijska Kartica se izdaje u elektroničkom obliku i upućuje na adresu elektroničke pošte Gosta koju sam navede ili putem aplikacije, odnosno drugim digitalnim putem navedenim na web stranici. Gost je obvezan unesti ispravnu adresu elektroničke pošte na koju mu IQM upućuje Destinacijsku Karticu te uslijed kršenja takve obveze Gosta, IQM ne odgovara za posljedičnu štetu koja bi mogla nastati Gostu.</div>
                <div className="section__text">Također, Gost može otisnuti Destinacijsku Karticu na papiru A4 formata ili se koristiti drugim medijem sa kojeg je čitljiv kod Destinacijske Kartice, kao što je ekran pametnog telefona. Destinacijska Kartica nije prenosiva te istu može koristiti samo osoba koja je na njoj navedena kao korisnik, uz dokaz o identitetu, odnosno valjani osobni dokument s fotografijom.</div>
                <h3 className="section__h3">Naknada za izdavanje Destinacijske Kartice</h3>
                <div className="section__text">Za korištenje i održavanje programa Destinacijske Kartice, Gost ne plaća naknadu, već prodajno mjesto prilikom rezervacije izdaje gratis IQM PASS.</div>
                {/* <div className="section__text">IQM ne prihvaća načine plaćanja izuzev onih navedenih na ovoj web stranici te ukoliko Gost uputi IQM-u naknadu koristeći nenavedeni način plaćanja, IQM nije odgovoran za eventualnu štetu koja bi nastala Gostu.</div> */}
                <h3 className="section__h3">Odgovornost za štetu</h3>
                <div className="section__text">IQM ne preuzima odgovornost za imovinsku/neimovinsku štetu koja bi Gostu mogla nastati prilikom sklapanja ugovora i izvršenja s pojedinim Partnerom ili propuštanjem poduzimanja obećane radnje. Sva potraživanja koja bi Gost imao temeljem korištenja usluga Partnera je ovlašten potraživati isključivo od pojedinog Partnera, sve sukladno članku 341. stavku 2. Zakona o obveznim odnosima. IQM nema nikakvu odgovornost uslijed nedostataka proizvoda i/ili usluga prodanih Gostu od strane Partnera.</div>
                <h3 className="section__h3">Nadležnost i primjenjivo pravo</h3>
                <div className="section__text">IQM pruža vlastite usluge na teritoriju Republike Hrvatske te ne usmjerava vlastite aktivnosti u bilo koju drugu državu. Ukoliko bi ovi OUP ili drugi dijelovi ove web stranice bili prevedeni na strani jezik, takva radnja je poduzeta isključivo radi lakšeg razumijevanja programa Destinacijske Kartice osobama koje ne govore hrvatski jezik, međutim takve radnje ne ukazuju na usmjeravanje aktivnosti IQM-a u druge države.</div>
                <div className="section__text">Slijedom navedenoga, za sve sporove koji bi proizašli iz odnosa IQM-a i Gosta je nadležan hrvatski sud, odnosno stvarno nadležni sud u Rijeci, uz primjenu hrvatskog prava.</div>
                <h3 className="section__h3">Promjene OUP</h3>
                <div className="section__text">IQM je ovlašten vršiti izmjene ovim OUP u svakom trenutku. Na odnos s pojedinim Gostom će se primjenjivati OUP koji je bio objavljen na web stranici u trenutku nastanka odnosa.</div>
                <h3 className="section__h3">Zahtjevi Partnera za korištenje usluga</h3>
                <div className="section__text">Partneri su ovlašteni uskratiti korištenje usluga Gostu u cijelosti ili djelomično, privremeno ili trajno ukoliko Gosti ne udovoljavaju nužnim zahtjevima za pružanje usluge (primjerice zdravstveno stanje ili dob, zahtjevi koji se odnose na odjevenost ili korištenje opreme) ili ako se očekuje da bi korištenje usluge moglo ugroziti Gosta. Ista odredba se primjenjuje i ukoliko se Gost, prilikom korištenja usluge, ne pridržava sigurnosnih ili pravnih obveza, uputa za korištenje ili ako na bilo koji drugi način krši ugovor u mjeri da je isključenje Gosta objektivno opravdano. Gost nije ovlašten potraživati ikakvu naknadu ukoliko su mu opravdano uskraćene usluge u sukladnosti s prethodnim odredbama.</div>

                {/* <h3 className="section__h3">Izjava o korištenju WSPaya</h3>
                <div className="section__text">Feel IQM d.o.o. koristi WSPay za online plaćanja. WSPay je siguran sustav za online plaćanje, plaćanje u realnom vremenu, kreditnim i debitnim karticama te drugim načinima plaćanja. WSPay kupcu i trgovcu osiguravaju siguran upis i prijenos upisanih podataka o karticama što podvrđuje i PCI DSS certifikat koji WSPay ima. WSPay koristi SSL certifikat 256 bitne enkripcije te TLS 1.2 kriptografski protokol kao najviše stupnjeve zaštite kod upisa i prijenosa podataka.</div>
                <div className="section__text"> Za online rješavanje sporova posjetite <a href=" https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=HR">https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=HR</a>  </div> */}

                {/* <h3 className="section__h3">Izjava o konverziji</h3>
                <div className="section__text">Sva plaćanja bit će izvršena u hrvatskoj valuti. Iznos koji će se teretiti s vašeg računa na kreditnoj kartici dobiva se konverzijom cijene iz eura u hrvatsku kunu prema trenutnom tečaju Hrvatske narodne banke. Prilikom naplate vaše kreditne kartice isti se iznos pretvara u vašu lokalnu valutu prema tečaju udruga kreditnih kartica. Kao rezultat ove pretvorbe postoji mogućnost neznatne razlike u odnosu na izvornu cijenu navedenu na našoj web stranici.</div> */}

            </div>
        </div>
    )
}

function TermsEN() {
    return (
        <div className="section" id="terms">
            <div className="container">
                <h2 className="section__title section__title--blue">General Terms and Conditions of Use for the loyalty Destination Card IQM PASS</h2>
                <div className="section__text">Feel IQM d.o.o., Gravot 7/B, Mali Lošinj, OIB (PIN): 45732227989 (hereinafter referred to as: IQM) issues a Destination Card, i.e. an identification card based on which you are authorized to get discounts with different tourism service providers (hereinafter referred to as: the Partners) in your chosen destination.</div>
                <h3 className="section__h3">Field of application</h3>
                <div className="section__text">These General Terms and Conditions (GTC) apply to all Destination Cards issued to recipients of tourism services (hereinafter referred to as: the Guests), through all communication channels or directly. Requests by individual Guests explicitly contrary to the GTC will not be considered. Any provisions outside the GTC applicable to the relationship between IQM and the Guest shall be expressly accepted by IQM in writing.</div>
                <h3 className="section__h3">Contact details of the Destination Card issuer</h3>
                <div className="section__text">The Agreement on the Use of the Destination Card shall be concluded between the Guest and IQM, who you can contact if you have any questions related to the Agreement, namely by phone or email:</div>
                <div className="section__text">
                    <ul>
                        <li>Phone number: +385 99 216 3105</li>
                        <li>email address: <a href="mailto:info@iqmpass.com">info@iqmpass.com</a></li>
                    </ul>
                </div>
                <h3 className="section__h3">Using the Destination Card</h3>
                <div className="section__text">The discounts offered by our Partners can be used only by presenting the Partner with a valid Destination Card, which is sent to the Guest in digital form or through our application, namely before the use of the requested service.</div>
                <div className="section__text">The Partners are required to provide their services in accordance with their own terms and conditions. In particular, that refers to their working hours and to the general terms and conditions necessary for the use of their services (for instance, weather conditions if they affect the provision of a particular service). The Partners reserve the right to change their offer, i.e. their range of services, as well as their working hours. </div>
                <div className="section__text">The discounts offered by each Partner for a part / whole range of their services are advertised on this website, and their amount depends on the specific Partner, whereby the Partners are prohibited from reducing the advertised discounts or giving a discount lower than the advertised one for any reason.</div>
                <div className="section__text">Our guests can get discounts with our Partners only whenever they order services and products directly from a Partner. It is not possible to get a discount if a service and/or product is ordered/reserved/paid/purchased through a third party or an intermediary.</div>
                <div className="section__text">The Guest is not authorized to make changes to or duplicate the issued Destination Card. Should a duplicated or modified Destination Card be used by a third party, the Guest will be liable for any damages incurred by IQM or the Partner by the use of such a Destination Card, and IQM will be authorized to withdraw the issued Destination Card.</div>

                <h3 className="section__h3">Catalog of services and issuance of the Destination Card</h3>
                <div className="section__text">The presentation of the Partners' services in the catalog on this website does not constitute a legally binding offer for the conclusion of an agreement, but it is an indicative illustration of the services that the Guest can receive from our Partners.</div>
                <div className="section__text">The Destination Card is issued in electronic form and sent to the email address provided by the Guest, via our application, or by some other digital means specified on the website. The Guest is required to enter the correct email address to which IQM shall send the Destination Card, and in case of a violation of said obligation by the Guest, IQM shall not be liable for any consequential damage that may be incurred by the Guest.</div>
                <div className="section__text">Also, the Guest may print the Destination Card on an A4 paper or use some other media from which the Destination Card code can be read, such as a smart phone screen. The Destination Card is not transferable and can only be used by the person specified on it as the user. Proof of identity, i.e. a valid personal document with a photo should be presented as well.</div>

                <h3 className="section__h3">Destination Card issuance fee</h3>
                <div className="section__text">For the use and maintenance of the Destination Card program, the Guest does not pay a fee, but the point of sale issues a free IQM PASS when booking.</div>
                {/* <div className="section__text">IQM does not accept any payment methods other than those specified on this website. Should a Guest send IQM the fee using an unspecified payment method, IQM shall not be responsible for any damage that may be incurred by the Guest.</div> */}

                <h3 className="section__h3">Liability for damage</h3>
                <div className="section__text">IQM shall not be liable for any material/non-material damage that may be incurred by the Guest upon the conclusion of the agreement and the provision of services by a particular Partner, or due to the failure to provide the promised services. All claims the Guest may have based on the use of a Partner's services may be claimed by the Guest exclusively from that Partner, all in accordance with Article 341, Paragraph 2 of the Civil Obligations Act. IQM shall not be liable for any deficiencies related to products and/or services sold to the Guest by the Partners.</div>
                
                <h3 className="section__h3">Jurisdiction and applicable law</h3>
                <div className="section__text">IQM provides its services on the territory of the Republic of Croatia, and does not direct its activities to any other country. Should these GTC or any other parts of this website be translated into a foreign language, such action will be taken solely in order to facilitate the understanding of the Destination Card program by non-Croatian speakers. However, that does not imply that IQM's activities are directed to other countries.</div>
                <div className="section__text">Consequently, all disputes arising from the relationship between IQM and the Guest shall be subject to the jurisdiction of the competent Croatian court, i.e. the Court in Rijeka having jurisdiction as to the substance of the matter, with the application of the Croatian law.</div>
                
                <h3 className="section__h3">GTC changes</h3>
                <div className="section__text">IQM is authorized to make changes to these GTC at any time. The GTC available on the website at the time of entering into an agreement shall apply to the relationship between IQM and the Guest.</div>
                
                <h3 className="section__h3">Partner requests pertaining to the use services</h3>
                <div className="section__text">The Partners are authorized to deny the use of their services to a Guest in whole or in part, as well as temporarily or permanently, if a Guest does not meet the necessary requirements for the provision of the service in question (for instance, due to their state of health or age, dress standards or equipment requirements), or if the use of the service could put the Guest in jeopardy. Said provision shall also apply if the Guest, when using the service, does not comply with the safety or legal obligations or instructions for use, or if they in any other way violate the agreement to the extent which would make the exclusion of the Guest objectively justified. The guest is not authorized to claim any compensation if the services are justifiably denied to them in accordance with the previous provisions.</div>

                {/* <h3 className="section__h3">WSPay Usage Statement</h3>
                <div className="section__text">Feel IQM d.o.o. uses WSPay for online payments. WSPay is a secure system for online payments, real time credit and debit card payments, and other payment methods. WSPay ensures the buyer and the merchant with the secure card data entry and transfer, which is also confirmed by PCI DSS certificate. WSPay uses 256-bit SSL encryption and TLS 1.2 cryptographic protocol as the highest protection standards for data entry and transfer.</div>
                <div className="section__text"> For online dispute resolution please visit <a href=" https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=EN">https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=EN</a></div> */}

                {/* <h3 className="section__h3">Conversion policy</h3>
                <div className="section__text">All payments will be effected in Croatian currency. The amount your credit card account will be charged for is obtained through the conversion of the price in Euro into Croatian kuna according to the current exchange rate of the Croatian National bank. When charging your credit card, the same amount is converted into your local currency according to the exchange rate of credit card associations. As a result of this conversion there is a possibility of a slight difference from the original price stated in our web site.</div> */}
                
            </div>
        </div>
    )
}

function HeroTerms({match}) {
    const {lang} = match.params
    return (
        <div className="section hero--small" id="hero">
            <div className="heroSlider__wrap">
                <div className="heroSlider__mask" />
                <div className="heroSlider">
                    <div className="heroSlider__item">
                        <img src="/img/hero/hero-img4.jpg" alt="" />
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="hero">
                    <h1 className="hero__title">{lang === 'hr' ? "Uvjeti i usluge" : "Terms and Services"}</h1>
                </div>
            </div>

            <div className="spacer spacer--grayTop" />
        </div>
    )
}


/*
All payments will be effected in Croatian currency. The amount your credit card account will be charged for is obtained through the conversion of the price in Euro into Croatian kuna according to the current exchange rate of the Croatian National bank. When charging your credit card, the same amount is converted into your local currency according to the exchange rate of credit card associations. As a result of this conversion there is a possibility of a slight difference from the original price stated in our web site.
*/