import React, { Fragment, useRef, useEffect, useState} from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";


import BillingPage from './pages/BillingPage'
import FreeCardPage from './pages/FreeCardPage'
import DestinationHomePage from './pages/DestinationHomePage'
import DealsPage from './pages/DealsPage'
import DealPage from './pages/DealPage'
import WidgetPage from './pages/WidgetPage'

import PaymentSuccessPage from './pages/PaymentSuccessPage'
import PaymentErrorPage from './pages/PaymentErrorPage'
import NotFound404Page from './pages/NotFound404Page'
import ItineraryPage from './pages/ItineraryPage'
import TripPlannerPage from './pages/TripPlannerPage'

import Loader from './includes/Loader'





export default (props) => {
    const {match, destinationList} = props;
    const {destinationWebUrl} = match.params

    const destination = destinationList.find((destination) => {
        return destination.webUrl === destinationWebUrl
    })       
    const destinationID = destination? destination._id: null     

    if (!destinationID) return <NotFound404Page {...props} />

    return(
        <Fragment>
        <Switch>
            <Route exact path={`${match.path}`} render = {(props)=> <DestinationHomePage destinationID = {destinationID} destination = {destination} destinationList = {destinationList} {...props}/> }></Route>

            <Route path={`${match.path}/take-your-pass/:orderID`} render = {(props)=> <FreeCardPage destinationID = {destinationID} destination = {destination} destinationList = {destinationList} {...props} /> }></Route>
            <Route exact path={`${match.path}/take-your-pass`} render = {(props)=> <BillingPage destinationID = {destinationID} destination = {destination} destinationList = {destinationList} {...props} /> }></Route>
    
            <Route exact path={`${match.path}/deals`} render = {(props)=> <DealsPage destinationID = {destinationID} destination = {destination} destinationList = {destinationList}  {...props} /> }></Route>
           
            <Route exact path={`${match.path}/deal/:dealID`} render = {(props)=> <DealPage destinationID = {destinationID} destination = {destination} destinationList = {destinationList}  {...props} /> }></Route>
           
            <Route exact path={`${match.path}/widget/:widgetURL`} render = {(props)=> <WidgetPage destinationID = {destinationID} destination = {destination} destinationList = {destinationList}  {...props} /> }></Route>

            <Route exact path={`${match.path}/itinerary/:itineraryID`} render = {(props)=> <ItineraryPage destinationID = {destinationID} destination = {destination} destinationList = {destinationList}  {...props} /> }></Route>

            <Route exact path={`${match.path}/trip-planner/:tripPlannerSlug`} render = {(props)=> <TripPlannerPage destinationID = {destinationID} destination = {destination} destinationList = {destinationList}  {...props} /> }></Route>



            <Route exact path={`${match.path}/error`} render = {(props)=> <PaymentErrorPage destinationID = {destinationID} destination = {destination} destinationList = {destinationList}  {...props} name={"PaymentErrorPage"} /> }></Route>
            <Route exact path={`${match.path}/success`} render = {(props)=> <PaymentSuccessPage destinationID = {destinationID} destination = {destination} destinationList = {destinationList}  {...props} name={"PaymentSuccessPage"} /> }></Route>
            {/* Todo: <Route path={`${match.path}/success/:orderID`} render = {(props)=> <PaymentSuccessPage destinationID = {destinationID} destination = {destination} destinationList = {destinationList}  {...props} name={"PaymentSuccessPage"} /> }></Route> */}


            {/* <Route path={`${match.path}/privacy`} render = {(props)=> <PrivacyPage destinationID = {destinationID} destination = {destination} destinationList = {destinationList}  {...props} name={"PrivacyPage"} /> }></Route>
            <Route path={`${match.path}/terms`} render = {(props)=> <TermsAndServicesPage destinationID = {destinationID} destination = {destination} destinationList = {destinationList}  {...props} name={"TermsAndServicesPage"} /> }></Route> */}
            
            <Route render = {(props)=> <NotFound404Page {...props} /> }></Route>
        </Switch>
        </Fragment>) 
}

