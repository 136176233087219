import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";

import webconfig from '../webconfig'


import Network from '../network';
const { getWebConfig } = Network

const defaultLanguage = webconfig.defaultLanguage


export default ({ match, history, pageType, ...props }) => {
    const TAG = `SeoHead-${pageType}`;
    const { params, path, url } = match

    useEffect(() => {
        var canonicalElement = document.querySelector('link[rel="canonical"]');
        var titleElement = document.querySelector('title')
    })
    

    return null;
    const getTitle = (url, lang) => {
        return webConfig[url] ? webConfig[url].title : "IQMPass"
    }
    const getCanonical = (url) => {
        return webConfig[url] ? webConfig[url].canonical : generateCanonicals(match)
    }
    const getDescription = (url) => {
        return webConfig[url] ? webConfig[url].description : ""
    }

    var trueUrl = url
    if (pageType === "LandingPage") {
        if (path === "/") {
            trueUrl = "/"
        }
        if (path === "/:lang/") {
            trueUrl = params.lang === defaultLanguage ? "/" : `/${params.lang}/`
        }
    }


    if (pageType === "DestinationHomePage") {
        if (path === "/:lang/:destinationWebUrl") {
            trueUrl = url[url.length - 1] === "/" ? url.slice(0, url.length - 1) : url
        }
    }

    if (pageType === "DealsPage") {
        if (path === "/:lang/:destinationWebUrl/deals") {
            trueUrl = url[url.length - 1] === "/" ? url.slice(0, url.length - 1) : url
        }
    }




    const [webConfig, setWebConfig] = useState(null)

    const LoadWebConfig = async () => {
        const config = await getWebConfig()
        setWebConfig(config)
    }

    useEffect(() => {
        LoadWebConfig();
    }, [])

    useEffect(() => {
        var htmlElement = document.querySelector("[lang]")
        htmlElement.lang = params.lang || defaultLanguage

        if (!webConfig) return

        const canonical = getCanonical(trueUrl)
        var canonicalElement = document.querySelector('link[rel="canonical"]');
        var titleElement = document.querySelector('title')

        if (canonical) {
            if (canonicalElement !== null) {
                canonicalElement.href = canonical
                console.log(TAG, "Updating link[rel=canonical] href ...")
            } else {
                canonicalElement = document.createElement('link');
                canonicalElement.rel = "canonical"
                canonicalElement.href = canonical
                titleElement.parentNode.insertBefore(canonicalElement, titleElement.nextSibling);
                console.log(TAG, "Creating new link[rel=canonical] href ...")
            }
        } else {
            if (canonicalElement !== null) {
                canonicalElement.remove()
                console.log(TAG, "Removing link[rel=canonical] href ...")
            }

        }



        console.log(TAG, url, "Canonical", getCanonical(trueUrl))
        console.log(TAG, url, "Title", getTitle(trueUrl))
        console.log(TAG, url, "Description", getDescription(trueUrl))


    }, [webConfig, url])



    if (!webConfig) return null

    return (<Helmet>
        <title>{getTitle(trueUrl, params.lang)}</title>
        {/* {getDescription(trueUrl) ? <meta name="description" content={getDescription(trueUrl)} />:null} */}

    </Helmet>)



}


const generateCanonicals = (match) => {
    const DOMAIN_URL = "https://iqmpass.com"

    const { params, path, url } = match

    let canonicals = ''
    switch (path) {
        case '/':
            canonicals = 'https://iqmpass.com/en/privacy'
            break;
        case '/lang/':
            canonicals = 'https://iqmpass.com/en/privacy'
            break;
        default:
            canonicals = ''


    }
    return canonicals;
}

const generateTitle = (url) => {

}