import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, NavLink } from "react-router-dom";

import $ from "jquery";




export default (lang) => {


    return lang === 'hr' ? QuestionsSectionHR : QuestionsSectionEN;

}



const QuestionsSectionHR = () => {

    useEffect(() => {
        $(".questions__box").click(function () {
            $(this).siblings(".questions__more").toggle("slow")
            $(this).find(".questions__icon").toggleClass("questions__icon--active")
        })
    }, [])


    return (
        <div className="section" id="questions">
            <div className="container">
                <h2 className="section__title section__title--blue">Najčešća pitanja i odgovori </h2>
                <div className="section__text">Na nastavku pročitajte najčešća pitanja i odgovore:</div>
                <div className="questions">

                  
                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Kako točno funkcionira IQM Pass?</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                                <ul>
                                    <li>1. IQM Pass možete besplatno preuzeti na web stranici <a href="https://iqmpass.com/" target="_blank">www.iqmpass.com</a></li>
                                    <li> 2. IQM Pass vrijedi najduže dva tjedna. Posebno obratite pažnju na Datum početka, odnosno na odabir dana kada želite da Vam počne vrijediti IQM Pass. </li>
                                    <li> 3. Da biste iskoristili popuste i pogodnosti, potrebno je pri narudžbi usluge ili pri rezervaciji, pokazati IQM Pass, koji će Vam djelatnik skenirati i nakon toga odobriti popust pri plaćanju. </li>
                                    <li> 4. Navedeni popust Gost može ostvariti kod Partnera samo u slučaju kada usluge i proizvode naručuje direktno kod Partnera te isključuje mogućnost ostvarivanja popusta ukoliko je usluga i/ili proizvod naručen/rezerviran/plaćen/kupljen putem treće strane/posrednika. </li>
                                    <li> 5. Odabiranjem Preuzmi dobit ćete vaš besplatni IQM Pass na mail. Možete isprintati IQM Pass i pokazati pri ulasku u odabrani objekt ili imati IQM Pass na svom mobitelu te pokazati i skenirati pri narudžbi.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Promjena termina korištenja IQM Pass-a</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                                Ukoliko želite promjenu datuma korištenja IQM Pass, ponovo preuzmite IQM Pass te unesite novi željeni datum.
                            </div>
                        </div>
                    </div>


                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Primanje online IQM PASS</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                                Nakon preuzimanja IQM Pass-a, u roku nekoliko minuta dobit ćete svoj IQM Pass na mail. Ukoliko pri preuzimanju IQM Pass imate problema, pokušajte ponovo nakon nekog vremena ili nam se obratite na mail <a href="mailto:pass@iqmdestination.com">pass@iqmdestination.com</a>.
                            </div>
                        </div>
                    </div>


                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Je li potrebno preuzeti IQM Pass prije putovanja?</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                                Svakako, iz više razloga:
                            </div>
                            <div className="questions__text">
                                <ul>
                                    <li>1. Ostvarujete popust na smještaj te time povećavate isplativost IQM Pass-a. Preporučamo da pri rezervaciji, našem parneru, vlasniku smještaja napomenete da koristite pogodnosti IQM Pass-a.</li>
                                    <li>2. U destinaciji ostvarujete najviše popuste i imate sigurnost da imate mogućnost najkvalitetnijeg destinacijskog doživljaja.</li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Zašto niste primili mail s IQM Pass-om?</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                                Molimo pregledajte Junk mail ili Spam mailove. Ukoliko u niti jednom pretincu nemate mail, molimo da pokušate ponovo nakon nekog vremena ili nam se obratite na mail <a href="mailto:pass@iqmdestination.com">pass@iqmdestination.com</a>.
                            </div>
                        </div>
                    </div>


                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Mogu li stornirati IQM Pass?</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                                Ukoliko nije iskorišten, IQM Pass se automatski stornira.
                            </div>
                        </div>
                    </div>


                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Koje informacije su potrebne pri preuzimanju IQM Pass-a?</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                                Datum početka i trajanje korištenja IQM Pass, mail adresa, broj osoba, destinacija u koju želite putovati.
                            </div>
                        </div>
                    </div>


                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Gdje točno mogu koristiti IQM Pass?</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                                Na web stranici <a href="https://iqmpass.com/" target="_blank">www.iqmpass.com</a> postoji cijeli popis svih partnera u destinaciji koji su uključeni u IQM Pass. Ukoliko ranije preuzimate IQM Pass moguća su odstupanja, ali u pravilu ona će pružiti veći popust i više mogućnosti.
                            </div>
                        </div>
                    </div>

                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Jesu li popusti primjenjivi kroz cijelu godinu u svim objektima?</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                                Na web stranici pojedinog partnera upisano je radno vrijeme i period kada rade kroz godinu. Ukoliko nisu otvoreni, nije moguće ostvariti popust u navedenim objektima/za navedene usluge.
                            </div>
                        </div>
                    </div>


                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Gdje mogu pronaći radno vrijeme svakog partnera koji odobrava popust?</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                                Na web stranicama svakog partnera možete pronaći aktualno radno vrijeme.
                            </div>
                        </div>
                    </div>


                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Smiju li djeca posjećivati navedena mjesta u IQM Pass-u?</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                                Djeca su dobrodošla, uz obveznu pratnju odrasle osobe, izuzev partnera koji svoji objekt predstavljaju kao adult-only.
                            </div>
                        </div>
                    </div>

                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">
                                Je li ponuda IQM Pass prilagođena osobama s poteškoćama u kretanju?</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                                Pojedini objekti su prilagođeni osobama sa poteškoćama u kretanju. Svi detalji nalaze se na web stranici vlasnika objekta/usluge.
                            </div>
                        </div>
                    </div>


                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Mogu li preuzeti IQM Pass kada sam već u destinaciji?</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                                Svakako, u svakom trenutku možete preueti svoj IQM Pass.
                            </div>
                        </div>
                    </div>


                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Multipliciranje popusta</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                                Popusti se odnose na objavljenu cijenu i nije moguće multipliciranje popusta za isti proizvod/uslugu. Partner će navesti ukoliko se popust ne odnosi na pojedinu uslugu/proizvod.
                            </div>
                        </div>
                    </div>


                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Tko je vlasnik IQM Pass?</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                                U suradnji s javnim institucijama IQM Pass je official i u vlasništvu je Feel IQM d.o.o.
                            </div>
                        </div>
                    </div>

                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Dodatne informacije</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                                Ukoliko niste pronašli neki od naših odgovora, molimo, javite nam se na mail: <a href="mailto:pass@iqmdestination.com">pass@iqmdestination.com</a>, Službi za korisnike. Rado ćemo odgovoriti na svako vaše pitanje.
                            </div>
                        </div>
                    </div>
               
               
                </div>
            </div>
        </div>
    );
}


const QuestionsSectionEN = () => {

    useEffect(() => {
        $(".questions__box").click(function () {
            $(this).siblings(".questions__more").toggle("slow")
            $(this).find(".questions__icon").toggleClass("questions__icon--active")
        })
    }, [])


    return (
        <div className="section" id="questions">
            <div className="container">
                <h2 className="section__title section__title--blue">Frequent Q&A</h2>
                <div className="section__text">Below you can find frequently asked questions and answers:</div>
                <div className="questions">
                    
                    



                <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">How exactly does the IQM Pass work?</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                                <ul>
                                    <li>1. You can download your IQM Pass online, at <a href="https://iqmpass.com/" target="_blank">www.iqmpass.com</a></li>
                                    <li> 2. The IQM Pass is valid for a maximum of two weeks. Pay special attention to the Start date, that is, to the selection on the day you want the IQM Pass to start being valid for you.</li>
                                    <li> 3. In order to use the discounts listed in the IQM Pass terms and conditions, all you need to do is show it right away when ordering or making a reservation for a service. Your IQM Pass will be scanned for you, and you will subsequently be granted a discount when paying.</li>
                                    <li> 4. Our guests can get discounts with our partners only whenever they order services and products directly from a partner. It is not possible to get a discount if a service and/or product is ordered/reserved/paid/purchased through a third party or an intermediary.</li>
                                    <li> 5. After downloading the IQM Pass, you will receive your IQM Pass by email. You can print your IQM Pass and show it when entering the selected facility or when booking an accommodation unit, or you can have an IQM Pass on your cell phone, and show it and scan it when ordering.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Changing the timeframe for the use of the IQM Pass</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                            If you need to change the dates for the use of your IQM Pass, you can download the IQM Pass again and select new dates.
                            </div>
                        </div>
                    </div>


                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Receiving the IQM Pass online</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                            After downloading the IQM Pass, you should receive your IQM Pass by email in 1-2 minutes. If you encounter any problems while downloading your IQM Pass, you can try again after a few minutes or contact us at <a href="mailto:pass@iqmdestination.com">pass@iqmdestination.com</a>.
                            </div>
                        </div>
                    </div>


                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Do I need to download my IQM Pass before traveling?</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                            Certainly, and for several reasons:
                            </div>
                            <div className="questions__text">
                                <ul>
                                    <li>1. You will get a discount on your accommodation, thus increasing the cost-effectiveness of the IQM Pass. We recommend that when making a reservation, you mention to our partner, the accommodation owner, that you use the benefits of the IQM Pass.</li>
                                    <li>2. You will get the best discounts at your destination, and you will be provided with the opportunity to have the finest experiences there.</li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Why didn't I receive an email with my IQM Pass?</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                            Please check your junk mail/spam folder. If our email is not in any of your mailboxes, please try to download it again after a few minutes or contact us at email <a href="mailto:pass@iqmdestination.com">pass@iqmdestination.com</a>.
                            </div>
                        </div>
                    </div>


                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Can I cancel my IQM Pass?</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                            If it has not been used, the IQM Pass is automatically canceled.
                            </div>
                        </div>
                    </div>


                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">What information do I need to provide when downloading an IQM Pass?</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                            You should specify the start date and the exact duration of the use of your IQM Pass, an email address, as well as the number of persons, and the chosen destination you wish to travel to.
                            </div>
                        </div>
                    </div>


                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Where exactly can I use my IQM Pass?</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                            On the website <a href="https://iqmpass.com/" target="_blank">www.iqmpass.com</a>, there is a complete list of all our partners taking part in the IQM Pass program. If you buy your IQM Pass earlier, some inconsistencies are possible, but as a rule, there will be an even bigger discount and more options.
                            </div>
                        </div>
                    </div>

                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Are discounts available throughout the year in all facilities?</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                            On each partner's website, the working hours, and the period when they offer their services are specified. If they are not open, it is not possible to get a discount in their facilities / for the specified services.
                            </div>
                        </div>
                    </div>


                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Where can I find the working hours of each partner who grants a discount?</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">You can find the current working hours on our partners' websites.</div>
                        </div>
                    </div>


                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">
Are children allowed to visit the places specified in the IQM Pass?</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                            Children are welcome, but they have to be accompanied by an adult, except for partners who present their facility as adult-only.
                            </div>
                        </div>
                    </div>

                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">
                                
Is the IQM Pass offer tailored to people with mobility difficulties?</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                            Some facilities are adapted for people with mobility difficulties. All details can be found on the website of the owner of the facility/service provider.
                            </div>
                        </div>
                    </div>


                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Can I download my IQM Pass if I am already in my destination?</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                                
Of course, you can download your IQM Pass at any time.
                            </div>
                        </div>
                    </div>


                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Discount multiplication</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                            Use of discounts; discounts refer to the published prices, and it is not possible to multiply discounts for the same product/service. Partners will specify whenever a discount does not apply to a particular service/product.
                            </div>
                        </div>
                    </div>


                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Who owns the IQM Pass program?</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                            The IQM Pass program is owned by the company Feel IQM d.o.o., in cooperation with the public institutions.
                            </div>
                        </div>
                    </div>

                    <div className="questions__item">
                        <div className="questions__box">
                            <div className="questions__icon"><span>+</span>
                            </div>
                            <div className="questions__text">Additional informations</div>
                        </div>
                        <div className="questions__more">
                            <div className="questions__text">
                            You have not found the answer you were looking for? Please contact us by email at: <a href="mailto:pass@iqmdestination.com">pass@iqmdestination.com</a> (Customer Services). We will be happy to answer any questions you might have.
                            
                            </div>
                        </div>
                    </div>
               
               



                </div>
            </div>
        </div>
    );
}