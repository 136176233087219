import React, { Fragment, useEffect, useState } from 'react';
import { Switch, Route, Redirect, Link, NavLink } from "react-router-dom";


import SeoHead from '../includes/SeoHead'
import Loader from '../includes/Loader'
import Footer from '../includes/Footer'
import Navbar from '../includes/Navbar'
import NotFound404Page from './NotFound404Page'

import Network from '../network';
const { getWidgetByURL, getActiveDeals } = Network


export default (props) => {
    const { destination, destinationID, match, history } = props
    const { lang, widgetURL: url, destinationWebUrl } = match.params

    const [widget, setWidget] = useState({})
    const [widgetDeals, setWidgetDeals] = useState({})



    const LoadData = async () => {
        const widget = await getWidgetByURL(destinationID, url)
        const deals = await getActiveDeals(destinationID)
        setWidget(widget)
        setWidgetDeals(widget.deals.filter(dealID => deals.find(deal => dealID === deal._id))
            .map(dealID => deals.find(deal => dealID === deal._id)))

    }

    useEffect(() => {
        LoadData().then(() => {
            setFetching(false)
        }).catch(e => console.log(e))


    }, [])


    if (widget && widget.partnerID)
        localStorage.setItem('referalID', widget.partnerID);

    const [fetching, setFetching] = useState(true)
    if (fetching === true) return <Loader />
    else if (!widget || !widget._id) return <NotFound404Page {...props} />

    const rootUrl = `/${lang}/${destinationWebUrl}`
    const heroImg = destination.webData.hero_section.images[0]
    return (
        <Fragment>

            <SeoHead match={match} history={history} pageType="WidgetPage" />


            <Navbar lang={lang} {...props} />


            {/* HERO */}
            <div className="section hero--small" id="hero">
                <div className="heroSlider">
                    <div className="heroSlider__item">
                        <img src={`https://assets.iqmpass.com/${heroImg.path}`} alt="" />
                    </div>
                </div>
                <div className="container">
                    <div className="hero">
                        <h1 className="hero__title">{`IQM Pass - ${widget.name}`}</h1><Link className="hero__box" to={`${rootUrl}/billing`}>BUY PASS NOW</Link>
                    </div>
                </div>
                {/* <div className="spacer spacer--greenTop" /> */}
                <div className="spacer spacer--whiteTop" />
            </div>

            <Deals widgetDeals={widgetDeals} {...props} />


            <Footer {...props} />
        </Fragment>
    );
}




const Deals = ({ widgetDeals, match }) => {


    const DealItems = widgetDeals.map(deal => {
        return <DealsItem deal={deal} match={match} />
    })


    return (<div id="deals">
        <div className="container">
            <div className="deals">
                {DealItems}
            </div>
            <SavingCalculation match={match} deals={widgetDeals} />
        </div>
    </div>

    )

}

const SavingCalculation = ({ deals, match }) => {
    const { lang, destinationWebUrl } = match.params

    const url = `/${lang}/${destinationWebUrl}`

    let regularPrice = 0;
    let yourPrice = 0;
    let youSave = 0;

    deals.forEach(deal => {
        const dealData = deal.dealData.find(data => data.lang === lang)
        const price = deal.price.find(price => {
            if (price.userType !== 'any') return false
            if ((price.currency === 'HRK' && lang === 'hr') || (price.currency === 'EUR' && lang === 'en')) return price
            else return false
        })

        const discount = deal.discount.find(discount => {
            if (discount.userType !== 'any') return false
            if ((discount.currency === 'HRK' && lang === 'hr') || (discount.currency === 'EUR' && lang === 'en')) return discount
            else return false
        })

        const save = discount.discountType === "gift" ? price.value : (discount.discountType === "trade" ? price.value * ((100 - discount.value) / 100) : price.value - discount.value)

        regularPrice = regularPrice + price.value
        yourPrice = yourPrice + save

    })
    youSave = regularPrice - yourPrice

    return (<div className="saveBar">
        <div className="saveBar__item saveBar__item--yellow">
            <div className="saveBar__text">{lang === 'hr' ? "Možeš uštedjeti" : "You can save"}</div>
        </div>
        <div className="saveBar__item saveBar__item--white">
            <div className="saveBar__text saveBar__text--large saveBar__text--striked">{`${regularPrice.toFixed(2)} ${lang === 'hr' ? "HRK" : "EUR"}`}</div>
            <div className="saveBar__text saveBar__text--small">{lang === 'hr' ? 'Regularna cijena' : 'Regular Price'}</div>
        </div>
        <div className="saveBar__item saveBar__item--white">
            <div className="saveBar__text saveBar__text--large">{`${yourPrice.toFixed(2)} ${lang === 'hr' ? "HRK" : "EUR"}`}</div>
            <div className="saveBar__text saveBar__text--small">{lang === 'hr' ? 'Tvoja cijena' : ' Your price'}</div>
        </div>
        <div className="saveBar__item saveBar__item--blue">
            <div className="saveBar__text saveBar__text--large">{`${youSave.toFixed(2)} ${lang === 'hr' ? "HRK" : "EUR"}`}</div>
            <div className="saveBar__text saveBar__text--small">{lang === 'hr' ? 'Štediš' : 'You Save'}</div>
        </div>
        <div className="btn btn--green btn--fill btn--arrow">
            <Link className="btn__text" to={`${url}/billing`} >{lang === 'hr' ? 'Uštedi sada' : 'Save now'}</Link>
            <div className="btn__icon">
                <svg viewBox="0 0 15.698 8.706" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.354 0l-.708.706 3.14 3.147H0v1h13.786L10.646 8l.708.706 4.344-4.353z" />
                </svg>
            </div>
        </div>

    </div>


    )
}

const DealsItem = ({ deal, match }) => {

    const { lang, destinationWebUrl } = match.params

    const dealData = deal.dealData.find(data => data.lang === lang)
    const discount = deal.discount.find(discount => {
        if (discount.userType !== 'any') return false
        if ((discount.currency === 'HRK' && lang === 'hr') || (discount.currency === 'EUR' && lang === 'en')) return discount
        else return false
    })


    const curencyMap = {
        "HRK": "kn",
        "EUR": "€"
    }
    const discountType = discount.discountType === 'cash' ? `-${discount.value}${curencyMap[discount.currency]}` :
        (discount.discountType === 'trade' ? `-${discount.value}%` : "GIFT")

    console.log(discount, discountType)
    const url = `/${lang}/${destinationWebUrl}/deal/${deal._id}`
    return (
        <Link className="deals__item" to={`${url}`} name={dealData.title} title={dealData.title}>
            <div className="card card--large">
                <div className="card__img">
                    {deal.coverImage ? <img src={`https://assets.iqmpass.com/${deal.coverImage.path}`} alt="" /> : null}
                    {/* css je napravljen tako da se prilagodava sirini, tako da vrijednosti mogu bit koliko hoces samo textualno ubaci (npr. "-100€" "-100kn" "Gift" "-90%") */}
                    <div className="card__discount">{discountType}</div>
                </div>
                <div className="card__row card__row--white">
                    <h2 className="card__h1">{dealData.title}</h2>
                    <div className="card__text">{dealData.description}</div>
                </div>
            </div>
            <div className="deals__mask" />
        </Link>


    );
}