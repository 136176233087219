import React, { Fragment, useEffect, useState } from 'react';
import { Switch, Route, Redirect, Link, NavLink } from "react-router-dom";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import DOMPurify from 'dompurify'; // To prevent XSS attacks

import SeoHead from '../includes/SeoHead'
import Loader from '../includes/Loader'
import Footer from '../includes/Footer'
import Navbar from '../includes/Navbar'
import NotFound404Page from './NotFound404Page'
import Network from '../network';

import DataLayerHead from '../includes/DataLayerHead'

import GetPassWidget from '../includes/GetPassWidget';

import { categoryAboutProviderMapper, passTypeMapper, categorySlugMapper, trimmString } from '../helpers'
const { getItinerary, getPassList, getActiveDeals, getTripPlannerBySlug } = Network


export default (props) => {
    const { destination, destinationID, match, history } = props
    const { lang, destinationWebUrl, tripPlannerSlug } = match.params


    const [tripPlanner, setTripPlanner] = useState(null)
    const [dealList, setDealList] = useState([])

    const LoadData = async () => {
        const tripPlanner = await getTripPlannerBySlug(destinationID, tripPlannerSlug)
        const dealList = await getActiveDeals(destinationID)
        setTripPlanner(tripPlanner)
        setDealList(dealList)
    }




    useEffect(() => {
        LoadData().then(() => {
            setFetching(false)
        }).catch(e => console.log(e))
    }, [])




    const [fetching, setFetching] = useState(true)
    if (fetching === true) return <Loader />
    else if (!tripPlanner || !tripPlanner._id) return <NotFound404Page {...props} />



    const title = tripPlanner.metaData[lang].title;


    var heroImgPath = "";
    if (tripPlanner.coverImage) {
        heroImgPath = `${tripPlanner.coverImage.path.split(".")[0]}_1100.webp`
    } else if (destination.webData && destination.webData.hero_section && destination.webData.hero_section.images) {
        heroImgPath = destination.webData.hero_section.images[0].path;
    }


    const url = `/${lang}/${destinationWebUrl}`

    const tripPlannerLangData = tripPlanner.metaData[lang]
    const webTitle = `IQM Pass - ${tripPlannerLangData.title} - IQMpass.com`;

    return (
        <Fragment>
            <DataLayerHead webTitle={webTitle} />
            <SeoHead match={match} history={history} pageType="TripPlanerPage" />
            <Navbar lang={lang} {...props} />


            {/* HERO */}
            <div className="section" id="hero">
                <div className="heroSlider">
                    <div className="heroSlider__item">
                        <img src={`https://assets.iqmpass.com/${heroImgPath}`} alt="" />
                    </div>
                </div>
                <div className="container">
                    <div className="hero">
                        <h1 className="hero__title">{`${title}`}</h1><Link className="hero__box" to={`${url}/take-your-pass`}>{lang === 'hr' ? 'Preuzmite svoj IQM Pass besplatno' : 'Take Your free IQM Pass'}</Link>
                    </div>
                </div>
                {/* <div className="spacer spacer--greenTop" /> */}
                <div className="spacer spacer--whiteTop" />
            </div>


            <TripPlanner tripPlanner={tripPlanner} dealList={dealList} match={match} />

            {/* <div
            className="container"
             style={{
                height: "350px",
                background: "#3754a3",
                display: "flex",
                verticalAlign: "center",
                justifyContent: "center"
            }} > */}

            {/* </div> */}


            <GoogleMap lang={lang} tripPlanner={tripPlanner} dealList={dealList} />

            <GetPassWidget match={match} />
            
            <div className="last-page" >
                <Footer {...props} />
            </div>
        </Fragment>
    );
}



const TripPlanner = ({ tripPlanner, dealList, match }) => {

    const { destinationWebUrl, lang } = match.params
    const url = `/${lang}/${destinationWebUrl}`


    const DealsItems = tripPlanner.deals.map(dealID => {
        const deal = dealList.find(deal => deal._id === dealID)
        if (deal) {
            return (<DealsItem deal={deal} lang={lang} match={match} />)
        } else {
            return null
        }
    })



    const SaveList = [];
    var temp = [];
    for (var i = 0; i < DealsItems.length; i++) {
        if (i % 3 == 0) temp = [];
        temp.push(DealsItems[i])
        if (i % 3 == 2 || i == DealsItems.length - 1) SaveList.push(temp);
    }

    const SaveListElements = SaveList.map(DealsItems => {
        return <div className="save__list">
            {DealsItems}
        </div>

    })



    const subtitle = tripPlanner.metaData[lang].subtitle;
    const description = tripPlanner.metaData[lang].description;
    const shortdesc = tripPlanner.metaData[lang].shortdesc;

    return (
        <div className="section" id="save">
            <div className="container">
                <h2 className="section__title section__title--blue">{subtitle}</h2>
                {
                    description ?
                        <div style={{ maxWidth: "100%", marginBottom: "40px" }} className="section__text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}></div> :
                        <div style={{ maxWidth: "100%", marginBottom: "40px" }} className="section__text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(shortdesc) }}></div>
                }

                <div className="save">
                    {/* <div className="save__list">
                        {DealsItems}
                    </div> */}

                    {SaveListElements}
                </div>
            </div>
        </div>

    );
}

const DealsItem = ({ deal, lang, match, history }) => {


    const { destinationWebUrl } = match.params

    const dealData = deal.dealData.find(data => data.lang === lang)
    const discount = deal.discount.find(discount => {
        if (discount.userType !== 'any') return false
        if ((discount.currency === 'HRK' && lang === 'hr') || (discount.currency === 'EUR' && lang === 'en')) return discount
        else return false
    })


    const curencyMap = {
        "HRK": "kn",
        "EUR": "€"
    }
    const discountType = discount.discountType === 'cash' ? (`-${discount.value}${curencyMap[discount.currency]}`) :
        (discount.discountType === 'trade' ? `-${discount.value}%` : (discount.discountType === 'gift' ? "GIFT" : "FREE"))

    // Ovo je za slučaj kada nije free i gift već nema popusta
    const price = deal.price.find(price => {
        if (price.userType !== 'any') return false
        if ((price.currency === 'HRK' && lang === 'hr') || (price.currency === 'EUR' && lang === 'en')) return price
        else return false
    })
    const noDisscout = (discount.discountType === "trade" || discount.discountType === "cash") && discount.value === 0 ? true : false;

    // console.log(discount,discountType)
    // const url = match.url.replace('/deals', `/deal/${deal._id}`)
    const url = `/${lang}/${destinationWebUrl}/deal/${deal._id}`
    return (
        <Link className="deals__item" to={`${url}`} name={dealData.title} title={dealData.title}>
            <div className="card card--large">
                <div className="card__img">
                    {deal.coverImage ? <img src={`https://assets.iqmpass.com/${deal.coverImage.path.split(".")[0]}_750.webp`} alt="" /> : null}
                    {/* css je napravljen tako da se prilagodava sirini, tako da vrijednosti mogu bit koliko hoces samo textualno ubaci (npr. "-100€" "-100kn" "Gift" "-90%") */}

                    {!noDisscout ? <div className="card__discount">{discountType}</div> : null}
                </div>
                <div className="card__row card__row--white">
                    <h2 className="card__h1">{dealData.title}</h2>
                    <div className="card__text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dealData.shortdesc) }} />
                    <div className="card__btn">
                        <div className="btnTextArrow" >
                            {lang === 'hr' ?
                                <div className="btnTextArrow__text">{(noDisscout || discountType == "FREE" || discountType == "GIFT") ? "Saznaj više" : "Otkrijte put do doživljaja"}</div> :
                                <div className="btnTextArrow__text">{(noDisscout || discountType == "FREE" || discountType == "GIFT") ? "More" : "Discover the path to experience"}</div>
                            }
                            <div className="btnTextArrow__icon">
                                <svg viewBox="0 0 15.698 8.706" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.354 0l-.708.706 3.14 3.147H0v1h13.786L10.646 8l.708.706 4.344-4.353z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="deals__mask" />
        </Link>


    );
}

const MapBox = ({ google, lang, tripPlanner, dealList }) => {

    // Hahy ™2
    var toPdf = false;
    if (window.innerHeight == 600 && window.innerWidth == 800)
        toPdf = true;

    const mapProps = tripPlanner.map;


    const Markers = tripPlanner.deals.map(dealID => {
        const deal = dealList.find(deal => deal._id === dealID)

        if (!deal) return null;
        const { address } = deal
        const { long, lat } = address.geoLocation;
        const dealData = deal.dealData.find(data => data.lang === lang)
        return <Marker title={dealData.name} name={dealData.name} position={{ lat: long, lng: lat }} />
    })




    return (
        <div className="section" id="googlemap">
            <div className="container">
                <div className="map">
                    <h2 className="section__h3">{i18n[lang].location}</h2>
                    <div className="mapBox" style={{ position: "relative" }}>

                        {mapProps ?
                            <Map google={google} zoom={mapProps.zoom} initialCenter={mapProps.center} style={{
                                minWidth: toPdf ? "1000px" : "initial"
                            }}>
                                {Markers}
                            </Map> :
                            null}

                    </div>
                </div>
            </div>
            {/* <div className="spacer spacer--grayBot" /> */}
        </div>
    );
}

const GoogleMap = GoogleApiWrapper({
    apiKey: ("AIzaSyBw8oSp5nAJC43HjK1GjFRR7hLhkGRezcQ")
})(MapBox)



const i18n = {

    'hr': {
        'location': 'Lokacije',

    },
    'en': {
        'location': 'Locations'
    }

}

