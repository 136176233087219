import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, NavLink } from "react-router-dom";


export default (props) => {

    const { match, destinationList } = props


    const { lang, destinationWebUrl } = match.params
    const url = `/${lang}/${destinationWebUrl}`



    const destinationItemsLinks = destinationList ? destinationList.map(destination => <NavLink className="footer__link" to={`/${lang}/${destination.webUrl}`}>{destination.name}</NavLink>) : []


    return (
        <div className="section" id="footer">
            <div className="container">
                <div className="footer">
                    <div className="footer__col">
                        <div className="footer__logo">
                            <img src="/img/logos/iqm-logo-white.png" alt="IQM" />
                        </div>
                        <div className="footer__title">IQM Project</div>
                        <div className="footer__text">
                            {
                                lang === 'hr' ?
                                    <Fragment>
                                        {/* <p>IQM Destination projekt temeljen je na kvaliteti, standardima kvalitete i razvoju destinacijske ponude. Sa željom postizanja sinergije u destinaciji i promocije lokalnih proizvoda, integriramo i potičemo lokalne proizvode u ugostiteljsku ponudu smještaja, suvenirnica, ugostiteljske ponude. Takvim načinom rada potičemo kvalitetu turizma, razvoj poljoprivrede, pružamo gostima novi doživljaj. Stalnim edukacijama, poticajem održivog razvoja i odgovornog turizma, naše destinacije postižu svake godine veće zadovoljstvo gostiju.</p> */}
                                        <p>IQM Destination projekt temeljen je na kvaliteti, standardima kvalitete i razvoju destinacijske ponude.</p>
                                        <a className="footer__link footer__link--small" href="https://iqmdestination.com/" target="_blank">Više o IQM projektu <span>&gt;&gt;</span></a></Fragment> :
                                    <Fragment>
                                        <p>The IQM Destination project is based on quality, quality standards and the development of destination offers.</p>
                                        <a className="footer__link footer__link--small" href="https://iqmdestination.com/" target="_blank">More about IQM project <span>&gt;&gt;</span></a>
                                    </Fragment>
                            }
                            <p></p>
                        </div>
                    </div>
                    <div className="footer__col">
                        <div className="footer__icon">
                            <svg viewBox="0 0 15.707 15.207" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.707 11.707L12 8l-1 1-4.293-4.293 1-1L4 0 2.646 1.354c-1.298 1.298-1.298 3.409 0 4.707l7 7c.521.521 1.175.823 1.854.925v.221H5v1h7.5v-1.221c.679-.102 1.332-.404 1.854-.925l1.353-1.354zm-2.061.647c-.908.908-2.385.908-3.293 0l-7-7c-.908-.908-.908-2.385 0-3.293L4 1.414l2.293 2.293-1 1L11 10.414l1-1 2.293 2.293-.647.647zM2 14.207h2v1H2zM0 14.207h1v1H0z" />
                            </svg>
                        </div>
                        {/* <div className="footer__title">{lang === 'hr' ? 'Nazovite nas' : 'Call us'}</div>
                        <div className="footer__text">+385 99 216 3105</div>
                        <div className="footer__spacer" /> */}
                        <div className="footer__icon">
                            <svg viewBox="0 0 15 13.707" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0v10h11.293L15 13.707V0zm14 11.293L11.707 9H1V1h13z" />
                                <path d="M2.5 3h10v1h-10zM2.5 6h10v1h-10z" />
                            </svg>
                        </div>
                        <div className="footer__title">{lang === 'hr' ? "Pošaljite nam email" : "Mail us"}</div><a className="footer__link" href="mailto:info@iqmpass.com">info@iqmpass.com</a>
                    </div>
                    <div className="footer__col">
                        <div className="footer__icon">
                            <svg viewBox="0 0 14.707 14.707" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 5.707L14.707 5l-.677-.677L10.384.677 9.707 0 9 .707l.613.613-5.229 4.357L3.707 5 3 5.707l.613.613 2.034 2.034L0 14l.707.707 5.646-5.646 2.034 2.034.613.612.707-.707-.677-.677 4.357-5.229zM8.32 9.613L5.094 6.387l5.229-4.357 2.354 2.354z" />
                            </svg>
                        </div>
                        {
                            lang === 'hr' ?
                                <Fragment>
                                    <div className="footer__title">Destinacije</div>
                                    {destinationItemsLinks}
                                </Fragment> :
                                <Fragment>
                                    <div className="footer__title">Destinations</div>
                                    {destinationItemsLinks}
                                </Fragment>
                        }


                    </div>
                    <div className="footer__col" id="contact">
                        <div className="footer__icon">
                            <a href="https://www.facebook.com/iqmpass/" rel="nofollow" target="blank" style={{ marginRight: "5px" }}>
                                <svg height={1792} viewBox="0 0 1792 1792" width={1792} xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1579 128q35 0 60 25t25 60v1366q0 35-25 60t-60 25h-391v-595h199l30-232h-229V689q0-56 23.5-84t91.5-28l122-1V369q-63-9-178-9-136 0-217.5 80T948 666v171H748v232h200v595H213q-35 0-60-25t-25-60V213q0-35 25-60t60-25h1366z" />
                                </svg>
                            </a>
                            <a href="https://www.instagram.com/iqmpass/" rel="nofollow" target="blank" >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                                </svg>
                            </a>
                        </div>

                        {
                            lang === 'hr' ?
                                <Fragment>
                                    <div className="footer__title">IQM Pass</div>
                                    <NavLink className="footer__link" to={`${url}#questions`}>Kako funkcionira</NavLink>
                                    <NavLink className="footer__link" to={`/hr/terms`}>Opći uvjeti</NavLink>
                                    <NavLink className="footer__link" to={`/hr/privacy`}>Privatnost</NavLink>
                                </Fragment> :
                                <Fragment>
                                    <div className="footer__title">IQM Pass</div>
                                    <NavLink className="footer__link" to={`${url}#questions`}>How it works</NavLink>
                                    <NavLink className="footer__link" to={`/en/terms`}>Terms and conditions</NavLink>
                                    <NavLink className="footer__link" to={`/en/privacy`}>Privacy</NavLink>
                                </Fragment>
                        }


                        <div className="footer__spacer" />
                        <div className="footer__text">Copyright © {new Date().getFullYear()}
                            <br />IQM Destination</div>
                    </div>
                </div>
                <div className="footer__bot">

                    {lang === 'hr' ?
                        <p>
                            Feel IQM d.o.o., Gravot 7b, Mali Lošinj, OIB: 45732227989 <br /> Trgovačko društvo upisano je u Sudski registar Trgovačkog suda u Rijeci pod matičnim brojem subjekta 5277671 <br /> Temeljni kapital: 60.000,00 HRK / 7.963,37 €, uplaćen u cijelosti.  Žiro-račun broj: HR58 2340 0091 1110 78895 kod Privredne banke <br /> Sjedište u Malom Lošinju. Direktor: Đurđica Šimičić
                        </p>
                        : <p>Feel IQM d.o.o., Gravot 7b, Mali Lošinj, OIB: 45732227989 <br /> The trading company is registered in the Court Register of the Commercial Court in Rijeka under entity registration number 5277671 <br /> Share capital: HRK 60,000.00/EUR 7,963.37, paid in full. Giro account number: HR58 2340 0091 1110 78895 at Privredna banka <br /> Headquarters in Mali Lošinj. Director: Đurđica Šimičić</p>}
                </div>
            </div>
        </div>
    );
}
