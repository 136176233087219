let url = ''
if (process.env.NODE_ENV == 'development')
    url = "http://localhost:3000"
else if (process.env.NODE_ENV == 'production')
    url = "https://api.iqmpass.com"



url = "https://api.iqmpass.com"

const POST_OPTS = ContentType => {
    return {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': ContentType || 'application/json',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url   
    }
}


const GET_OPTS = ContentType => {
    return {
        method: 'GET',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': ContentType || 'application/json',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url   
    }
}

const API_URL = url;
const SERVICE_ROUTE = "/api/public";



const generateOrder = async (destinationID, passID, passActivationDate, passExpirationDate, giftcardID, referalID, lang) => {
    try {
        const options = {
            body: JSON.stringify({ destinationID, passID, passActivationDate, passExpirationDate, giftcardID, referalID, lang}),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/generateOrder`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}

const getOrder = async (destinationID, orderID) => {
    try {
        const options = {
            body: JSON.stringify({ destinationID, orderID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getOrder`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}


const getPassList = async (destinationID) => {
    try {
        const options = {
            body: JSON.stringify({ destinationID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getPassList`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}

const sortDestinations = (array, sortArray) => {
    return [...array].sort(
      (a, b) => sortArray.indexOf(a.slug) - sortArray.indexOf(b.slug)
    )
  }

const slugOrder = 
[
    'visit-karlovac',
    'visit-peljesac',
    'visit-vukovar',
    'visit-brela',
    'visit-vodice',
    'visit-bjelovarsko-bilogorska-zupanija',
    'visit-omis',
    'visit-novalja',
    'visit-gorski-kotar',
    'visit-rab',
    'visit-imota',
    'visit-cres-losinj-archipelago',
    'visit-podstrana',
    'visit-lika-destination',
    'visit-nijemci',
    'visit-brac',
    'visit-ilok',
    'visit-golden-slavonia',       
    'visit-bibinje',
    'visit-korcula']

const loadDestinationList = async () => {
    try {
        const options = {
            ...GET_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/web`, options);
        const resJson = await response.json();

        return sortDestinations(resJson, slugOrder)
         
    } catch (error) {

        console.error(error);
        return null;
    }
}




const loadDestination = async (destinationID) => {


    try {
        const options = {
            ...GET_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/web/${destinationID}`, options);
        const resJson = await response.json();

        return resJson
    } catch (error) {

        console.error(error);
        return null;
    }
}

const getWebConfig = async () => {


    try {
        const options = {
            ...GET_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/webConfig`, options);
        const resJson = await response.json();

        return resJson
    } catch (error) {

        console.error(error);
        return null;
    }
}



const getActiveDeals = async (destinationID) => {
    try {
        const options = {
            body: JSON.stringify({ destinationID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getActiveDeals`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}

const getDeal = async (destinationID, dealID) => {
    try {
        const options = {
            body: JSON.stringify({ destinationID, dealID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getDeal`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}

const getPartner = async (destinationID, partnerID) => {
    try {
        const options = {
            body: JSON.stringify({ destinationID, partnerID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getPartner`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}



const getWidget = async (destinationID, widgetID) => {
    try {
        const options = {
            body: JSON.stringify({ destinationID, widgetID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getWidget`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}

const getWidgetByURL = async (destinationID, url) => {
    try {
        const options = {
            body: JSON.stringify({ destinationID, url }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getWidgetByURL`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}



const getGiftCardByCode  = async (destinationID, code) => {
    try {
        const options = {
            body: JSON.stringify({ destinationID, code }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getGiftCardByCode`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}


const getGiftCard  = async (destinationID, giftcardID) => {
    try {
        const options = {
            body: JSON.stringify({ destinationID, giftcardID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getGiftCard`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}

const generateGiftOrder = async(destinationID, orderID, CustomerFirstName, CustomerLastName, CustomerEmail) => {
    try {
        const options = {
            body: JSON.stringify({destinationID, orderID, CustomerFirstName, CustomerLastName, CustomerEmail }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/generateGiftOrder`, options);
        return await response.json();
    } catch (error) {
        
    }
}


const getItinerary = async (destinationID, itineraryID) => {
    try {
        const options = {
            body: JSON.stringify({ destinationID, itineraryID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getItinerary`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}

const getTripPlannerBySlug = async (destinationID, slug) => {
    try {
        const options = {
            body: JSON.stringify({ destinationID, slug }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getTripPlannerBySlug`, options);
        return await response.json()
    } catch (error) {

        console.error(error);
        return null;
    }
}

const generateFreePassCards  = async (destinationID, passID, passActivationDate, passExpirationDate, CustomerEmail, lang, verificationToken) => {
    try {
        const options = {
            body: JSON.stringify({ destinationID, passID, passActivationDate, passExpirationDate, CustomerEmail, lang, verificationToken}),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/generateFreePassCards`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}


const takeFreePass  = async (destinationID, passID, passActivationDate, passExpirationDate, CustomerEmail, lang, verificationToken,numberOfUsers) => {
    try {
        const options = {
            body: JSON.stringify({ destinationID, passID, passActivationDate, passExpirationDate, CustomerEmail, lang, verificationToken, numberOfUsers}),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/takeFreePass`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}




export default {
    API_URL,
    SERVICE_ROUTE,
    POST_OPTS,

    loadDestinationList,
    loadDestination,
    getWebConfig,
    
    getOrder,
    generateOrder,
    getPassList,
    getActiveDeals,
    getDeal,
    getPartner,
    getWidget,
    getWidgetByURL,
    getGiftCardByCode,
    getGiftCard,
    generateGiftOrder,
    getItinerary,
    generateFreePassCards,
    takeFreePass,
    getTripPlannerBySlug
}