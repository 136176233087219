import React, { Fragment, useRef, useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import SeoHead from '../includes/SeoHead'
import DataLayerHead  from '../includes/DataLayerHead'

export default (props) => {

  const {match, history} = props
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const webTitle = "IQM Pass - 404"
  return (<Fragment>
    <DataLayerHead webTitle={webTitle} />
    <SeoHead match={match} history={history} pageType="NotFound404Page" />
    <div className="section" id="notfound">
      <div className="notfound">
        <div className="notfound__title">
          <h1>404</h1>
        </div>
        <div className="notfound__subtitle">
          <h2>Oops! Nothing was found</h2>
        </div>
        <div className="notfound__text">
          <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p><a href="/" >Return to homepage</a>
        </div>
        {/* <div className="notfound__social">
              <div className="notfound__item"><Link to={"/"} />
              </div>
            </div> */}
      </div>
    </div>
  </Fragment>

  );
}   